import React from 'react';
import { Card, CardContent, Typography, Stack, Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router';
const DashboardCard2 = ({
  title,
  subtitle,
  children,
  action,
  footer,
  cardheading,
  headtitle,
  headsubtitle,
  middlecontent,
}) => {

  const navigate=useNavigate()
  return (
    <Card 
      sx={{
        padding: 3,
        // background: '#F2F2F7',
        borderRadius: 4
      }}
      elevation={9}
      variant={undefined}
    >
      {cardheading ? (
        <CardContent>
          <Typography variant="h5">{headtitle}</Typography>
          <Typography variant="subtitle2" color="textSecondary">
            {headsubtitle}
          </Typography>
        </CardContent>
      ) : (
        <CardContent sx={{
          background: 'white',
          borderRadius: 5,
        //   boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.08), 0px 8px 8px rgba(0, 0, 0, 0.08)',
          padding: 0
        }}
        // elevation={9}
        >
          {title ? (
            <Stack
              direction="row"
              spacing={2}
              justifyContent="space-between"
              alignItems={'center'}
              mb={3}
              sx={{
                // background: '#6174DD',
                // px: 3,
                // py: 2,
                // borderRadius: 5,
                // borderBottomLeftRadius: 0,
                // borderBottomRightRadius: 0,
                // color: 'white'
              }}
            >
              <Box sx={{width:"100%",display:'flex',justifyContent:"space-between"}}>
                {title ? <Typography variant="h4" fontSize={25}>{title}</Typography> : ''}

                {subtitle ? (
                  <Typography variant="subtitle2" color="textSecondary">
                    {subtitle}
                  </Typography>
                ) : (
                  ''
                )}
                <div style={{margin:"0 20px"}} ><ArrowBackIcon onClick={()=>navigate(-1)}/></div>
              </Box>
              {action}
            </Stack>
          ) : null}
          <Box
            sx={{
              minHeight: '68vh',
            }}
          >
            {children}
          </Box>
        </CardContent>
      )}
      {footer}
    </Card>
  );
};

export default DashboardCard2