import React from 'react';
import {
    Typography, Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material';

// const users = [
//     {
//         "id": 1,
//         "name": "Alice",
//         "email": "alice@example.com",
//         "registeredOn": "2022-05-01T12:30:00Z"
//     },
//     {
//         "id": 2,
//         "name": "Bob",
//         "email": "bob@example.com",
//         "registeredOn": "2022-04-15T09:45:00Z"
//     },
//     {
//         "id": 3,
//         "name": "Charlie",
//         "email": "charlie@example.com",
//         "registeredOn": "2022-03-21T18:00:00Z"
//     },
//     {
//         "id": 4,
//         "name": "Dave",
//         "email": "dave@example.com",
//         "registeredOn": "2022-02-08T07:15:00Z"
//     },
//     {
//         "id": 5,
//         "name": "Emma",
//         "email": "emma@example.com",
//         "registeredOn": "2022-01-12T15:30:00Z"
//     },
//     {
//         "id": 6,
//         "name": "Frank",
//         "email": "frank@example.com",
//         "registeredOn": "2021-12-05T11:00:00Z"
//     },
//     {
//         "id": 7,
//         "name": "Grace",
//         "email": "grace@example.com",
//         "registeredOn": "2021-11-19T20:15:00Z"
//     },
//     {
//         "id": 8,
//         "name": "Harry",
//         "email": "harry@example.com",
//         "registeredOn": "2021-10-27T13:45:00Z"
//     }
// ]


export const TrimData = (data) => {
    if (data.length < 25)
        return data;
    return data.substring(0, 15) + '...' + data.substring(data?.length - 10);
};


const Users = ({ users }) => {
    return (
        <Box sx={{ overflow: 'auto', width: { xs: '450px', sm: 'auto' } }}>
            <Table
                aria-label="simple table"
                sx={{
                    whiteSpace: "nowrap",
                    mt: 2
                }}
            >
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography variant="subtitle2" fontWeight={600}>
                                Id
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="subtitle2" fontWeight={600}>
                                Name
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="subtitle2" fontWeight={600}>
                                Email
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="subtitle2" fontWeight={600}>
                                Registered On
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users?.map((user) => (
                        <TableRow key={user?._id}>
                            <TableCell>
                                <Typography
                                    sx={{
                                        fontSize: "15px",
                                        fontWeight: "500",
                                    }}
                                >
                                    {user?._id}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography
                                    sx={{
                                        fontSize: "15px",
                                        fontWeight: "500",
                                    }}
                                >
                                    {TrimData(user?.displayName)}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography sx={{
                                    fontSize: "15px",
                                    fontWeight: "500",
                                }}>
                                    {TrimData(user?.email)}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography sx={{
                                    fontSize: "15px",
                                    fontWeight: "500",
                                }}>
                                    {new Date(user?.createdAt)?.toLocaleDateString()}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Box>
    );
};

export default Users;