import React, { useEffect, useState } from 'react';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard from 'src/components/shared/DashboardCard';
import { Button, Grid, Modal, Snackbar } from '@mui/material';
import { Box } from '@mui/system';
import { useMain } from '../hooks/useMain';
import TextInputs from 'src/components/inputs/TextInputs';
import SelectInputs from 'src/components/inputs/SelectInputs';
import SuccessModal from 'src/components/Modals/SuccessModal';
import RadioInputs from 'src/components/inputs/RadioInputs';

const initialValue = {
    name: ''
};

const AddCategory = () => {
    const [openConfirm, setOpenConfirm] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [formData, setFormData] = useState(initialValue);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [showLoader, setShowLoader] = useState(false);
    const [services, setServices] = useState([]);
    const [appointments, setAppointments] = useState([]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value })
    }

    const { createCategory } = useMain();

    const handleSubmit = async () => {
        const { name } = formData;
        if (name.trim() === '') return setErrorMessage('category Name is Required');
        setShowLoader(true);

        // Simulate API call for client creation
        const res = await createCategory(JSON.stringify(formData));

        if (res?.status === 'success') {
            setSuccessMessage('Category Successfully Created');
            setTimeout(() => {
                setSuccessMessage('');
            }, 7000);
            setFormData(initialValue);
            setErrorMessage('');
            setShowLoader(false);
        } else {
            setErrorMessage(res?.message);
            setTimeout(() => {
                setErrorMessage('');
            }, 7000);
            setFormData(initialValue);
            setShowLoader(false);
        }
    };

    const handleSuccessModal = () => {
        setOpenConfirm(false);
        setSuccessModal(!successModal);
    };

    const handleCloseConfirm = () => {
        setOpenConfirm(false);
    };

    return (
        <PageContainer title="Generate Category" description="this is add Category">
            <DashboardCard title="Generate New Category">
                <Snackbar
                    open={errorMessage === '' ? false : true}
                    autoHideDuration={6000}
                    onClose={() => {
                        setErrorMessage('');
                    }}
                    severity="error"
                    message={errorMessage}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                />
                <Box style={{ marginTop: '20px' }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                            <TextInputs
                                title={'Category Name'}
                                name={'name'}
                                value={formData?.name}
                                handleChange={handleChange}
                                type={'text'}
                            />
                        </Grid>
                    </Grid>
                </Box>

                {/* ... (other form inputs) */}

                <div style={{ width: '200px', margin: '3rem auto' }}>
                    <Button
                        variant="contained"
                        fullWidth
                        color="primary"
                        target="_blank"
                        sx={{
                            background: '#6174DD'
                        }}
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </div>
            </DashboardCard>
            <SuccessModal open={successModal} handleClose={handleSuccessModal} text={'Congratulations on your Appointment successfully!'} />
        </PageContainer >
    )
}

export default AddCategory