import React, { useEffect, useState } from 'react';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard2 from 'src/components/shared/DashboardCard2';
import { useMain } from '../hooks/useMain';
import star_img from '../../assets/images/star.png';
import { Grid, InputLabel, Select, MenuItem, Button, Typography, Pagination } from '@mui/material';
import SearchImg from '../../assets/search.svg'; // Add correct path if needed
import { Box } from '@mui/system';

export const maskData = (value, isMasked) => {
  if (isMasked) {
    const maskLength = Math.ceil(value.length / 2);
    return '*'.repeat(maskLength) + value.substring(maskLength);
  } else {
    return value;
  }
};

const filterMenu = [{ value: 'ratings', label: 'Ratings' }];

const filterRatings = [
  { value: 4, label: '4' },
  { value: 3, label: '3' },
  { value: 2, label: '2' },
  { value: 1, label: '1' },
];

const ClientReviewPage = () => {
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [limit, setLimit] = useState(10); // Initialize limit
  const [page, setPage] = useState(1); // Initialize page
  const [search, setSearch] = useState(''); // Initialize search
  const [ratings, setRatings] = useState(''); // Initialize ratings

  const { getClientReviews, activeBranchId, setShowMessage, branchData } = useMain();

  useEffect(() => {
    applyFilters();
  }, [data, limit, ratings, page, search]);

  const getData = async () => {
    const queryParams = `branchId=${activeBranchId}&ratings=${ratings}`;
    try {
      setShowLoader(true);
      const res = await getClientReviews(queryParams);
      if (res.statusCode === 200) {
        setData(res.data || []);
      } else {
        console.error('Failed to fetch data:', res);
        setShowMessage(`Failed to fetch data: ${res.message || 'Unknown error'}`);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setShowMessage(`Error fetching data: ${error.message}`);
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    if (activeBranchId) {
      getData();
    }
    return () => {
      setData([]);
    };
  }, [activeBranchId]);
  console.log(data);
  const applyFilters = () => {
    let filteredData = data.filter((item) => {
      let isRatingMatch = true;
      let isNameMatch = true;

      if (ratings) {
        isRatingMatch = item.avgRating >= ratings;
      }

      if (search) {
        const clientName = item.clientName || '';
        const clientNumber = item.clientNumber || '';
        isNameMatch =
          clientName.toLowerCase().includes(search.toLowerCase()) || clientNumber.includes(search);
      }
      return isRatingMatch && isNameMatch;
    });

    // Applying pagination
    const startIndex = (page - 1) * limit;
    const endIndex = startIndex + limit;
    filteredData = filteredData.slice(startIndex, endIndex);
    setFilterData(filteredData);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const clearDataHandler = () => {
    setRatings('');
    setSearch('');
    setPage(1); // Reset page to 1 on clear
  };

  return (
    <PageContainer title="Branches" description="This contains all products">
      <DashboardCard2 title="Client Review">
        <Grid container style={{ display: 'flex', gap: '15px' }}>
          <Grid container justifyContent="space-between">
            {/* Show per page */}
            <Grid item xs={2}>
              <InputLabel>Show per page</InputLabel>
              <Select
                style={{
                  borderRadius: '7px',
                  border: '0.5px solid #6174DD',
                  padding: '4px 12px',
                  background: '#FFF',
                  height: '40px',
                }}
                value={limit}
                onChange={handleLimitChange}
              >
                {[10, 20, 50].map((value) => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid item xs={1.3} marginLeft={40}>
              <InputLabel>Ratings</InputLabel>
              <Select
                style={{
                  borderRadius: '7px',
                  border: '0.5px solid #6174DD',
                  padding: '4px 12px',
                  background: '#FFF',
                  height: '40px',
                  width: '100%',
                  display: 'flex',
                }}
                value={ratings}
                onChange={(e) => setRatings(e.target.value)}
              >
                {/* Assuming filterRatings is defined somewhere */}
                {filterRatings.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                    <img
                      src={star_img}
                      alt="star"
                      style={{ width: '20px', height: '20px', marginBottom: '3px' }}
                    />
                    & above
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            {/* Search */}
            <Grid item xs={1.3}>
              <InputLabel>Search</InputLabel>
              <div className="search-input">
                <img src={SearchImg} alt="" />
                <input
                  type="text"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </Grid>

            {/* Apply and Clear Buttons */}
            <Grid item xs={1.3} display="flex" gap="20px" alignItems="flex-end">
              <Button
                variant="contained"
                color="primary"
                sx={{
                  height: '40px',
                  width: '100%',
                  background: '#ff4d4d',
                  '&:hover': {
                    background: '#ff6666',
                  },
                }}
                onClick={clearDataHandler}
              >
                Clear
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <table className="table-container mt-3">
          <thead className="table-thead">
            <tr>
              {['Client Name', 'Contact', 'Avg Ratings'].map((h, index) => (
                <th key={index} style={{ width: '100%' }}>
                  {h}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="table-tbody">
            {filterData && filterData.length ? (
              filterData.map((item) => (
                <tr key={item._id}>
                  <td style={{ width: '100%' }}>{item.clientName}</td>
                  <td style={{ width: '100%' }}>
                    {typeof branchData?.isMasked !== 'boolean' || !item?.clientNumber
                      ? 'loading...'
                      : maskData(item?.clientNumber, branchData?.isMasked)}
                  </td>
                  <td style={{ width: '100%' }}>{item?.avgRating}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={3}>
                  <Typography variant="body1">No reviews found</Typography>
                </td>
              </tr>
            )}
          </tbody>
        </table>

        {/* Pagination */}
        <Box display={'flex'} justifyContent={'flex-end'}>
          <Pagination
            count={filterData && filterData?.length < limit ? page : page + 1}
            page={page}
            color="primary"
            onChange={(e, page) => setPage(page)}
          />
        </Box>
      </DashboardCard2>
    </PageContainer>
  );
};

export default ClientReviewPage;
