import React, { useState } from 'react';
import {
    Typography, Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Chip,
    Button,
    Snackbar,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Dialog
} from '@mui/material';

// const orders = [
//     {
//         id: 1,
//         customer: 'John Doe',
//         date: '2022-01-15',
//         status: 'delivered',
//         items: ['T-shirt', 'Jeans'],
//         quantity: [1, 2],
//         price: [20, 50],
//         total: 120,
//         paymentStatus: 'pending',
//         shippingAddress: '123 Main St, Anytown USA',
//         billingAddress: '1234 Bank St, Anytown USA'
//     },
//     {
//         id: 2,
//         customer: 'Jane Smith',
//         date: '2022-02-01',
//         status: 'shipped',
//         items: ['Hoodie', 'Sneakers'],
//         quantity: [1, 1],
//         price: [40, 80],
//         total: 120,
//         paymentStatus: 'paid',
//         shippingAddress: '567 Elm St, Anytown USA',
//         billingAddress: '4321 Credit Union Ave, Anytown USA'
//     },
//     {
//         id: 3,
//         customer: 'Bob Johnson',
//         date: '2022-03-05',
//         status: 'pending',
//         items: ['Sweater', 'Boots'],
//         quantity: [1, 1],
//         price: [60, 100],
//         total: 160,
//         paymentStatus: 'pending',
//         shippingAddress: '789 Oak St, Anytown USA',
//         billingAddress: '5678 Finance Blvd, Anytown USA'
//     },
//     {
//         id: 4,
//         customer: 'Alice Lee',
//         date: '2022-04-10',
//         status: 'shipped',
//         items: ['Jacket', 'Hat'],
//         quantity: [1, 1],
//         price: [80, 20],
//         total: 100,
//         paymentStatus: 'prepaid',
//         shippingAddress: '234 Pine St, Anytown USA',
//         billingAddress: '8765 Savings Dr, Anytown USA'
//     },
//     {
//         id: 5,
//         customer: 'Tom Smith',
//         date: '2022-05-20',
//         status: 'delivered',
//         items: ['Dress', 'Sandals'],
//         quantity: [1, 1],
//         price: [70, 30],
//         total: 100,
//         paymentStatus: 'prepaid',
//         shippingAddress: '456 Maple St, Anytown USA',
//         billingAddress: '9876 Investment Ln, Anytown USA'
//     },
//     {
//         id: 6,
//         customer: 'Emily Johnson',
//         date: '2022-06-25',
//         status: 'pending',
//         items: ['Blouse', 'Skirt'],
//         quantity: [1, 1],
//         price: [50, 40],
//         total: 90,
//         paymentStatus: 'paid',
//         shippingAddress: '789 Walnut St, Anytown USA',
//         billingAddress: '6543 Accounting Rd, Anytown USA'
//     },
//     {
//         id: 7,
//         customer: 'Mark Davis',
//         date: '2022-07-30',
//         status: 'shipped',
//         items: ['Sweatpants', 'Sneakers'],
//         quantity: [1, 1],
//         price: [30, 70],
//         total: 100,
//         paymentStatus: 'pending',
//         shippingAddress: '901 Oak Ave, Anytown USA',
//         billingAddress: '2345 Financial Blvd, Anytown USA'
//     }
// ];

export const TrimData = (data) => {
    if (data.length < 25)
        return data;
    return data.substring(0, 15) + '...' + data.substring(data?.length - 10);
};


const Orders = ({ orders }) => {
    const [isEdit, setIsEdit] = useState(false);

    const [openDialogue, setOpenDialogue] = useState(false);
    const [alert, setAlert] = useState('');

    const handleCloseDialogue = () => {
        setOpenDialogue(false);
    };

    const handleDeleteField = () => {
        setOpenDialogue(false);
        setAlert('Order deleted successfully')
    };
    return (
        <Box sx={{ overflow: 'auto', width: { xs: '450px', sm: 'auto' } }}>
            <Snackbar
                open={alert === '' ? false : true}
                autoHideDuration={6000}
                onClose={() => {
                    setAlert('');
                }}
                severity="error"
                message={alert}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            />
            <Table
                aria-label="simple table"
                sx={{
                    whiteSpace: "nowrap",
                    mt: 2
                }}
            >
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography variant="subtitle2" fontWeight={600}>
                                Id
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="subtitle2" fontWeight={600}>
                                Customer
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="subtitle2" fontWeight={600}>
                                Items
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="subtitle2" fontWeight={600}>
                                Payment Status
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="subtitle2" fontWeight={600}>
                                Shipping Status
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="subtitle2" fontWeight={600}>
                                Date
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="subtitle2" fontWeight={600} align='right'>
                                Total
                            </Typography>
                        </TableCell>
                        {/* <TableCell align="right">
                            <Typography variant="subtitle2" fontWeight={600}>
                                Actions
                            </Typography>
                        </TableCell> */}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {orders.map((order) => (
                        <TableRow key={order?._id}>
                            <TableCell>
                                <Typography
                                    sx={{
                                        fontSize: "15px",
                                        fontWeight: "500",
                                    }}
                                >
                                    {order?._id}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="subtitle2" fontWeight={600}>
                                    {TrimData(order?.buyer?.displayName)}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                {
                                    order?.products.map((item, index) =>
                                        <Chip
                                            sx={{
                                                px: "4px",
                                                m: "2px",
                                                backgroundColor: 'primary.main',
                                                color: "#fff",
                                            }}
                                            size="small"
                                            label={`${item?.quantity} x ${item?.product?.displayName}`}
                                        ></Chip>
                                    )
                                }
                            </TableCell>
                            <TableCell>
                                <Chip
                                    sx={{
                                        px: "4px",
                                        m: "2px",
                                        backgroundColor: order?.payment_status === 'PENDING' ? 'primary.main' : order.paymentStatus === 'PREPAID' ? 'secondary.main' : 'error.main',
                                        color: "#fff",
                                    }}
                                    size="small"
                                    label={order?.payment_status}
                                ></Chip>
                            </TableCell>
                            <TableCell>
                                <Typography color="textSecondary" variant="subtitle2" fontWeight={400}>
                                    {order?.order_status}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography color="textSecondary" variant="subtitle2" fontWeight={400}>
                                    {new Date(order?.createdAt).toLocaleDateString()}
                                </Typography>
                            </TableCell>
                            <TableCell align='right'>
                                <Typography variant="h6">Rs. {order?.order_price}</Typography>
                            </TableCell>
                            {/* <TableCell align={'right'}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-end",
                                        gap: '1rem'
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        sx={{
                                            backgroundColor: "success.main",
                                            color: '#fff',
                                            '&:hover': {
                                                backgroundColor: 'success.dark',
                                            },
                                        }}
                                        onClick={() => setIsEdit(true)}
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            backgroundColor: "error.main",
                                            color: '#fff',
                                            '&:hover': {
                                                backgroundColor: 'error.dark',
                                            },
                                        }}
                                        onClick={() => setOpenDialogue(true)}
                                    >
                                        Delete
                                    </Button>
                                    <Dialog open={openDialogue} onClose={handleCloseDialogue}>
                                        <DialogTitle>Delete Order</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText>
                                                Are you sure you want to delete this data?
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={handleCloseDialogue}>Cancel</Button>
                                            <Button onClick={handleDeleteField} autoFocus>
                                                Delete
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </Box>
                            </TableCell> */}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Box>
    );
};

export default Orders;
