// import React, { useState, useEffect } from 'react';
// import {
//   Box,
//   Button,
//   Grid,
//   InputLabel,
//   MenuItem,
//   Pagination,
//   Select,
//   Typography,
// } from '@mui/material';
// import * as XLSX from 'xlsx';
// import PageContainer from 'src/components/container/PageContainer';
// import DashboardCard2 from 'src/components/shared/DashboardCard2';
// import ShowLoader from 'src/components/CustomComponents/ShowLoader';
// import DateRangeInputs from 'src/components/inputs/DateRangeInputs';
// import FieldData from './FieldData';
// import SearchImg from '../../assets/search.svg';
// import { useMain } from '../hooks/useMain';
// import { useNavigate } from 'react-router';
// import star_img from 'src/assets/images/star.png';
// const filterMenu = [
//   // { value: 'billsFrequency', label: 'Most Frequent' },
//   // { value: 'totalBillsAmount', label: 'Highest billed' },
//   { value: 'ratings', label: 'Ratings' },
// ];

// const filterGenderWise = [
//   { value: 'Female', label: 'Female' },
//   { value: 'Male', label: 'Male' },
// ];
// const filterRatings = [
//   { value: 4, label: '4' },
//   { value: 3, label: '3' },
// ];

// const AllBillings = () => {
//   const [data, setData] = useState([]);
//   const [showLoader, setShowLoader] = useState(true);
//   const [search, setSearch] = useState('');
//   const [genderBy, setGenderBy] = useState('');
//   const [ratingBy, setRatingBy] = useState('');
//   const [limit, setLimit] = useState(10);
//   const [page, setPage] = useState(1);
//   const [sortBy, setSortBy] = useState('');
//   const [sortValue, setSortValue] = useState(-1);
//   const [clear, setClear] = useState(false);
//   const [startDate, setStartDate] = useState('');
//   const [endDate, setEndDate] = useState('');
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [filterData, setFilterData] = useState(null);

//   const { fetchAllBillings, activeBranchId } = useMain(); // Replace with your actual API import

//   const navigate = useNavigate();

//   useEffect(() => {
//     applyFilters(
//       data,
//       limit,
//       genderBy,
//       ratingBy,
//       page,
//       search,
//       startDate,
//       endDate,
//       sortBy,
//       sortValue,
//     );
//   }, [data, limit, genderBy, ratingBy, page, search, startDate, endDate, sortBy, sortValue]);

//   const openModal = () => setIsModalOpen(true);

//   const closeModal = () => {
//     setIsModalOpen(false);
//   };

//   const handleDateRangeSelected = (startDate, endDate) => {
//     setStartDate(startDate);
//     setEndDate(endDate);
//     setIsModalOpen(false);
//     getData();
//     setClear(true);
//   };

//   const clearDataHandler = () => {
//     setLimit(10);
//     setPage(1);
//     setSearch('');
//     setSortBy('');
//     setSortValue(-1);
//     setGenderBy('');
//     setRatingBy('');
//     setEndDate('');
//     setStartDate('');
//     if (clear) {
//       getData();
//       setClear(false);
//     }
//   };

//   const onApplyHandler = () => {
//     if (search) {
//       getData();
//       setClear(true);
//     }
//   };

//   const getData = async () => {
//     const queryParams = `?page=${page}&limit=${limit}${
//       sortBy ? `&sortKey=${sortBy}&sortValue=${sortValue}` : ''
//     }${genderBy ? `&gender=${genderBy}` : ''}${ratingBy ? `&ratings=${ratingBy}` : ''}${
//       search ? `&search=${search}` : ''
//     }${startDate ? `&startDate=${startDate}` : ''}${endDate ? `&endDate=${endDate}` : ''}`;

//     try {
//       setShowLoader(true);
//       const res = await fetchAllBillings(activeBranchId, queryParams);
//       if (res.statusCode === 200) {
//         setData(res.data || []);
//       } else {
//         console.error('Failed to fetch data:', res);
//       }
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     } finally {
//       setShowLoader(false);
//     }
//   };

//   useEffect(() => {
//     if (activeBranchId) {
//       getData();
//     }
//     return () => {
//       setData([]);
//     };
//   }, [activeBranchId, page, limit, sortBy, sortValue, genderBy, search, startDate, endDate]);

//   const handleLimitChange = (e) => {
//     setLimit(e.target.value);
//     setPage(1);
//     getData();
//   };

//   const handleSortChange = (sortField, sortOrder) => {
//     setSortBy(sortField);
//     setSortValue(sortOrder);
//     setPage(1);
//     getData();
//   };

//   const handleOnExport = () => {
//     // Check if tableData is defined and is an array
//     if (!Array.isArray(data)) {
//       console.error('Data is not an array or is undefined');
//       return;
//     }

//     // Define the fields you want to extract
//     const selectedFields = [
//       'clientName',
//       'clientNumber',
//       'gender',
//       'useMembership',
//       'dateOfBilling',
//       'timeOfBilling',
//       'subTotal',
//       'totalAmount',
//       'amountPayable',
//       'paidDues',
//       'advancedGiven',
//       'preAdvanceUsed',
//       'ratings',
//     ];

//     // Flatten the data and extract fields
//     const flattenedData = data.map((item) => {
//       const flattenedItem = {};

//       // Extract selected fields from item
//       selectedFields.forEach((field) => {
//         if (item.hasOwnProperty(field)) {
//           flattenedItem[field] = item[field];
//         }
//       });
//       // Sum up the amountPaid if it's an array
//       if (Array.isArray(item.amountPaid)) {
//         flattenedItem.amountPaid = item.amountPaid.reduce((total, item) => {
//           return total + parseFloat(item.amount);
//         }, 0);
//       }
//       // Extract and flatten services data
//       if (Array.isArray(item.services)) {
//         flattenedItem.serviceName = item.services
//           .map((serviceItem) => serviceItem.serviceName)
//           .join(', ');
//         flattenedItem.serviceProviders = item.services
//           .map((serviceItem) => serviceItem.serviceProvider?.name || '')
//           .join(', ');
//       }

//       // Extract and flatten products data
//       if (Array.isArray(item.products)) {
//         flattenedItem.productName = item.products
//           .map((productItem) => productItem.productName)
//           .join(', ');
//         flattenedItem.productProvider = item.products
//           .map((productItem) => productItem.providerStaff?.name || '')
//           .join(', ');
//       }

//       return flattenedItem;
//     });

//     // Convert the flattened data to a sheet
//     let wb = XLSX.utils.book_new();
//     let ws = XLSX.utils.json_to_sheet(flattenedData);
//     XLSX.utils.book_append_sheet(wb, ws, 'Bills');

//     // Write the workbook to a file
//     XLSX.writeFile(wb, 'Bills.xlsx');
//   };


//   const applyFilters = (
//     data,
//     limit,
//     gender,
//     ratings,
//     page,
//     search,
//     startDate,
//     endDate,
//     sortBy,
//     sortValue,
//   ) => {
//     let filteredData = data.filter((item) => {
//       let isGenderMatch = true;
//       let isRatingMatch = true;
//       let isNameMatch = true;
//       let isDateMatch = true;

//       if (gender) {
//         isGenderMatch = item.gender === gender;
//       }

//       if (ratings === 4) {
//         isRatingMatch = item.ratings >= ratings;
//       } else if(ratings===3){
//         isRatingMatch = item.ratings <= ratings;
//       }

//       if (search) {
//         const clientName = item.clientName || '';
//         const clientNumber = item.clientNumber || '';
//         isNameMatch =
//           clientName.toLowerCase().includes(search.toLowerCase()) || clientNumber.includes(search);
//       }

//       if (startDate && endDate) {
//         const billDate = new Date(item.dateOfBilling);
//         isDateMatch = billDate >= new Date(startDate) && billDate <= new Date(endDate);
//       }

//       return isGenderMatch && isRatingMatch && isNameMatch && isDateMatch;
//     });

//     // Apply sorting
//     if (sortBy) {
//       filteredData = filteredData.sort((a, b) => {
//         if (sortBy === 'ratings') {
//           return sortValue === -1 ? b.ratings - a.ratings : a.ratings - b.ratings;
//         }
//         // Add more sorting logic if needed
//         return 0;
//       });
//     }

//     // Applying pagination
//     const startIndex = (page - 1) * limit;
//     const endIndex = startIndex + limit;
//     filteredData = filteredData.slice(startIndex, endIndex);
//     setFilterData(filteredData);
//   };
//   return (
//     <PageContainer title="Billing History" description="this contains all products">
//       <DashboardCard2 title="Billing History">
//         <Grid container style={{ display: 'flex', gap: '15px' }}>
//           <Grid container justifyContent="space-between">
//             {/* Show per page */}
//             <Grid item xs={2}>
//               <InputLabel>Show per page</InputLabel>
//               <Select
//                 style={{
//                   borderRadius: '7px',
//                   border: '0.5px solid #6174DD',
//                   padding: '4px 12px',
//                   background: '#FFF',
//                   height: '40px',
//                 }}
//                 value={limit}
//                 onChange={handleLimitChange}
//               >
//                 {[10, 20, 50].map((value) => (
//                   <MenuItem key={value} value={value}>
//                     {value}
//                   </MenuItem>
//                 ))}
//               </Select>
//             </Grid>

//             {/* Date Range */}
//             <Grid item xs={2} display={'flex'} flexDirection={'column'} justifyContent={'flex-end'}>
//               <Typography variant="subtitle2" fontWeight={500} component="label">
//                 {startDate && endDate ? `${startDate} to ${endDate}` : ' '}
//               </Typography>
//               <Button
//                 variant="outlined"
//                 target="_blank"
//                 sx={{ color: 'black', height: '40px', width: '100%' }}
//                 onClick={openModal}
//               >
//                 Choose Date
//               </Button>
//             </Grid>

//             <Grid item xs={1}>
//               <InputLabel>Sort By</InputLabel>
//               <Select
//                 style={{
//                   borderRadius: '7px',
//                   border: '0.5px solid #6174DD',
//                   padding: '4px 12px',
//                   background: '#FFF',
//                   height: '40px',
//                   width: '100%',
//                 }}
//                 value={sortBy}
//                 onChange={(e) => setSortBy(e.target.value)}
//               >
//                 {filterMenu.map((item, index) => (
//                   <MenuItem key={index} value={item.value}>
//                     {item.label}
//                   </MenuItem>
//                 ))}
//               </Select>
//             </Grid>

//             <Grid item xs={2}>
//               <div>
//                 <InputLabel>Ratings</InputLabel>
//                 <Select
//                   style={{
//                     borderRadius: '7px',
//                     border: '0.5px solid #6174DD',
//                     padding: '4px 12px',
//                     background: '#FFF',
//                     height: '40px',
//                     width: '100%',
//                     display: 'flex',
//                   }}
//                   value={ratingBy}
//                   onChange={(e) => setRatingBy(e.target.value)}
//                 >
//                   {filterRatings.map((item, index) => (
//                     <MenuItem key={index} value={item.value}>
//                       {item.label === '4' ? (
//                         <>
//                           {item.label}{' '}
//                           <img
//                             src={star_img}
//                             alt="star"
//                             style={{ width: '20px', height: '20px', marginBottom: '3px' }}
//                           />
//                           & above
//                         </>
//                       ) : (
//                         <>
//                           {item.label}{' '}
//                           <img
//                             src={star_img}
//                             alt="star"
//                             style={{ width: '20px', height: '20px', marginBottom: '3px' }}
//                           />
//                           & below
//                         </>
//                       )}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </div>
//             </Grid>

//             {/* Gender Filter */}
//             <Grid item xs={2}>
//               <div>
//                 <InputLabel>Gender</InputLabel>
//                 <Select
//                   style={{
//                     borderRadius: '7px',
//                     border: '0.5px solid #6174DD',
//                     padding: '4px 12px',
//                     background: '#FFF',
//                     height: '40px',
//                     width: '100%',
//                   }}
//                   value={genderBy}
//                   onChange={(e) => setGenderBy(e.target.value)}
//                 >
//                   {filterGenderWise.map((item, index) => (
//                     <MenuItem key={index} value={item.value}>
//                       {item.label}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </div>
//             </Grid>

//             {/* Search */}
//             <Grid item xs={1}>
//               <div>
//                 <InputLabel>Search</InputLabel>
//                 <div className="search-input">
//                   <img src={SearchImg} alt="" />
//                   <input
//                     type="text"
//                     placeholder=""
//                     value={search}
//                     onChange={(e) => setSearch(e.target.value)}
//                   />
//                 </div>
//               </div>
//             </Grid>

//             {/* Apply and Clear Buttons */}
//             <Grid item xs={1} display="flex" gap="20px" alignItems="flex-end">
//               <Button
//                 variant="contained"
//                 color="primary"
//                 sx={{
//                   height: '40px',
//                   width: '100%',
//                   background: '#ff4d4d',
//                   '&:hover': { background: '#ff6666' },
//                 }}
//                 onClick={clearDataHandler}
//               >
//                 Clear
//               </Button>
//             </Grid>
//           </Grid>

//           {/* Download Excel Form Button */}
//           <Grid container justifyContent="flex-end">
//             <Grid item justifyContent="flex-end">
//               {!showLoader && data.length > 0 && (
//                 <Button
//                   variant="contained"
//                   fullWidth
//                   target="_blank"
//                   color="primary"
//                   sx={{ '&:hover': { background: '#6174DD' } }}
//                   onClick={handleOnExport}
//                 >
//                   Download Bills
//                 </Button>
//               )}
//             </Grid>
//           </Grid>

//           {/* Package Bills Button */}
//           <Grid container justifyContent="flex-start">
//             <Grid item justifyContent="flex-start">
//               <Button
//                 variant="contained"
//                 fullWidth
//                 target="_blank"
//                 color="primary"
//                 sx={{ '&:hover': { background: '#6174DD' } }}
//                 onClick={() => {
//                   navigate('/PackageAllBill');
//                 }}
//               >
//                 Package Bills
//               </Button>
//             </Grid>
//           </Grid>
//         </Grid>

//         {/* Show Loader or Data */}
//         {showLoader ? (
//           <ShowLoader />
//         ) : filterData?.length > 0 ? (
//           <Box>
//             <Grid container spacing={3}>
//               <Grid item xs={12} lg={12}>
//                 <FieldData data={filterData} setData={setData} getData={getData} />
//               </Grid>
//             </Grid>
//           </Box>
//         ) : (
//           'No data found'
//         )}

//         {/* Pagination */}
//         <Box display={'flex'} justifyContent={'flex-end'}>
//           <Pagination
//             count={filterData && filterData?.length < limit ? page : page + 1}
//             page={page}
//             color="primary"
//             onChange={(e, page) => setPage(page)}
//           />
//         </Box>

//         <DateRangeInputs
//           isOpen={isModalOpen}
//           onClose={closeModal}
//           onDateRangeSelected={handleDateRangeSelected}
//           selectedEndDate={endDate}
//           selectedStartDate={startDate}
//         />
//       </DashboardCard2>
//     </PageContainer>
//   );
// };

// export default AllBillings;

//new filter code
// import React, { useState, useEffect } from 'react';
// import { Box, Button, Grid, InputLabel, MenuItem, Pagination, Select, Typography, } from '@mui/material';
// import * as XLSX from 'xlsx';
// import PageContainer from 'src/components/container/PageContainer';
// import DashboardCard2 from 'src/components/shared/DashboardCard2';
// import ShowLoader from 'src/components/CustomComponents/ShowLoader';
// import DateRangeInputs from 'src/components/inputs/DateRangeInputs';
// import FieldData from './FieldData';
// import SearchImg from '../../assets/search.svg';
// import { useMain } from '../hooks/useMain';
// import { useNavigate } from 'react-router';
// import star_img from 'src/assets/images/star.png';
// import BigFilter from 'src/components/inputs/BigFilter';
// import { IoStar } from "react-icons/io5";

// const filterMenu = [
//   // { value: 'billsFrequency', label: 'Most Frequent' },
//   // { value: 'totalBillsAmount', label: 'Highest billed' },
//   { value: 'ratings', label: 'Ratings' },
// ];

// const filterGenderWise = [
//   { value: 'Female', label: 'Female' },
//   { value: 'Male', label: 'Male' },
// ];
// // const filterRatings = [
// //   { value: 4, label: '4 <IoStar />& above' },
// //   { value: 3, label: '3 <IoStar />& above' },
// // ];

// const filterRatings = [
//   { value: 4, label: <>4 <IoStar style={{marginBottom:'0.2rem'}} /> & above</> },
//   { value: 3, label: <>3 <IoStar style={{marginBottom:'0.2rem'}} /> & above</> },
// ];

// const AllBillings = () => {
//   const [data, setData] = useState([]);
//   const [showLoader, setShowLoader] = useState(true);
//   const [search, setSearch] = useState('');
//   const [genderBy, setGenderBy] = useState('');
//   const [ratingBy, setRatingBy] = useState('');
//   const [limit, setLimit] = useState(10);
//   const [page, setPage] = useState(1);
//   const [sortBy, setSortBy] = useState('');
//   const [sortValue, setSortValue] = useState(-1);
//   const [clear, setClear] = useState(false);
//   const [startDate, setStartDate] = useState('');
//   const [endDate, setEndDate] = useState('');
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [filterData, setFilterData] = useState(null);

//   const { fetchAllBillings, activeBranchId } = useMain(); // Replace with your actual API import

//   const navigate = useNavigate();

//   useEffect(() => {
//     applyFilters(
//       data,
//       limit,
//       genderBy,
//       ratingBy,
//       page,
//       search,
//       startDate,
//       endDate,
//       sortBy,
//       sortValue,
//     );
//   }, [data, limit, genderBy, ratingBy, page, search, startDate, endDate, sortBy, sortValue]);

//   const openModal = () => setIsModalOpen(true);

//   const closeModal = () => {
//     setIsModalOpen(false);
//   };

//   const handleDateRangeSelected = (startDate, endDate) => {
//     setStartDate(startDate);
//     setEndDate(endDate);
//     setIsModalOpen(false);
//     getData();
//     setClear(true);
//   };

//   const clearDataHandler = () => {
//     setLimit(10);
//     setPage(1);
//     setSearch('');
//     setSortBy('');
//     setSortValue(-1);
//     setGenderBy('');
//     setRatingBy('');
//     setEndDate('');
//     setStartDate('');
//     if (clear) {
//       getData();
//       setClear(false);
//     }
//   };

//   const onApplyHandler = () => {
//     if (search) {
//       getData();
//       setClear(true);
//     }
//   };

//   const getData = async () => {
//     const queryParams = `?page=${page}&limit=${limit}${sortBy ? `&sortKey=${sortBy}&sortValue=${sortValue}` : ''
//       }${genderBy ? `&gender=${genderBy}` : ''}${ratingBy ? `&ratings=${ratingBy}` : ''}${search ? `&search=${search}` : ''
//       }${startDate ? `&startDate=${startDate}` : ''}${endDate ? `&endDate=${endDate}` : ''}`;

//     try {
//       setShowLoader(true);
//       const res = await fetchAllBillings(activeBranchId, queryParams);
//       if (res.statusCode === 200) {
//         setData(res.data || []);
//       } else {
//         console.error('Failed to fetch data:', res);
//       }
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     } finally {
//       setShowLoader(false);
//     }
//   };

//   useEffect(() => {
//     if (activeBranchId) {
//       getData();
//     }
//     return () => {
//       setData([]);
//     };
//   }, [activeBranchId, page, limit, sortBy, sortValue, genderBy, search, startDate, endDate]);

//   const handleLimitChange = (e) => {
//     setLimit(e.target.value);
//     setPage(1);
//     getData();
//   };

//   const handleSortChange = (sortField, sortOrder) => {
//     setSortBy(sortField);
//     setSortValue(sortOrder);
//     setPage(1);
//     getData();
//   };

//   const handleOnExport = () => {
//     // Check if tableData is defined and is an array
//     if (!Array.isArray(data)) {
//       console.error('Data is not an array or is undefined');
//       return;
//     }

//     // Define the fields you want to extract
//     const selectedFields = [
//       'clientName',
//       'clientNumber',
//       'gender',
//       'useMembership',
//       'dateOfBilling',
//       'timeOfBilling',
//       'subTotal',
//       'totalAmount',
//       'amountPayable',
//       'paidDues',
//       'advancedGiven',
//       'preAdvanceUsed',
//       'ratings',
//     ];

//     // Flatten the data and extract fields
//     const flattenedData = data.map((item) => {
//       const flattenedItem = {};

//       // Extract selected fields from item
//       selectedFields.forEach((field) => {
//         if (item.hasOwnProperty(field)) {
//           flattenedItem[field] = item[field];
//         }
//       });
//       // Sum up the amountPaid if it's an array
//       if (Array.isArray(item.amountPaid)) {
//         flattenedItem.amountPaid = item.amountPaid.reduce((total, item) => {
//           return total + parseFloat(item.amount);
//         }, 0);
//       }
//       // Extract and flatten services data
//       if (Array.isArray(item.services)) {
//         flattenedItem.serviceName = item.services
//           .map((serviceItem) => serviceItem.serviceName)
//           .join(', ');
//         flattenedItem.serviceProviders = item.services
//           .map((serviceItem) => serviceItem.serviceProvider?.name || '')
//           .join(', ');
//       }

//       // Extract and flatten products data
//       if (Array.isArray(item.products)) {
//         flattenedItem.productName = item.products
//           .map((productItem) => productItem.productName)
//           .join(', ');
//         flattenedItem.productProvider = item.products
//           .map((productItem) => productItem.providerStaff?.name || '')
//           .join(', ');
//       }

//       return flattenedItem;
//     });

//     // Convert the flattened data to a sheet
//     let wb = XLSX.utils.book_new();
//     let ws = XLSX.utils.json_to_sheet(flattenedData);
//     XLSX.utils.book_append_sheet(wb, ws, 'Bills');

//     // Write the workbook to a file
//     XLSX.writeFile(wb, 'Bills.xlsx');
//   };


//   const applyFilters = (
//     data,
//     limit,
//     gender,
//     ratings,
//     page,
//     search,
//     startDate,
//     endDate,
//     sortBy,
//     sortValue,
//   ) => {
//     let filteredData = data.filter((item) => {
//       let isGenderMatch = true;
//       let isRatingMatch = true;
//       let isNameMatch = true;
//       let isDateMatch = true;

//       if (gender) {
//         isGenderMatch = item.gender === gender;
//       }

//       if (ratings === 4) {
//         isRatingMatch = item.ratings >= ratings;
//       } else if (ratings === 3) {
//         isRatingMatch = item.ratings <= ratings;
//       }

//       if (search) {
//         const clientName = item.clientName || '';
//         const clientNumber = item.clientNumber || '';
//         isNameMatch =
//           clientName.toLowerCase().includes(search.toLowerCase()) || clientNumber.includes(search);
//       }

//       if (startDate && endDate) {
//         const billDate = new Date(item.dateOfBilling);
//         isDateMatch = billDate >= new Date(startDate) && billDate <= new Date(endDate);
//       }

//       return isGenderMatch && isRatingMatch && isNameMatch && isDateMatch;
//     });

//     // Apply sorting
//     if (sortBy) {
//       filteredData = filteredData.sort((a, b) => {
//         if (sortBy === 'ratings') {
//           return sortValue === -1 ? b.ratings - a.ratings : a.ratings - b.ratings;
//         }
//         // Add more sorting logic if needed
//         return 0;
//       });
//     }

//     // Applying pagination
//     const startIndex = (page - 1) * limit;
//     const endIndex = startIndex + limit;
//     filteredData = filteredData.slice(startIndex, endIndex);
//     setFilterData(filteredData);
//   };
//   return (
//     <PageContainer title="Billing History" description="this contains all products">
//       <DashboardCard2 title="Billing History">
//         <Grid container style={{ display: 'flex', gap: '15px' }}>
//           <Grid container justifyContent="space-between">
//             {/* Show per page */}
//             <Grid item xs={2}>
//               <InputLabel>Show per page</InputLabel>
//               <Select
//                 style={{
//                   borderRadius: '7px',
//                   border: '0.5px solid #6174DD',
//                   padding: '4px 12px',
//                   background: '#FFF',
//                   height: '40px',
//                 }}
//                 value={limit}
//                 onChange={handleLimitChange}
//               >
//                 {[10, 20, 50].map((value) => (
//                   <MenuItem key={value} value={value}>
//                     {value}
//                   </MenuItem>
//                 ))}
//               </Select>
//             </Grid>

//             {/* Date Range */}
//             <Grid item xs={2} display={'flex'} flexDirection={'column'} justifyContent={'flex-end'}>
//               <Typography variant="subtitle2" fontWeight={500} component="label">
//                 {startDate && endDate ? `${startDate} to ${endDate}` : ' '}
//               </Typography>
//               <Button
//                 variant="outlined"
//                 target="_blank"
//                 sx={{ color: 'black', height: '40px', width: '100%' }}
//                 onClick={openModal}
//               >
//                 Choose Date
//               </Button>
//             </Grid>

//             {/* <Grid item xs={1}>
//               <InputLabel>Sort By</InputLabel>
//               <Select
//                 style={{
//                   borderRadius: '7px',
//                   border: '0.5px solid #6174DD',
//                   padding: '4px 12px',
//                   background: '#FFF',
//                   height: '40px',
//                   width: '100%',
//                 }}
//                 value={sortBy}
//                 onChange={(e) => setSortBy(e.target.value)}
//               >
//                 {filterMenu.map((item, index) => (
//                   <MenuItem key={index} value={item.value}>
//                     {item.label}
//                   </MenuItem>
//                 ))}
//               </Select>
//             </Grid> */}

//             {/* <Grid item xs={2}>
//               <div>
//                 <InputLabel>Ratings</InputLabel>
//                 <Select
//                   style={{
//                     borderRadius: '7px',
//                     border: '0.5px solid #6174DD',
//                     padding: '4px 12px',
//                     background: '#FFF',
//                     height: '40px',
//                     width: '100%',
//                     display: 'flex',
//                   }}
//                   value={ratingBy}
//                   onChange={(e) => setRatingBy(e.target.value)}
//                 >
//                   {filterRatings.map((item, index) => (
//                     <MenuItem key={index} value={item.value}>
//                       {item.label === '4' ? (
//                         <>
//                           {item.label}{' '}
//                           <img
//                             src={star_img}
//                             alt="star"
//                             style={{ width: '20px', height: '20px', marginBottom: '3px' }}
//                           />
//                           & above
//                         </>
//                       ) : (
//                         <>
//                           {item.label}{' '}
//                           <img
//                             src={star_img}
//                             alt="star"
//                             style={{ width: '20px', height: '20px', marginBottom: '3px' }}
//                           />
//                           & below
//                         </>
//                       )}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </div>
//             </Grid> */}

//             {/* Gender Filter */}
//             {/* <Grid item xs={2}>
//               <div>
//                 <InputLabel>Gender</InputLabel>
//                 <Select
//                   style={{
//                     borderRadius: '7px',
//                     border: '0.5px solid #6174DD',
//                     padding: '4px 12px',
//                     background: '#FFF',
//                     height: '40px',
//                     width: '100%',
//                   }}
//                   value={genderBy}
//                   onChange={(e) => setGenderBy(e.target.value)}
//                 >
//                   {filterGenderWise.map((item, index) => (
//                     <MenuItem key={index} value={item.value}>
//                       {item.label}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </div>
//             </Grid> */}

//             {/* <Grid item xs={12} sm={6} md={3}>
//             <InputLabel>Filters</InputLabel>
//             <BillFilter
//                 options={[
//                   { title: 'Sort By', items: filterMenu },
//                   { title: 'Gender', items: filterGenderWise },
//                   { title: 'Rate By', items: filterRatings }
//                 ]}
//                 value={[genderBy, sortBy, ratingBy]}
//                 onChange={(selectedValues) => {
//                   setSortBy(selectedValues.find(val => filterMenu.some(opt => opt.value === val)));
//                   setGenderBy(selectedValues.find(val => filterGenderWise.some(opt => opt.value === val)));
//                   setRatingBy(selectedValues.find(val => filterRatings.some(opt => opt.value === val)));
//                 }}
//                 placeholder="Select filters"
//               />
//             </Grid> */}
//             <Grid item xs={12} sm={6} md={3}>
//               {/* <InputLabel>Filters</InputLabel> */}
//               <BigFilter
//                 options={[
//                   { title: 'Sort By', items: filterMenu },
//                   { title: 'Gender', items: filterGenderWise },
//                   { title: 'Rate By', items: filterRatings }
//                 ]}
//                 value={[genderBy, sortBy, ratingBy].filter(Boolean)} // Filters out undefined or empty values
//                 onChange={(selectedValues) => {
//                   setSortBy(selectedValues.find(val => filterMenu.some(opt => opt.value === val)));
//                   setGenderBy(selectedValues.find(val => filterGenderWise.some(opt => opt.value === val)));
//                   setRatingBy(selectedValues.find(val => filterRatings.some(opt => opt.value === val)));
//                 }}
//                 placeholder="Filters"
//               />
//             </Grid>

//             {/* Search */}
//             <Grid item xs={1}>
//               <div>
//                 <InputLabel>Search</InputLabel>
//                 <div className="search-input">
//                   <img src={SearchImg} alt="" />
//                   <input
//                     type="text"
//                     placeholder=""
//                     value={search}
//                     onChange={(e) => setSearch(e.target.value)}
//                   />
//                 </div>
//               </div>
//             </Grid>

//             {/* Apply and Clear Buttons */}
//             <Grid item xs={1} display="flex" gap="20px" alignItems="flex-end">
//               <Button
//                 variant="contained"
//                 color="primary"
//                 sx={{
//                   height: '40px',
//                   width: '100%',
//                   background: '#ff4d4d',
//                   '&:hover': { background: '#ff6666' },
//                 }}
//                 onClick={clearDataHandler}
//               >
//                 Clear
//               </Button>
//             </Grid>
//           </Grid>

//           {/* Download Excel Form Button */}
//           <Grid container justifyContent="flex-end">
//             <Grid item justifyContent="flex-end">
//               {!showLoader && data.length > 0 && (
//                 <Button
//                   variant="contained"
//                   fullWidth
//                   target="_blank"
//                   color="primary"
//                   sx={{ '&:hover': { background: '#6174DD' } }}
//                   onClick={handleOnExport}
//                 >
//                   Download Bills
//                 </Button>
//               )}
//             </Grid>
//           </Grid>

//           {/* Package Bills Button */}
//           <Grid container justifyContent="flex-start">
//             <Grid item justifyContent="flex-start">
//               <Button
//                 variant="contained"
//                 fullWidth
//                 target="_blank"
//                 color="primary"
//                 sx={{ '&:hover': { background: '#6174DD' } }}
//                 onClick={() => {
//                   navigate('/PackageAllBill');
//                 }}
//               >
//                 Package Bills
//               </Button>
//             </Grid>
//           </Grid>
//         </Grid>

//         {/* Show Loader or Data */}
//         {showLoader ? (
//           <ShowLoader />
//         ) : filterData?.length > 0 ? (
//           <Box>
//             <Grid container spacing={3}>
//               <Grid item xs={12} lg={12}>
//                 <FieldData data={filterData} setData={setData} getData={getData} />
//               </Grid>
//             </Grid>
//           </Box>
//         ) : (
//           'No data found'
//         )}

//         {/* Pagination */}
//         <Box display={'flex'} justifyContent={'flex-end'}>
//           <Pagination
//             count={filterData && filterData?.length < limit ? page : page + 1}
//             page={page}
//             color="primary"
//             onChange={(e, page) => setPage(page)}
//           />
//         </Box>

//         <DateRangeInputs
//           isOpen={isModalOpen}
//           onClose={closeModal}
//           onDateRangeSelected={handleDateRangeSelected}
//           selectedEndDate={endDate}
//           selectedStartDate={startDate}
//         />
//       </DashboardCard2>
//     </PageContainer>
//   );
// };

// export default AllBillings;

//new filter multiple select
// import React, { useState, useEffect } from 'react';
// import { Box, Button, Grid, InputLabel, MenuItem, Pagination, Select, Typography, } from '@mui/material';
// import * as XLSX from 'xlsx';
// import PageContainer from 'src/components/container/PageContainer';
// import DashboardCard2 from 'src/components/shared/DashboardCard2';
// import ShowLoader from 'src/components/CustomComponents/ShowLoader';
// import DateRangeInputs from 'src/components/inputs/DateRangeInputs';
// import FieldData from './FieldData';
// import SearchImg from '../../assets/search.svg';
// import { useMain } from '../hooks/useMain';
// import { useNavigate } from 'react-router';
// import star_img from 'src/assets/images/star.png';
// import BigFilter from 'src/components/inputs/BigFilter';
// import { IoStar } from "react-icons/io5";

// const filterMenu = [
//   // { value: 'billsFrequency', label: 'Most Frequent' },
//   // { value: 'totalBillsAmount', label: 'Highest billed' },
//   { value: 'ratings', label: 'Ratings' },
// ];

// const filterGenderWise = [
//   { value: 'Female', label: 'Female' },
//   { value: 'Male', label: 'Male' },
// ];
// // const filterRatings = [
// //   { value: 4, label: '4 <IoStar />& above' },
// //   { value: 3, label: '3 <IoStar />& above' },
// // ];

// const filterRatings = [
//   { value: 4, label: <>4 <IoStar style={{ marginBottom: '0.2rem' }} /> & above</> },
//   { value: 3, label: <>3 <IoStar style={{ marginBottom: '0.2rem' }} /> & above</> },
// ];

// const AllBillings = () => {
//   const [data, setData] = useState([]);
//   const [showLoader, setShowLoader] = useState(true);
//   const [search, setSearch] = useState('');
//   // const [genderBy, setGenderBy] = useState('');
//   // const [ratingBy, setRatingBy] = useState('');
//   const [limit, setLimit] = useState(10);
//   const [page, setPage] = useState(1);
//   // const [sortBy, setSortBy] = useState('');
//   const [sortValue, setSortValue] = useState(-1);
//   const [clear, setClear] = useState(false);
//   const [startDate, setStartDate] = useState('');
//   const [endDate, setEndDate] = useState('');
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [filterData, setFilterData] = useState(null);

//   const [selectedSortBy, setSelectedSortBy] = useState([]);
//   const [selectedGenderBy, setSelectedGenderBy] = useState([]);
//   const [selectedRatingBy, setSelectedRatingBy] = useState([]);

//   const { fetchAllBillings, activeBranchId } = useMain(); // Replace with your actual API import

//   const navigate = useNavigate();

//   useEffect(() => {
//     applyFilters(
//       data,
//       limit,
//       // genderBy,
//       // ratingBy,
//       selectedGenderBy,
//       selectedRatingBy,
//       page,
//       search,
//       startDate,
//       endDate,
//       // sortBy,
//       selectedSortBy,
//       sortValue,
//     );
//     // }, [data, limit, genderBy, ratingBy, page, search, startDate, endDate, sortBy, sortValue]);
//   }, [data, limit, selectedGenderBy, selectedRatingBy, page, search, startDate, endDate, selectedSortBy, sortValue]);

//   const openModal = () => setIsModalOpen(true);

//   const closeModal = () => {
//     setIsModalOpen(false);
//   };

//   const handleDateRangeSelected = (startDate, endDate) => {
//     setStartDate(startDate);
//     setEndDate(endDate);
//     setIsModalOpen(false);
//     getData();
//     setClear(true);
//   };

//   const clearDataHandler = () => {
//     setLimit(10);
//     setPage(1);
//     setSearch('');
//     // setSortBy('');
//     setSelectedSortBy([]);
//     setSelectedGenderBy([]);
//     setSelectedRatingBy([]);
//     setSortValue(-1);
//     // setGenderBy('');
//     // setRatingBy('');
//     setEndDate('');
//     setStartDate('');
//     if (clear) {
//       getData();
//       setClear(false);
//     }
//   };

//   const onApplyHandler = () => {
//     if (search) {
//       getData();
//       setClear(true);
//     }
//   };

//   const getData = async () => {
//     const queryParams = `?page=${page}&limit=${limit}${selectedSortBy.length ? `&sortKey=${selectedSortBy}&sortValue=${sortValue}` : ''
//       }${selectedGenderBy.length ? `&gender=${selectedGenderBy}` : ''}${selectedRatingBy.length ? `&ratings=${selectedRatingBy}` : ''}${search ? `&search=${search}` : ''
//       }${startDate ? `&startDate=${startDate}` : ''}${endDate ? `&endDate=${endDate}` : ''}`;

//     try {
//       setShowLoader(true);
//       const res = await fetchAllBillings(activeBranchId, queryParams);
//       if (res.statusCode === 200) {
//         setData(res.data || []);
//       } else {
//         console.error('Failed to fetch data:', res);
//       }
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     } finally {
//       setShowLoader(false);
//     }
//   };

//   useEffect(() => {
//     if (activeBranchId) {
//       getData();
//     }
//     return () => {
//       setData([]);
//     };
//     // }, [activeBranchId, page, limit, sortBy, sortValue, genderBy, search, startDate, endDate]);
//   }, [activeBranchId, page, limit, selectedSortBy, sortValue, selectedGenderBy, search, startDate, endDate]);

//   const handleLimitChange = (e) => {
//     setLimit(e.target.value);
//     setPage(1);
//     getData();
//   };

//   const handleSortChange = (sortField, sortOrder) => {
//     setSelectedSortBy(sortField);
//     setSortValue(sortOrder);
//     setPage(1);
//     getData();
//   };

//   const handleOnExport = () => {
//     // Check if tableData is defined and is an array
//     if (!Array.isArray(data)) {
//       console.error('Data is not an array or is undefined');
//       return;
//     }

//     // Define the fields you want to extract
//     const selectedFields = [
//       'clientName',
//       'clientNumber',
//       'gender',
//       'useMembership',
//       'dateOfBilling',
//       'timeOfBilling',
//       'subTotal',
//       'totalAmount',
//       'amountPayable',
//       'paidDues',
//       'advancedGiven',
//       'preAdvanceUsed',
//       'ratings',
//     ];

//     // Flatten the data and extract fields
//     const flattenedData = data.map((item) => {
//       const flattenedItem = {};

//       // Extract selected fields from item
//       selectedFields.forEach((field) => {
//         if (item.hasOwnProperty(field)) {
//           flattenedItem[field] = item[field];
//         }
//       });
//       // Sum up the amountPaid if it's an array
//       if (Array.isArray(item.amountPaid)) {
//         flattenedItem.amountPaid = item.amountPaid.reduce((total, item) => {
//           return total + parseFloat(item.amount);
//         }, 0);
//       }
//       // Extract and flatten services data
//       if (Array.isArray(item.services)) {
//         flattenedItem.serviceName = item.services
//           .map((serviceItem) => serviceItem.serviceName)
//           .join(', ');
//         flattenedItem.serviceProviders = item.services
//           .map((serviceItem) => serviceItem.serviceProvider?.name || '')
//           .join(', ');
//       }

//       // Extract and flatten products data
//       if (Array.isArray(item.products)) {
//         flattenedItem.productName = item.products
//           .map((productItem) => productItem.productName)
//           .join(', ');
//         flattenedItem.productProvider = item.products
//           .map((productItem) => productItem.providerStaff?.name || '')
//           .join(', ');
//       }

//       return flattenedItem;
//     });

//     // Convert the flattened data to a sheet
//     let wb = XLSX.utils.book_new();
//     let ws = XLSX.utils.json_to_sheet(flattenedData);
//     XLSX.utils.book_append_sheet(wb, ws, 'Bills');

//     // Write the workbook to a file
//     XLSX.writeFile(wb, 'Bills.xlsx');
//   };

//   const applyFilters = (
//     data,
//     limit,
//     gender,
//     ratings,
//     page,
//     search,
//     startDate,
//     endDate,
//     sortBy,
//     sortValue,
//   ) => {
//     let filteredData = data.filter((item) => {
//       let isGenderMatch = true;
//       let isRatingMatch = true;
//       let isNameMatch = true;
//       let isDateMatch = true;

//       // Gender filtering logic
//       if (gender.length) {
//         isGenderMatch = gender.includes(item.gender);
//       }

//       // Ratings filtering logic
//       if (ratings.length) {
//         isRatingMatch = ratings.some(rating => {
//           if (rating === 4) return item.ratings >= 4;
//           if (rating === 3) return item.ratings >= 3 && item.ratings < 4;
//           return false;
//         });
//       }

//       // Search filtering logic
//       if (search) {
//         const clientName = item.clientName || '';
//         const clientNumber = item.clientNumber || '';
//         isNameMatch = clientName.toLowerCase().includes(search.toLowerCase()) || clientNumber.includes(search);
//       }

//       // Date filtering logic
//       if (startDate && endDate) {
//         const billDate = new Date(item.dateOfBilling);
//         isDateMatch = billDate >= new Date(startDate) && billDate <= new Date(endDate);
//       }

//       return isGenderMatch && isRatingMatch && isNameMatch && isDateMatch;
//     });

//     // Apply sorting (adapted to multiple sorts if needed)
//     if (sortBy.length) {
//       sortBy.forEach(sortField => {
//         filteredData = filteredData.sort((a, b) => {
//           if (sortField === 'ratings') {
//             return sortValue === -1 ? b.ratings - a.ratings : a.ratings - b.ratings;
//           }
//           // Add more sorting logic if needed
//           return 0;
//         });
//       });
//     }

//     // Applying pagination
//     const startIndex = (page - 1) * limit;
//     const endIndex = startIndex + limit;
//     filteredData = filteredData.slice(startIndex, endIndex);
//     setFilterData(filteredData);
//   };


//   return (
//     <PageContainer title="Billing History" description="this contains all products">
//       <DashboardCard2 title="Billing History">
//         <Grid container style={{ display: 'flex', gap: '15px' }}>
//           <Grid container justifyContent="space-between">
//             {/* Show per page */}
//             <Grid item xs={2}>
//               <InputLabel>Show per page</InputLabel>
//               <Select
//                 style={{
//                   borderRadius: '7px',
//                   border: '0.5px solid #6174DD',
//                   padding: '4px 12px',
//                   background: '#FFF',
//                   height: '40px',
//                 }}
//                 value={limit}
//                 onChange={handleLimitChange}
//               >
//                 {[10, 20, 50].map((value) => (
//                   <MenuItem key={value} value={value}>
//                     {value}
//                   </MenuItem>
//                 ))}
//               </Select>
//             </Grid>

//             {/* Date Range */}
//             <Grid item xs={2} display={'flex'} flexDirection={'column'} justifyContent={'flex-end'}>
//               <Typography variant="subtitle2" fontWeight={500} component="label">
//                 {startDate && endDate ? `${startDate} to ${endDate}` : ' '}
//               </Typography>
//               <Button
//                 variant="outlined"
//                 target="_blank"
//                 sx={{ color: 'black', height: '40px', width: '100%' }}
//                 onClick={openModal}
//               >
//                 Choose Date
//               </Button>
//             </Grid>
//             <Grid item xs={12} sm={6} md={3}>
//               {/* <InputLabel>Filters</InputLabel> */}
//               {/* <BigFilter
//                 options={[
//                   { title: 'Sort By', items: filterMenu },
//                   { title: 'Gender', items: filterGenderWise },
//                   { title: 'Rate By', items: filterRatings }
//                 ]}
//                 value={[genderBy, sortBy, ratingBy].filter(Boolean)} // Filters out undefined or empty values
//                 onChange={(selectedValues) => {
//                   setSortBy(selectedValues.find(val => filterMenu.some(opt => opt.value === val)));
//                   setGenderBy(selectedValues.find(val => filterGenderWise.some(opt => opt.value === val)));
//                   setRatingBy(selectedValues.find(val => filterRatings.some(opt => opt.value === val)));
//                 }}
//                 placeholder="Filters"
//               /> */}
//               <BigFilter
//                 options={[
//                   { title: 'Sort By', items: filterMenu },
//                   { title: 'Gender', items: filterGenderWise },
//                   { title: 'Rate By', items: filterRatings }
//                 ]}
//                 value={[...selectedGenderBy, ...selectedSortBy, ...selectedRatingBy]} // Flatten the selected values
//                 onChange={(selectedValues) => {
//                   setSelectedSortBy(selectedValues.filter(val => filterMenu.some(opt => opt.value === val)));
//                   setSelectedGenderBy(selectedValues.filter(val => filterGenderWise.some(opt => opt.value === val)));
//                   setSelectedRatingBy(selectedValues.filter(val => filterRatings.some(opt => opt.value === val)));
//                 }}
//                 placeholder="Filters"
//               />

//             </Grid>

//             {/* Search */}
//             <Grid item xs={1}>
//               <div>
//                 <InputLabel>Search</InputLabel>
//                 <div className="search-input">
//                   <img src={SearchImg} alt="" />
//                   <input
//                     type="text"
//                     placeholder=""
//                     value={search}
//                     onChange={(e) => setSearch(e.target.value)}
//                   />
//                 </div>
//               </div>
//             </Grid>

//             {/* Apply and Clear Buttons */}
//             <Grid item xs={1} display="flex" gap="20px" alignItems="flex-end">
//               <Button
//                 variant="contained"
//                 color="primary"
//                 sx={{
//                   height: '40px',
//                   width: '100%',
//                   background: '#ff4d4d',
//                   '&:hover': { background: '#ff6666' },
//                 }}
//                 onClick={clearDataHandler}
//               >
//                 Clear
//               </Button>
//             </Grid>
//           </Grid>

//           {/* Download Excel Form Button */}
//           <Grid container justifyContent="flex-end">
//             <Grid item justifyContent="flex-end">
//               {!showLoader && data.length > 0 && (
//                 <Button
//                   variant="contained"
//                   fullWidth
//                   target="_blank"
//                   color="primary"
//                   sx={{ '&:hover': { background: '#6174DD' } }}
//                   onClick={handleOnExport}
//                 >
//                   Download Bills
//                 </Button>
//               )}
//             </Grid>
//           </Grid>

//           {/* Package Bills Button */}
//           <Grid container justifyContent="flex-start">
//             <Grid item justifyContent="flex-start">
//               <Button
//                 variant="contained"
//                 fullWidth
//                 target="_blank"
//                 color="primary"
//                 sx={{ '&:hover': { background: '#6174DD' } }}
//                 onClick={() => {
//                   navigate('/PackageAllBill');
//                 }}
//               >
//                 Package Bills
//               </Button>
//             </Grid>
//           </Grid>
//         </Grid>

//         {/* Show Loader or Data */}
//         {showLoader ? (
//           <ShowLoader />
//         ) : filterData?.length > 0 ? (
//           <Box>
//             <Grid container spacing={3}>
//               <Grid item xs={12} lg={12}>
//                 <FieldData data={filterData} setData={setData} getData={getData} />
//               </Grid>
//             </Grid>
//           </Box>
//         ) : (
//           'No data found'
//         )}

//         {/* Pagination */}
//         <Box display={'flex'} justifyContent={'flex-end'}>
//           <Pagination
//             count={filterData && filterData?.length < limit ? page : page + 1}
//             page={page}
//             color="primary"
//             onChange={(e, page) => setPage(page)}
//           />
//         </Box>

//         <DateRangeInputs
//           isOpen={isModalOpen}
//           onClose={closeModal}
//           onDateRangeSelected={handleDateRangeSelected}
//           selectedEndDate={endDate}
//           selectedStartDate={startDate}
//         />
//       </DashboardCard2>
//     </PageContainer>
//   );
// };

// export default AllBillings;

//correct pagination all data one time

//correct pagination new code
import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, InputLabel, MenuItem, Pagination, Select, Typography } from '@mui/material';
import * as XLSX from 'xlsx';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard2 from 'src/components/shared/DashboardCard2';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import DateRangeInputs from 'src/components/inputs/DateRangeInputs';
import FieldData from './FieldData';
import SearchImg from '../../assets/search.svg';
import { useMain } from '../hooks/useMain';
import { useNavigate } from 'react-router';
import BigFilter from 'src/components/inputs/BigFilter';
import { IoStar } from "react-icons/io5";

const filterMenu = [
  { value: 'ratings', label: 'Ratings' },
];

const filterGenderWise = [
  { value: 'Female', label: 'Female' },
  { value: 'Male', label: 'Male' },
];

const filterRatings = [
  { value: 4, label: <>4 <IoStar style={{ marginBottom: '0.2rem' }} /> & above</> },
  { value: 3, label: <>3 <IoStar style={{ marginBottom: '0.2rem' }} /> & above</> },
];

const AllBillings = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [search, setSearch] = useState('');
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [sortValue, setSortValue] = useState(-1);
  const [clear, setClear] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSortBy, setSelectedSortBy] = useState([]);
  const [selectedGenderBy, setSelectedGenderBy] = useState([]);
  const [selectedRatingBy, setSelectedRatingBy] = useState([]);

  const { fetchAllBillings, activeBranchId } = useMain();
  const navigate = useNavigate();

  useEffect(() => {
    getData(page);
    // eslint-disable-next-line
  }, [activeBranchId]);

  const getData = async () => {
    const queryParams = `?limit=10000`; // Fetch a large number of records initially for client-side operations
    try {
      setShowLoader(true); // Start loader before fetching
      const res = await fetchAllBillings(activeBranchId, queryParams);
      if (res.statusCode === 200) {
        setData(res.data || []);
        setFilteredData(res.data || []); // Initialize with fetched data
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  // const applyFilters = () => {
  //   let filteredData = data.filter((item) => {
  //     let isGenderMatch = true;
  //     let isRatingMatch = true;
  //     let isNameMatch = true;
  //     let isDateMatch = true;

  //     if (selectedGenderBy.length) {
  //       isGenderMatch = selectedGenderBy.includes(item.gender);
  //     }

  //     if (selectedRatingBy.length) {
  //       isRatingMatch = selectedRatingBy.some(rating => {
  //         if (rating === 4) return item.ratings >= 4;
  //         if (rating === 3) return item.ratings >= 3 && item.ratings < 4;
  //         return false;
  //       });
  //     }

  //     if (search) {
  //       const clientName = item.clientName || '';
  //       const clientNumber = item.clientNumber || '';
  //       isNameMatch = clientName.toLowerCase().includes(search.toLowerCase()) || clientNumber.includes(search);
  //     }

  //     if (startDate && endDate) {
  //       const billDate = new Date(item.dateOfBilling);
  //       isDateMatch = billDate >= new Date(startDate) && billDate <= new Date(endDate);
  //     }

  //     return isGenderMatch && isRatingMatch && isNameMatch && isDateMatch;
  //   });

  //   if (selectedSortBy.length) {
  //     filteredData = filteredData.sort((a, b) => {
  //       if (selectedSortBy[0] === 'ratings') {
  //         return sortValue === -1 ? b.ratings - a.ratings : a.ratings - b.ratings;
  //       }
  //       return 0;
  //     });
  //   }

  //   const startIndex = (page - 1) * limit;
  //   const endIndex = startIndex + limit;
  //   filteredData = filteredData.slice(startIndex, endIndex);
  //   setFilteredData(filteredData);
  // };

  const applyFilters = () => {
    if (!data.length) return;

    let filteredData = data.filter((item) => {
      let isGenderMatch = true;
      let isRatingMatch = true;
      let isNameMatch = true;
      let isDateMatch = true;

      if (selectedGenderBy.length) {
        isGenderMatch = selectedGenderBy.includes(item.gender);
      }

      if (selectedRatingBy.length) {
        isRatingMatch = selectedRatingBy.some(rating => {
          if (rating === 4) return item.ratings >= 4;
          if (rating === 3) return item.ratings >= 3 && item.ratings < 4;
          return false;
        });
      }

      if (search) {
        const clientName = item.clientName || '';
        const clientNumber = item.clientNumber || '';
        isNameMatch = clientName.toLowerCase().includes(search.toLowerCase()) || clientNumber.includes(search);
      }

      if (startDate && endDate) {
        const billDate = new Date(item.dateOfBilling);
        isDateMatch = billDate >= new Date(startDate) && billDate <= new Date(endDate);
      }

      return isGenderMatch && isRatingMatch && isNameMatch && isDateMatch;
    });

    if (selectedSortBy.length) {
      filteredData = filteredData.sort((a, b) => {
        if (selectedSortBy[0] === 'ratings') {
          return sortValue === -1 ? b.ratings - a.ratings : a.ratings - b.ratings;
        }
        return 0;
      });
    }

    const startIndex = (page - 1) * limit;
    const endIndex = startIndex + limit;
    filteredData = filteredData.slice(startIndex, endIndex);

    setFilteredData(filteredData);
    setShowLoader(false); // Stop loader after filters are applied
  };


  useEffect(() => {
    applyFilters();
    // eslint-disable-next-line
  }, [data, limit, selectedGenderBy, selectedRatingBy, page, search, startDate, endDate, selectedSortBy, sortValue]);

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setPage(page);
  };

  const handleSortChange = (sortField, sortOrder) => {
    setSelectedSortBy(sortField);
    setSortValue(sortOrder);
    setPage(1);
  };

  const handleOnExport = () => {
    if (!Array.isArray(data)) {
      console.error('Data is not an array or is undefined');
      return;
    }

    const selectedFields = [
      'clientName', 'clientNumber', 'gender', 'useMembership', 'dateOfBilling', 'timeOfBilling',
      'subTotal', 'totalAmount', 'amountPayable', 'paidDues', 'advancedGiven', 'preAdvanceUsed',
      'ratings',
    ];

    const flattenedData = data.map((item) => {
      const flattenedItem = {};
      selectedFields.forEach((field) => {
        if (item.hasOwnProperty(field)) {
          flattenedItem[field] = item[field];
        }
      });
      if (Array.isArray(item.amountPaid)) {
        flattenedItem.amountPaid = item.amountPaid.reduce((total, item) => total + parseFloat(item.amount), 0);
      }
      if (Array.isArray(item.services)) {
        flattenedItem.serviceName = item.services.map(serviceItem => serviceItem.serviceName).join(', ');
        flattenedItem.serviceProviders = item.services.map(serviceItem => serviceItem.serviceProvider?.name || '').join(', ');
      }
      if (Array.isArray(item.products)) {
        flattenedItem.productName = item.products.map(productItem => productItem.productName).join(', ');
        flattenedItem.productProvider = item.products.map(productItem => productItem.providerStaff?.name || '').join(', ');
      }
      return flattenedItem;
    });

    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.json_to_sheet(flattenedData);
    XLSX.utils.book_append_sheet(wb, ws, 'Bills');
    XLSX.writeFile(wb, 'Bills.xlsx');
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleDateRangeSelected = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
    setIsModalOpen(false);
    setClear(true);
    applyFilters();
  };

  const clearDataHandler = () => {
    setLimit(10);
    setPage(1);
    setSearch('');
    setSelectedSortBy([]);
    setSelectedGenderBy([]);
    setSelectedRatingBy([]);
    setSortValue(-1);
    setEndDate('');
    setStartDate('');
    setClear(false);
    applyFilters();
  };

  return (
    <PageContainer title="Billing History" description="this contains all products">
      <DashboardCard2 title="Billing History">
        <Grid container style={{ display: 'flex', gap: '15px' }}>
          <Grid container justifyContent="space-between">
            <Grid item xs={2}>
              <InputLabel>Show per page</InputLabel>
              <Select
                style={{
                  borderRadius: '7px',
                  border: '0.5px solid #6174DD',
                  padding: '4px 12px',
                  background: '#FFF',
                  height: '40px',
                }}
                value={limit}
                onChange={handleLimitChange}
              >
                {[10, 20, 50].map((value) => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid item xs={2} display={'flex'} flexDirection={'column'} justifyContent={'flex-end'}>
              <Typography variant="subtitle2" fontWeight={500} component="label">
                {startDate && endDate ? `${startDate} to ${endDate}` : ' '}
              </Typography>
              <Button
                variant="outlined"
                sx={{ color: 'black', height: '40px', width: '100%', marginLeft: '9rem', marginBottom: '0.3rem' }}
                onClick={openModal}
              >
                Choose Date
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <BigFilter
                options={[
                  // { title: 'Sort By', items: filterMenu },
                  { title: 'Gender', items: filterGenderWise },
                  { title: 'Rate By', items: filterRatings }
                ]}
                value={[...selectedGenderBy, ...selectedSortBy, ...selectedRatingBy]}
                onChange={(selectedValues) => {
                  // setSelectedSortBy(selectedValues.filter(val => filterMenu.some(opt => opt.value === val)));
                  setSelectedGenderBy(selectedValues.filter(val => filterGenderWise.some(opt => opt.value === val)));
                  setSelectedRatingBy(selectedValues.filter(val => filterRatings.some(opt => opt.value === val)));
                }}
                placeholder="Filters"
              />
            </Grid>

            <Grid item xs={1}>
              <div>
                <InputLabel>Search</InputLabel>
                <div className="search-input">
                  <img src={SearchImg} alt="" />
                  <input
                    sx={{ width: '100%' }}
                    type="text"
                    placeholder=""
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
            </Grid>

            <Grid item xs={1} display="flex" alignItems="flex-end">
              <Button
                variant="contained"
                color="primary"
                sx={{
                  height: '40px',
                  width: '100%',
                  background: '#ff4d4d',
                  marginBottom: '0.3rem',
                  marginTop: '1.8rem',
                  '&:hover': { background: '#ff6666' },
                }}
                onClick={clearDataHandler}
              >
                Clear
              </Button>
            </Grid>

            {/* <Grid container justifyContent="flex-end"> */}
            <Grid item>
              {!showLoader && data.length > 0 && (
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  sx={{ marginTop: '1.8rem', padding: "0.5rem", '&:hover': { background: '#6174DD' } }}
                  onClick={handleOnExport}
                >
                  Download Bills
                </Button>
              )}
            </Grid>
            {/* </Grid> */}
          </Grid>

          <Grid container justifyContent={'space-between'}>
            <Grid item justifyContent="flex-start">
              <Button
                variant="contained"
                fullWidth
                color="primary"
                sx={{ '&:hover': { background: '#6174DD' } }}
                onClick={() => navigate('/PackageAllBill')}
              >
                Package Bills
              </Button>
            </Grid>
            <Grid>
              <Button
                variant="contained"
                fullWidth
                color="primary"
                sx={{ '&:hover': { background: '#6174DD' } }}
                onClick={() => navigate('/AllDraftBills')}
              >
                All Draft Bills
              </Button>
            </Grid>
          </Grid>
        </Grid>

        {/* {showLoader ? (
          <ShowLoader />
        ) : filteredData.length > 0 ? (
          <Box>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <FieldData data={filteredData} setData={setData} getData={getData} />
              </Grid>
            </Grid>
          </Box>
        ) : (
          'No data found'
        )} */}
        {showLoader ? (
          <ShowLoader />
        ) : filteredData.length > 0 ? (
          <Box>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <FieldData data={filteredData} setData={setData} getData={getData} />
              </Grid>
            </Grid>
          </Box>
        ) : (
          data.length > 0 && 'No data found'
        )}


        <Box display={'flex'} justifyContent={'flex-end'}>
          <Pagination
            count={Math.ceil(data.length / limit)}
            page={page}
            color="primary"
            onChange={(e, page) => {
              setPage(page);
              applyFilters();
            }}
          />
        </Box>

        <DateRangeInputs
          isOpen={isModalOpen}
          onClose={closeModal}
          onDateRangeSelected={handleDateRangeSelected}
          selectedEndDate={endDate}
          selectedStartDate={startDate}
        />
      </DashboardCard2>
    </PageContainer>
  );
};

export default AllBillings;

// on every page click data fetch
// import React, { useState, useEffect } from 'react';
// import { Box, Button, Grid, InputLabel, MenuItem, Pagination, Select, Typography } from '@mui/material';
// import * as XLSX from 'xlsx';
// import PageContainer from 'src/components/container/PageContainer';
// import DashboardCard2 from 'src/components/shared/DashboardCard2';
// import ShowLoader from 'src/components/CustomComponents/ShowLoader';
// import DateRangeInputs from 'src/components/inputs/DateRangeInputs';
// import FieldData from './FieldData';
// import SearchImg from '../../assets/search.svg';
// import { useMain } from '../hooks/useMain';
// import { useNavigate } from 'react-router';
// import BigFilter from 'src/components/inputs/BigFilter';
// import { IoStar } from "react-icons/io5";

// const filterMenu = [
//   { value: 'ratings', label: 'Ratings' },
// ];

// const filterGenderWise = [
//   { value: 'Female', label: 'Female' },
//   { value: 'Male', label: 'Male' },
// ];

// const filterRatings = [
//   { value: 4, label: <>4 <IoStar style={{ marginBottom: '0.2rem' }} /> & above</> },
//   { value: 3, label: <>3 <IoStar style={{ marginBottom: '0.2rem' }} /> & above</> },
// ];

// const AllBillings = () => {
//   const [data, setData] = useState([]);
//   const [filteredData, setFilteredData] = useState([]);
//   const [showLoader, setShowLoader] = useState(true);
//   const [search, setSearch] = useState('');
//   const [limit, setLimit] = useState(10);
//   const [page, setPage] = useState(1);
//   const [totalPages, setTotalPages] = useState(1);
//   const [sortValue, setSortValue] = useState(-1);
//   const [clear, setClear] = useState(false);
//   const [startDate, setStartDate] = useState('');
//   const [endDate, setEndDate] = useState('');
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const [selectedSortBy, setSelectedSortBy] = useState([]);
//   const [selectedGenderBy, setSelectedGenderBy] = useState([]);
//   const [selectedRatingBy, setSelectedRatingBy] = useState([]);

//   const { fetchAllBillings, activeBranchId } = useMain();
//   const navigate = useNavigate();

//   const getData = async (currentPage = 1, currentLimit = 10) => {
//     // const queryParams = `?limit=${currentLimit}&page=${currentPage}`; // Include limit and page in the API call
//     // const queryParams = `?page=${currentPage}&limit=${currentLimit}`; // Include limit and page in the API call
//     const queryParams = `?page=${currentPage}&limit=${currentLimit}`; // Include limit and page in the API call
//     try {
//       setShowLoader(true); // Start loader before fetching
//       const res = await fetchAllBillings(activeBranchId, queryParams);
//       if (res.statusCode === 200) {
//         setData(res.data || []);
//         // setFilteredData(res.data || []); // Initialize with fetched data
//         setTotalPages(Math.ceil(res.totalRecords / currentLimit));
//       } else {
//         setData([]);
//         // setFilteredData([]);
//         setTotalPages(1); // Reset to 1 page if no data
//       }
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     } finally {
//       // setShowLoader(false); // Stop loader after fetching
//     }
//   };

//   const applyFilters = () => {
//     if (!data.length) return;

//     let filteredData = data.filter((item) => {
//       let isGenderMatch = true;
//       let isRatingMatch = true;
//       let isNameMatch = true;
//       let isDateMatch = true;

//       if (selectedGenderBy.length) {
//         isGenderMatch = selectedGenderBy.includes(item.gender);
//       }

//       if (selectedRatingBy.length) {
//         isRatingMatch = selectedRatingBy.some(rating => {
//           if (rating === 4) return item.ratings >= 4;
//           if (rating === 3) return item.ratings >= 3 && item.ratings < 4;
//           return false;
//         });
//       }

//       if (search) {
//         const clientName = item.clientName || '';
//         const clientNumber = item.clientNumber || '';
//         isNameMatch = clientName.toLowerCase().includes(search.toLowerCase()) || clientNumber.includes(search);
//       }

//       if (startDate && endDate) {
//         const billDate = new Date(item.dateOfBilling);
//         isDateMatch = billDate >= new Date(startDate) && billDate <= new Date(endDate);
//       }

//       return isGenderMatch && isRatingMatch && isNameMatch && isDateMatch;
//     });

//     setFilteredData(filteredData);
//     setShowLoader(false); // Stop loader after filters are applied
//   };


//   // Update effect to fetch data when page or limit changes
//   useEffect(() => {
//     getData(page, limit); // Fetch data on page change
//     // eslint-disable-next-line
//   }, [activeBranchId,page, limit]);


//   useEffect(() => {
//     applyFilters();
//     // eslint-disable-next-line
//   }, [data, limit, selectedGenderBy, selectedRatingBy, page, search, startDate, endDate, selectedSortBy, sortValue]);

//   const handleLimitChange = (e) => {
//     setLimit(e.target.value);
//     setPage(1);
//   };

//   const handleSortChange = (sortField, sortOrder) => {
//     setSelectedSortBy(sortField);
//     setSortValue(sortOrder);
//     setPage(1);
//   };

//   const handleOnExport = () => {
//     if (!Array.isArray(data)) {
//       console.error('Data is not an array or is undefined');
//       return;
//     }

//     const selectedFields = [
//       'clientName', 'clientNumber', 'gender', 'useMembership', 'dateOfBilling', 'timeOfBilling',
//       'subTotal', 'totalAmount', 'amountPayable', 'paidDues', 'advancedGiven', 'preAdvanceUsed',
//       'ratings',
//     ];

//     const flattenedData = data.map((item) => {
//       const flattenedItem = {};
//       selectedFields.forEach((field) => {
//         if (item.hasOwnProperty(field)) {
//           flattenedItem[field] = item[field];
//         }
//       });
//       if (Array.isArray(item.amountPaid)) {
//         flattenedItem.amountPaid = item.amountPaid.reduce((total, item) => total + parseFloat(item.amount), 0);
//       }
//       if (Array.isArray(item.services)) {
//         flattenedItem.serviceName = item.services.map(serviceItem => serviceItem.serviceName).join(', ');
//         flattenedItem.serviceProviders = item.services.map(serviceItem => serviceItem.serviceProvider?.name || '').join(', ');
//       }
//       if (Array.isArray(item.products)) {
//         flattenedItem.productName = item.products.map(productItem => productItem.productName).join(', ');
//         flattenedItem.productProvider = item.products.map(productItem => productItem.providerStaff?.name || '').join(', ');
//       }
//       return flattenedItem;
//     });

//     let wb = XLSX.utils.book_new();
//     let ws = XLSX.utils.json_to_sheet(flattenedData);
//     XLSX.utils.book_append_sheet(wb, ws, 'Bills');
//     XLSX.writeFile(wb, 'Bills.xlsx');
//   };

//   const openModal = () => setIsModalOpen(true);
//   const closeModal = () => setIsModalOpen(false);

//   const handleDateRangeSelected = (startDate, endDate) => {
//     setStartDate(startDate);
//     setEndDate(endDate);
//     setIsModalOpen(false);
//     setClear(true);
//     applyFilters();
//   };

//   const clearDataHandler = () => {
//     setLimit(10);
//     setPage(1);
//     setSearch('');
//     setSelectedSortBy([]);
//     setSelectedGenderBy([]);
//     setSelectedRatingBy([]);
//     setSortValue(-1);
//     setEndDate('');
//     setStartDate('');
//     setClear(false);
//     applyFilters();
//   };

//   // Handle pagination click to fetch new page data
//   const handlePageChange = (e, page) => {
//     setPage(page);
//     applyFilters();
//     // getData(newPage, limit); // Fetch data for the new page
//   };

//   return (
//     <PageContainer title="Billing History" description="this contains all products">
//       <DashboardCard2 title="Billing History">
//         <Grid container style={{ display: 'flex', gap: '15px' }}>
//           <Grid container justifyContent="space-between">
//             <Grid item xs={2}>
//               <InputLabel>Show per page</InputLabel>
//               <Select
//                 style={{
//                   borderRadius: '7px',
//                   border: '0.5px solid #6174DD',
//                   padding: '4px 12px',
//                   background: '#FFF',
//                   height: '40px',
//                 }}
//                 value={limit}
//                 onChange={handleLimitChange}
//               >
//                 {[10, 20, 50].map((value) => (
//                   <MenuItem key={value} value={value}>
//                     {value}
//                   </MenuItem>
//                 ))}
//               </Select>
//             </Grid>

//             <Grid item xs={2} display={'flex'} flexDirection={'column'} justifyContent={'flex-end'}>
//               <Typography variant="subtitle2" fontWeight={500} component="label">
//                 {startDate && endDate ? `${startDate} to ${endDate}` : ' '}
//               </Typography>
//               <Button
//                 variant="outlined"
//                 sx={{ color: 'black', height: '40px', width: '100%' }}
//                 onClick={openModal}
//               >
//                 Choose Date
//               </Button>
//             </Grid>
//             <Grid item xs={12} sm={6} md={3}>
//               <BigFilter
//                 options={[
//                   // { title: 'Sort By', items: filterMenu },
//                   { title: 'Gender', items: filterGenderWise },
//                   { title: 'Rate By', items: filterRatings }
//                 ]}
//                 value={[...selectedGenderBy, ...selectedSortBy, ...selectedRatingBy]}
//                 onChange={(selectedValues) => {
//                   // setSelectedSortBy(selectedValues.filter(val => filterMenu.some(opt => opt.value === val)));
//                   setSelectedGenderBy(selectedValues.filter(val => filterGenderWise.some(opt => opt.value === val)));
//                   setSelectedRatingBy(selectedValues.filter(val => filterRatings.some(opt => opt.value === val)));
//                 }}
//                 placeholder="Filters"
//               />
//             </Grid>

//             <Grid item xs={1}>
//               <div>
//                 <InputLabel>Search</InputLabel>
//                 <div className="search-input">
//                   <img src={SearchImg} alt="" />
//                   <input
//                     type="text"
//                     placeholder=""
//                     value={search}
//                     onChange={(e) => setSearch(e.target.value)}
//                   />
//                 </div>
//               </div>
//             </Grid>

//             <Grid item xs={1} display="flex" gap="20px" alignItems="flex-end">
//               <Button
//                 variant="contained"
//                 color="primary"
//                 sx={{
//                   height: '40px',
//                   width: '100%',
//                   background: '#ff4d4d',
//                   '&:hover': { background: '#ff6666' },
//                 }}
//                 onClick={clearDataHandler}
//               >
//                 Clear
//               </Button>
//             </Grid>
//           </Grid>

//           <Grid container justifyContent="flex-end">
//             <Grid item justifyContent="flex-end">
//               {!showLoader && data.length > 0 && (
//                 <Button
//                   variant="contained"
//                   fullWidth
//                   color="primary"
//                   sx={{ '&:hover': { background: '#6174DD' } }}
//                   onClick={handleOnExport}
//                 >
//                   Download Bills
//                 </Button>
//               )}
//             </Grid>
//           </Grid>

//           <Grid container justifyContent="flex-start">
//             <Grid item justifyContent="flex-start">
//               <Button
//                 variant="contained"
//                 fullWidth
//                 color="primary"
//                 sx={{ '&:hover': { background: '#6174DD' } }}
//                 onClick={() => navigate('/PackageAllBill')}
//               >
//                 Package Bills
//               </Button>
//             </Grid>
//           </Grid>
//         </Grid>
//         {showLoader ? (
//           <ShowLoader />
//         ) : filteredData.length > 0 ? (
//           <Box>
//             <Grid container spacing={3}>
//               <Grid item xs={12} lg={12}>
//                 <FieldData data={filteredData} setData={setData} getData={getData} />
//               </Grid>
//             </Grid>
//           </Box>
//         ) : (
//           data.length > 0 && 'No data found'
//         )}


//         <Box display={'flex'} justifyContent={'flex-end'}>
//           {/* <Pagination
//             count={Math.ceil(data.length / limit)}
//             page={page}
//             color="primary"
//             onChange={(e, page) => {
//               setPage(page);
//               applyFilters();
//             }}
//           /> */}
//           <Pagination
//             // count={totalPages} // Updated to show the correct number of pages
//             count={filteredData && filteredData?.length < limit ? page : page + 1} // Updated to show the correct number of pages
//             page={page} // Controls the current page
//             color="primary"
//             onChange={handlePageChange} // Calls the handlePageChange function on page click
//           />
//         </Box>

//         <DateRangeInputs
//           isOpen={isModalOpen}
//           onClose={closeModal}
//           onDateRangeSelected={handleDateRangeSelected}
//           selectedEndDate={endDate}
//           selectedStartDate={startDate}
//         />
//       </DashboardCard2>
//     </PageContainer>
//   );
// };

// export default AllBillings;
