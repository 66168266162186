import React, { useState } from 'react';

const Suggestion = ({ data, handleSuggestedClick }) => {
  const [hoveredSuggestion, setHoveredSuggestion] = useState(null);

  const handleChange = (suggestedName) => {
    handleSuggestedClick(suggestedName);
  };

  const ulStyle = {
    listStyle: "none",
    padding: 0,
    margin: 0,
    position: "absolute",
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    zIndex: 1000,
    maxHeight: "300px",
    overflowY: "auto",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    width: "330px",
  };

  const liStyle = {
    padding: '8px',
    cursor: 'pointer',
  };
  
  // CSS for customizing scrollbar in a React component
  const scrollbarStyle = `
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: #888;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  `;

  return (
    <ul style={ulStyle} className="custom-scrollbar">
      {data.map((suggestedName, index) => (
        <li
          key={index}
          onClick={() => handleChange(suggestedName)}
          style={{
            ...liStyle,
            ...(hoveredSuggestion === index?{backgroundColor: '#6174DD',color:'white'}:'')      
          }}
          onMouseEnter={() => setHoveredSuggestion(index)}
          onMouseLeave={() => setHoveredSuggestion(null)}
        >
          {suggestedName}
        </li>
      ))}
      <style>
        {`
          .custom-scrollbar {
            ${scrollbarStyle}
          }
        `}
      </style>
    </ul>
  );
};

export default Suggestion;
