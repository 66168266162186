import React, { useEffect, useState } from 'react'
import PageContainer from 'src/components/container/PageContainer'
import FieldData from './FieldData'
import { Box, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { useMain } from '../hooks/useMain'
import ShowLoader from 'src/components/CustomComponents/ShowLoader'
import DashboardCard2 from 'src/components/shared/DashboardCard2'
import { Search } from '@mui/icons-material'

const data = [
    {
        id: 1,
        name: 'John Doe',
        contactNumber: '1234567890',
        inviteCode: 'ABCD123',
        firstVisit: '2023-06-01',
        lastVisit: '2023-06-07',
        lastService: 'Massage',
        lastServiceProvider: 'Jane Smith',
        lastBillAmount: 150,
        gender: 'Male',
    },
    {
        id: 2,
        name: 'Jane Smith',
        contactNumber: '9876543210',
        inviteCode: 'EFGH456',
        firstVisit: '2023-06-03',
        lastVisit: '2023-06-09',
        lastService: 'Facial',
        lastServiceProvider: 'John Doe',
        lastBillAmount: 200,
        gender: 'Female',
    },
    // Add more data objects here
];

const AllRequests = () => {
    const [data, setData] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);

    const { getAllRequests } = useMain();

    const getData = async () => {
        setShowLoader(true);
        const res = await getAllRequests({ limit: 10, page: activePage });
        setData(res?.data);
        setTotalPage(res?.data);
        setShowLoader(false);
    }

    useEffect(() => {
        getData();

        return () => { }
    }, []);

    return (
        <PageContainer title="All Requests" description="this contains all products">
            <DashboardCard2 title="All Requests">
                <Grid container spacing={2} sx={{ mt: 1, mb: 3 }} alignItems="center">
                    <Grid item xs={6}>
                        <FormControl variant="standard" style={{ minWidth: 120 }}>
                            <InputLabel>Show per page</InputLabel>
                            <Select value={10} onChange={() => { }}>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={20}>20</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} container justifyContent="flex-end">
                        <TextField
                            label="Search"
                            variant="standard"
                            fullWidth
                            style={{
                                maxWidth: '300px'
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
                <Box>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={12}>
                            <FieldData data={data} setData={setData} />
                        </Grid>
                    </Grid>
                </Box>
                {showLoader && <ShowLoader value={showLoader} />}
            </DashboardCard2>
        </PageContainer>
    )
}

export default AllRequests