import React, { useEffect, useState } from 'react';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard from 'src/components/shared/DashboardCard';
import { Button, Grid } from '@mui/material';
import { Box } from '@mui/system';
import TextInputs from 'src/components/inputs/TextInputs';
import ClinetSuggestion from './ClinetSuggestion';
import { useMain } from '../hooks/useMain';
import RadioInputs from 'src/components/inputs/RadioInputs';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import SelectInputs from 'src/components/inputs/SelectInputs';
import NewSelectInput from './NewSelectInput';
import ServiceSuggestion from '../Billings/ServiceSuggestion';
import { IoStar } from 'react-icons/io5';
import TimeSlotSelector from 'src/components/inputs/TimeSlotSelector';

const initialValue = {
  clientName: '',
  clientNumber: '',
  dateOfAppointment: getCurrentDateInFormat(),
  sourceOfAppointment: 'Walk In',
  timeOfAppointment: '',
  gender: '',
  serviceSelected: [
    {
      service: '',
      serviceName: '',
      serviceProvider: '',
    },
  ],
};

const initialFormValidation = {
  clientNameValidation: false,
  clientNumberValidation: false,
  clientGenderValidation: false,
  dateOfAppointmentValidation: false,
  timeOfAppointmentValidation: false,
  serviceSelectedValidation: [
    {
      serviceValidation: false,
      serviceProviderValidation: false,
    },
  ],
};

const sourceOptions = [
  { title: 'Walk In', value: 'Walk In' },
  { title: 'Existing', value: 'Existing' },
  { title: 'Phone Call', value: 'Phone Call' },
  { title: 'Facebook', value: 'Facebook' },
];

const AddAppointment = () => {
  const { createAppointment, activeBranchId, getBranch, setShowMessage } = useMain();

  const [formData, setFormData] = useState(initialValue);
  const [formValidation, setFormValidation] = useState(initialFormValidation);
  const [servicesCat, setServicesCat] = useState([]);
  const [genderWiseService, setGenderWiseService] = useState([]);
  const [services, setServices] = useState([]);
  const [staff, setStaff] = useState([]);
  const [client, setClient] = useState([]);
  const [suggested, setSuggested] = useState([]);
  const [activeInputField, setActiveInputField] = useState('');
  const [loading, setLoader] = useState(false);
  const [focusedServiceIndex, setFocusedServiceIndex] = useState(null);
  const [serviceSuggested, setServiceSuggested] = useState([]);
  const clientNameValidation = !formData.clientName.trim();
  const clientNumberValidation = !formData.clientNumber || formData.clientNumber.length !== 10;
  const clientGenderValidation = !formData.gender;
  const dateOfAppointmentValidation = !formData.dateOfAppointment;
  const timeOfAppointmentValidation = !formData.timeOfAppointment;
  const serviceSelectedValidation = formData.serviceSelected.map((item) => ({
    serviceValidation: !item.service.trim(),
    serviceProviderValidation: item.serviceProvider === '',
  }));

  const updateServiceSuggested = (inputValue) => {
    if (inputValue === '') {
      setServiceSuggested(genderWiseService);
    } else {
      const serviceSuggestions = genderWiseService?.filter((service) =>
        service.serviceName.toLowerCase().includes(inputValue.toLowerCase()),
      );
      setServiceSuggested(serviceSuggestions);
    }
  };

  const handleServiceInputChange = (e, index) => {
    const { name, value } = e.target;
    if (name === 'service') {
      const updatedServiceSelected = [...formData.serviceSelected];
      updatedServiceSelected[index]['service'] = '';
      updatedServiceSelected[index]['serviceName'] = value;
      setFormData((prev) => ({ ...prev, serviceSelected: updatedServiceSelected }));
      updateServiceSuggested(value);
      setFocusedServiceIndex(index);
    }
  };
  console.log(formData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (name === 'clientNumber' || name === 'clientName') {
      updateSuggested(name, value);
    }
  };

  const updateSuggested = (fieldName, inputValue) => {
    if (inputValue === '') {
      setSuggested([]);
    } else {
      const suggestions = client?.filter((client) =>
        client[fieldName]?.toLowerCase().includes(inputValue.toLowerCase()),
      );
      setSuggested(suggestions);
    }
  };

  const handleSuggestedClick = (client) => {
    const { clientNumber, clientName, gender } = client;
    setFormData((prev) => ({ ...prev, clientNumber, clientName, gender }));
    setSuggested([]);
  };

  const addRow = () => {
    const isUnfilledField = formData.serviceSelected.some(
      (item) => item.serviceProvider === '' || item.service === '',
    );
    if (isUnfilledField)
      return setShowMessage({
        message: 'Please select a staff and services',
        messageType: 'error',
      });
    setFormData((prev) => ({
      ...prev,
      serviceSelected: [...prev.serviceSelected, { service: '', serviceProvider: '' }],
    }));
  };

  const removeRow = (index) => {
    const updatedServiceSelected = [...formData.serviceSelected];
    updatedServiceSelected.splice(index, 1);
    setFormData((prev) => ({ ...prev, serviceSelected: updatedServiceSelected }));
  };

  const handleServiceSelectedInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedServiceSelected = [...formData.serviceSelected];
    updatedServiceSelected[index][name] = value;
    setFormData((prev) => ({ ...prev, serviceSelected: updatedServiceSelected }));
  };

  const handleServiceSuggestedClick = (service) => {
    const index = focusedServiceIndex;
    const updatedServiceSelected = [...formData.serviceSelected];
    updatedServiceSelected[index]['service'] = service?._id;
    updatedServiceSelected[index]['serviceName'] = service?.serviceName;
    setFormData((prev) => ({ ...prev, serviceSelected: updatedServiceSelected }));
    setServiceSuggested([]);
  };

  const handleSubmit = async () => {
    const {
      clientName,
      gender,
      clientNumber,
      timeOfAppointment,
      dateOfAppointment,
      serviceSelected,
    } = formData;

    const validation = {
      clientNameValidation: !clientName.trim(),
      clientNumberValidation: !clientNumber || clientNumber.length !== 10,
      clientGenderValidation: !gender,
      dateOfAppointmentValidation: !dateOfAppointment,
      timeOfAppointmentValidation: !timeOfAppointment,
      serviceSelectedValidation: serviceSelected.map((item) => ({
        serviceValidation: !item.service.trim(),
        serviceProviderValidation: item.serviceProvider === '',
      })),
    };

    setFormValidation((prev) => ({ ...prev, ...validation }));

    if (
      Object.values(validation).some((item) =>
        Array.isArray(item)
          ? item.some(
              (nestedItem) => nestedItem.serviceValidation || nestedItem.serviceProviderValidation,
            )
          : item,
      )
    )
      return;

    try {
      setLoader(true);
      const res = await createAppointment(
        JSON.stringify({ ...formData, branchDetails: activeBranchId }),
      );
      if (res.statusCode === 200) {
        setFormData({
          ...initialValue,
          dateOfAppointment: getCurrentDateInFormat(),
          timeOfAppointment: '',
          sourceOfAppointment: 'Walk In',
          serviceSelected: [
            {
              service: '',
              serviceName: '',
              serviceProvider: '',
            },
          ],
        });
        setFormValidation({
          ...initialFormValidation,
          serviceSelectedValidation: [
            {
              serviceValidation: false,
              serviceProviderValidation: false,
            },
          ],
        });
        setShowMessage({
          message: res?.message || 'Appointment created successfully',
          messageType: 'success',
        });
      } else {
        setShowMessage({
          message: res?.message || 'Error occurs in creating appointment',
          messageType: 'error',
        });
      }
    } catch (error) {
      setShowMessage({
        message: error?.message || 'Error occurs in creating appointment',
        messageType: 'error',
      });
    } finally {
      setLoader(false);
    }
  };

  const handleInputFocus = () => {
    setSuggested([]);
    const activeElement = document.activeElement;
    if (activeElement && activeElement.getAttribute('name')) {
      setActiveInputField(activeElement.getAttribute('name'));
    } else {
      setActiveInputField('');
    }
  };

  useEffect(() => {
    document.addEventListener('focus', handleInputFocus, true);
    return () => {
      document.removeEventListener('focus', handleInputFocus, true);
    };
  }, [activeInputField, Object.keys(client).length]);

  const getAllData = async () => {
    try {
      const res = await getBranch(activeBranchId);
      setClient(res?.data?.client || []);
      setStaff(res?.data?.staffs || []);
      setServices(res?.data?.services || []);
      setGenderWiseService(res?.data?.services || []);
    } catch (error) {}
  };
  useEffect(() => {
    if (activeBranchId) {
      getAllData();
    }

    return () => {
      setClient([]);
      setStaff([]);
      setServices([]);
    };
  }, [activeBranchId]);

  const [isFocused, setIsFocused] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  // This logic disables the mouse wheel event
  useEffect(() => {
    const handleWheel = (e) => {
      if (document.activeElement.type === 'number') {
        document.activeElement.blur();
      }
    };

    window.addEventListener('wheel', handleWheel);
    return () => {
      window.removeEventListener('wheel', handleWheel);
    };
  }, []);

  return (
    <PageContainer title="Book Appointment" description="This is add Appointment">
      <DashboardCard title="Book Appointment">
        <Box style={{ marginTop: '20px' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <TextInputs
                title={'Client Number'}
                name={'clientNumber'}
                value={formData?.clientNumber}
                handleChange={handleChange}
                type={'number'}
                min={'0'}
                placeholder={'Client Number'}
                fieldValidation={clientNumberValidation && formValidation.clientNumberValidation}
              />
              {suggested.length > 0 && activeInputField === 'clientNumber' && (
                <ClinetSuggestion data={suggested} handleSuggestedClick={handleSuggestedClick} />
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <TextInputs
                title={'Client Name'}
                name={'clientName'}
                value={formData?.clientName}
                handleChange={handleChange}
                type={'text'}
                placeholder={'Client Name'}
                fieldValidation={clientNameValidation && formValidation.clientNameValidation}
              />
              {suggested.length > 0 && activeInputField === 'clientName' && (
                <ClinetSuggestion data={suggested} handleSuggestedClick={handleSuggestedClick} />
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <RadioInputs
                title={'Gender'}
                name={'gender'}
                value={formData?.gender}
                handleChange={handleChange}
                options={[
                  { title: 'M', value: 'Male' },
                  { title: 'F', value: 'Female' },
                ]}
                fieldValidation={clientGenderValidation && formValidation.clientGenderValidation}
              />
            </Grid>
          </Grid>
        </Box>

        <Box style={{ marginTop: '20px' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <TextInputs
                title={'Date of Appointment'}
                name={'dateOfAppointment'}
                value={formData?.dateOfAppointment}
                handleChange={handleChange}
                type={'date'}
                min={new Date().toISOString().split('T')[0]}
                fieldValidation={
                  dateOfAppointmentValidation && formValidation.dateOfAppointmentValidation
                }
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {/* <TextInputs
                    title={'Time of Appointment'}
                    name={'timeOfAppointment'}
                    value={formData?.timeOfAppointment}
                    handleChange={handleChange}
                    type={'time'}
                    fieldValidation={
                      timeOfAppointmentValidation && formValidation.timeOfAppointmentValidation
                    }
                  /> */}
                  <TimeSlotSelector
                    title={'Time of Appointment'}
                    name={'timeOfAppointment'}
                    value={formData?.timeOfAppointment}
                    handleChange={handleChange}
                    fieldValidation={
                      timeOfAppointmentValidation && formValidation.timeOfAppointmentValidation
                    }
                    required={true}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <SelectInputs
                name={'sourceOfAppointment'}
                value={formData.sourceOfAppointment}
                title={'Source of Appointment'}
                required={false}
                handleChange={handleChange}
                options={sourceOptions}
                optionTitle={'title'}
                optionValue={'value'}
              />
            </Grid>
          </Grid>
        </Box>
        {formData.serviceSelected.map((item, index) => (
          <Box style={{ marginTop: '20px' }} key={index}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <label>
                  Services
                  <IoStar
                    color="red"
                    style={{ height: '6px', fontWeight: 'bold', marginBottom: '10px' }}
                  />
                </label>
                <input
                  type="text"
                  className={`form-control ${item?.serviceName === '' ? 'border border-2' : ''}`}
                  placeholder="Service"
                  name="service"
                  value={item?.serviceName}
                  onChange={(e) => handleServiceInputChange(e, index)}
                  autoComplete="off"
                  style={{
                    padding: '14px',
                    border: '1px solid #dee2e6',
                    outline: isFocused ? '2px solid #6174DD' : 'none', // Focus style
                  }}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                />
                {item?.service === '' && (
                  // <div className="text-danger">Please select service</div>
                  <div className="text-danger"></div>
                )}
                {serviceSuggested.length > 0 && focusedServiceIndex === index && (
                  <ServiceSuggestion
                    data={serviceSuggested}
                    handleServiceSuggestedClick={handleServiceSuggestedClick}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={4}>
                <SelectInputs
                  name={'serviceProvider'}
                  value={item?.serviceProvider}
                  title={'Assign to'}
                  handleChange={(e) => handleServiceSelectedInputChange(e, index)}
                  options={staff}
                  optionValue={'_id'}
                  optionTitle={'name'}
                  fieldValidation={
                    serviceSelectedValidation[index]?.serviceProviderValidation &&
                    formValidation.serviceSelectedValidation[index]?.serviceProviderValidation
                  }
                />
              </Grid>
              <Grid item xs={12} md={4} style={{ display: 'flex', alignItems: 'center' }}>
                <Grid item xs={12} md={4} mt={'10px'}>
                  <Button
                    variant="contained"
                    color="primary"
                    target="_blank"
                    sx={{
                      background: '#6174DD',
                      width: '100px',
                      fontSize: '14px',
                      fontWeight: 'bold',
                    }}
                    onClick={addRow}
                  >
                    Add
                  </Button>
                </Grid>
                {index !== 0 ? (
                  <Grid item xs={12} md={4} mt={'10px'}>
                    <Button
                      variant="contained"
                      color="primary"
                      target="_blank"
                      sx={{
                        background: '#ff0000',
                        width: '100px',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        '&:hover': {
                          background: '#ff6666', // Change the background color on hover
                        },
                      }}
                      onClick={() => removeRow(index)}
                    >
                      Remove
                    </Button>
                  </Grid>
                ) : (
                  ''
                )}
              </Grid>
            </Grid>
          </Box>
        ))}
        {loading ? (
          <ShowLoader value={loading} />
        ) : (
          <div style={{ width: '200px', margin: '3rem auto' }}>
            <Button
              variant="contained"
              fullWidth
              color="primary"
              target="_blank"
              sx={{
                background: '#6174DD',
              }}
              onClick={handleSubmit}
            >
              {/* Create Appointment */}
              Book Appointment
            </Button>
          </div>
        )}
      </DashboardCard>
    </PageContainer>
  );
};

export default AddAppointment;

// Function to get the current date in a specific format
function getCurrentDateInFormat() {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

// Function to get the current time in a specific format
function getCurrentTime() {
  const now = new Date();
  const hours = now.getHours();
  const minutes = now.getMinutes();
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  return `${formattedHours}:${formattedMinutes}`;
}
