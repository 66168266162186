import Chart from 'react-apexcharts';

const BarGraph = ({ data }) => {
  let graphObject = {
    options: {
      chart: {
        id: 'basic-bar',
      },
      xaxis: {
        categories: data?.categories,
      },
    },
    series: [
      {
        name: 'Amount',
        data: data?.series,
      },
    ],
  };

  return (
    <div>
      <Chart options={graphObject.options} series={graphObject.series} type="bar" width="650" />
    </div>
  );
};

export default BarGraph;
