import React, { useState } from 'react';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard from 'src/components/shared/DashboardCard';
import { Box, Button, Grid, MenuItem, Select } from '@mui/material';
import { ReqField } from 'src/components/CustomComponents/CustomLoader';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import { useMain } from '../hooks/useMain';
import TextInputs from 'src/components/inputs/TextInputs';
import { useEffect } from 'react';

const initialValue = {
  discount: '',
  discountType: '%',
  minimumBillAmount: '',
  maximumDiscountAmount: '',
  couponPerUser: '',
  validTill: '',
  rewardPoints: '',
};

const initialFormValidation = {
  discountValidation: false,
  minimumBillAmountValidation: false,
  maximumDiscountAmountValidation: false,
  validTillValidation: false,
};

const discount_type = ['%', 'INR'];

const AddCoupon = () => {
  const { createCoupon, activeBranchId, setShowMessage } = useMain();

  const [formData, setFormData] = useState(initialValue);
  const [formValidation, setFormValidation] = useState(initialFormValidation);
  const [loading, setLoading] = useState(false);

  const discountValidation = !formData.discount || !(Number(formData.discount) > 0);
  const minimumBillAmountValidation =
    !formData.minimumBillAmount || !(Number(formData.minimumBillAmount) > 0);
  const maximumDiscountAmountValidation =
    !formData.maximumDiscountAmount || !(Number(formData.maximumDiscountAmount) > 0);
  const validTillValidation = !formData.validTill;

  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    const { discount, minimumBillAmount, maximumDiscountAmount, validTill } = formData;

    const validation = {
      discountValidation: !discount || !(Number(discount) > 0),
      minimumBillAmountValidation: !minimumBillAmount || !(Number(minimumBillAmount) > 0),
      maximumDiscountAmountValidation:
        !maximumDiscountAmount || !(Number(maximumDiscountAmount) > 0),
      validTillValidation: !validTill,
    };

    setFormValidation((pre) => ({ ...pre, ...validation }));

    if (Object.values(validation).some((item) => item)) return;

    try {
      setLoading(true);
      const res = await createCoupon({ ...formData, branchDetails: activeBranchId });
      if (res.statusCode === 200) {
        setShowMessage({
          message: res.message || 'Coupon Successfully created',
          messageType: 'success',
        });
        setFormData(initialValue);
        setFormValidation((pre) => ({ ...initialFormValidation }));
      } else {
        setShowMessage({ message: res.message || 'Something went wrong', messageType: 'error' });
      }
    } catch (error) {
      setShowMessage({ message: error.message || 'Something went wrong', messageType: 'error' });
    } finally {
      setLoading(false);
    }
  };

  // This logic disables the mouse wheel event
  useEffect(() => {
    const handleWheel = (e) => {
      if (document.activeElement.type === 'number') {
        document.activeElement.blur();
      }
    };

    window.addEventListener('wheel', handleWheel);
    return () => {
      window.removeEventListener('wheel', handleWheel);
    };
  }, []);

  return (
    <PageContainer title="Create Coupon" description="this is add coupon">
      <DashboardCard title="Create Coupon">
        <Box style={{ marginTop: '20px' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <TextInputs
                title={'Discount'}
                name={'discount'}
                value={formData?.discount}
                handleChange={handleChange}
                type={'number'}
                min={'0'}
                // placeholder={'0'}
                fieldValidation={discountValidation && formValidation.discountValidation}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <div style={{ marginBottom: '3px', fontWeight: 'bold', color: 'black' }}>
                Discount Type <ReqField />
              </div>
              <Select
                name="discountType"
                value={formData?.discountType}
                onChange={handleChange}
                fullWidth
              >
                {discount_type.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </Box>
        <Box style={{ marginTop: '20px' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <TextInputs
                title={'Minimum Bill Amount'}
                name={'minimumBillAmount'}
                value={formData?.minimumBillAmount}
                handleChange={handleChange}
                type={'number'}
                min={'0'}
                // placeholder={'0'}
                fieldValidation={
                  minimumBillAmountValidation && formValidation.minimumBillAmountValidation
                }
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextInputs
                title={'Max Discount in Rs.'}
                name={'maximumDiscountAmount'}
                value={formData?.maximumDiscountAmount}
                handleChange={handleChange}
                type={'number'}
                min={'0'}
                // placeholder={'0'}
                fieldValidation={
                  maximumDiscountAmountValidation && formValidation.maximumDiscountAmountValidation
                }
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextInputs
                title={'Coupon Per User'}
                name={'couponPerUser'}
                value={formData?.couponPerUser}
                handleChange={handleChange}
                type={'number'}
                min={'1'}
                // placeholder={'1'}
                required={false}
              />
            </Grid>
          </Grid>
        </Box>

        <Box style={{ marginTop: '20px' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <TextInputs
                title={'Valid Till'}
                name={'validTill'}
                value={formData?.validTill}
                handleChange={handleChange}
                type={'date'}
                min={new Date().toISOString().split('T')[0]}
                fieldValidation={validTillValidation && formValidation.validTillValidation}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextInputs
                required={false}
                title={'Rewards Points'}
                name={'rewardPoints'}
                value={formData?.rewardPoints}
                handleChange={handleChange}
                type={'number'}
                min={'0'}
                // placeholder={'0'}
              />
            </Grid>
          </Grid>
        </Box>

        <div style={{ width: '200px', margin: '3rem auto' }}>
          {loading ? (
            <ShowLoader />
          ) : (
            <Button
              variant="contained"
              fullWidth
              color="primary"
              target="_blank"
              sx={{ background: '#6174DD' }}
              onClick={handleSubmit}
            >
              Create Coupon
            </Button>
          )}
        </div>
      </DashboardCard>
    </PageContainer>
  );
};

export default AddCoupon;
