import React, { useEffect, useState } from 'react'
import PageContainer from 'src/components/container/PageContainer'
import DashboardCard from 'src/components/shared/DashboardCard'
import { Box, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import Subscription from './Subscription'
import { useMain } from '../hooks/useMain'
import ShowLoader from 'src/components/CustomComponents/ShowLoader'
import DashboardCard2 from 'src/components/shared/DashboardCard2'
import { Search } from '@mui/icons-material'

const AllSubscription = () => {

    const {parantBranchData } = useMain();
    return (
        <PageContainer title="Parant Subscription" description="this contains Parant data">
            <DashboardCard2 title="Parant Subscription">
                <Grid container spacing={2} sx={{ mt: 1, mb: 3 }} alignItems="center">
                    <Grid item xs={6}>
                        <FormControl variant="standard" style={{ minWidth: 120 }}>
                            <InputLabel>Show per page</InputLabel>
                            <Select value={10} onChange={() => { }}>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={20}>20</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} container justifyContent="flex-end">
                        <TextField
                            label="Search"
                            variant="standard"
                            fullWidth
                            style={{
                                maxWidth:'300px'
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
                <Box>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={12}>
                            <Subscription data={parantBranchData}  />
                        </Grid>
                    </Grid>
                </Box>
  
            </DashboardCard2>
        </PageContainer>
    )
}

export default AllSubscription
