import { baseUrl, headers, jwtHeaders } from '../common/data';

export const fetchAllBillings = async (branchId, queryParams) => {
  const response = await fetch(
    `${baseUrl}/api/billing/getbranchwisebill/${branchId}${queryParams ? queryParams : ''}`,
    {
      method: 'GET',
      headers: jwtHeaders(),
      redirect: 'follow',
    },
  );
  const resData = await response?.json();

  return resData;
};

export const fetchBillAnalytics = async (branchId, queryParams) => {
  const response = await fetch(
    `${baseUrl}/api/billing/combinedbillapi/${branchId}${queryParams ? queryParams : ''}`,
    {
      method: 'GET',
      headers: jwtHeaders(),
      redirect: 'follow',
    },
  );
  const resData = await response?.json();
  return resData;
};

export const fetchBillById = async (billId, branchId) => {
  const response = await fetch(
    `${baseUrl}/api/billing/getparticularbill/${billId}${branchId ? branchId : ''}`,
    {
      method: 'GET',
      headers: jwtHeaders(),
    },
  );
  const resData = await response?.json();
  return resData;
};

export const fetchTotalSales = async (branchId) => {
  const response = await fetch(`${baseUrl}/api/billing/gettotalsalesbranchwise/${branchId}`, {
    method: 'GET',
    headers: jwtHeaders(),
    redirect: 'follow',
  });
  const resData = await response?.json();

  return resData;
};

export const createBilling = async (data) => {
  const response = await fetch(`${baseUrl}/api/billing/create`, {
    method: 'POST',
    body: data,
    headers: jwtHeaders(),
  });
  const resData = await response?.json();

  return resData;
};

// export const deleteBilling = async (id, branchId) => {
//   const response = await fetch(
//     `${baseUrl}/api/billing/deletebill/${id}${branchId ? branchId : ''}`,
//     {
//       method: 'DELETE',
//       headers: jwtHeaders(),
//     },
//   );
//   const resData = await response?.json();

//   return resData;
// };

// new code 
export const deleteBilling = async (id, branchId) => {
  const response = await fetch(
    `${baseUrl}/api/billing/deletebill?branchId=${branchId}`,
    {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        ...jwtHeaders(),
      },
      body: JSON.stringify({
        ids: [id],
        status: false,
      }),
    },
  );

  const resData = await response.json();
  return resData;
};


export const updateBill = async (billId, branchId, data) => {
  const response = await fetch(
    `${baseUrl}/api/billing/updatebill/${billId}${branchId ? branchId : ''}`,
    {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: jwtHeaders(),
    },
  );
  const resData = await response?.json();

  return resData;
};

export const clearRemainingAmount = async (billId, branchId, data) => {
  const response = await fetch(
    `${baseUrl}/api/billing/updateprevious/${billId}${branchId ? branchId : ''}`,
    {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: jwtHeaders(),
    },
  );
  const resData = await response?.json();

  return resData;
};

export const fetchPackageBills = async (branchId, queryParams) => {
  const response = await fetch(
    `${baseUrl}/api/billing/packagebills/${branchId}${queryParams ? queryParams : ''}`, {
    method: 'GET',
    headers: jwtHeaders(),
  });

  const resData = await response.json();

  return resData;
};

//new code 
export const deletePackagebill = async(id,branchId) =>{
  const response = await fetch(
    `${baseUrl}/api/billing/deletepackagebills?branchId=${branchId}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...jwtHeaders(),
      },
      body: JSON.stringify({
        ids: [id],
        status: false,
      }),
    },
  );
  const resData = await response.json();
  return resData;
};

export const getGstData = async (activeBranchId) => {
  const response = await fetch(
    `${baseUrl}/api/billing/gstbilldetails/${activeBranchId}`, {
    method: 'GET',
    headers: jwtHeaders(),
  });

  const resData = await response.json();

  return resData;
};


// **********************************DRAFT BILL APIS************************************

export const fetchDraftBill = async (branchId) => {
  try {
    const response = await fetch(`${baseUrl}/api/billing/alldraftbill?branchId=${branchId}`, {
      method: 'GET',
      headers: jwtHeaders(),
      redirect: 'follow',
    });

    if (!response.ok) {
      throw new Error(`Error fetching draft bills: ${response.statusText}`);
    }

    const resData = await response.json();
    console.log('Draft Bill API Response:', resData); 
    return resData;
  } catch (error) {
    console.error('Error in fetchDraftBill:', error); 
    return { status: 'error', message: error.message };
  }
};

export const createDraftBilling = async (data) => {
  const response = await fetch(`${baseUrl}/api/billing/createdraftbill`, {
    method: 'POST',
    body: data,
    headers: jwtHeaders(),
  });
  const resData = await response?.json();

  return resData;
};

export const fetchSingleBill = async (queryParams) => {
  try {
    const response = await fetch(
      `${baseUrl}/api/billing/singledraftbill${queryParams ? queryParams : ''}`,
      {
        method: 'GET',
        headers: jwtHeaders(),
      }
    );
    
    const resData = await response.json();
    return resData;
    
  } catch (error) {
    console.error('Error fetching single bill:', error);
    throw error;
  }
};

