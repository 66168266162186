import React, { useEffect, useState } from 'react';
import PageContainer from 'src/components/container/PageContainer';
import FieldData from './FieldData';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
} from '@mui/material';
import { useMain } from '../hooks/useMain';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import DashboardCard2 from 'src/components/shared/DashboardCard2';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import * as XLSX from 'xlsx';
import SearchImg from '../../assets/search.svg';
import Book1service from '../../assets/excel/Book1service.xlsx';

const filterServiceFor = [
  { value: 'Female', label: 'Female' },
  { value: 'Male', label: 'Male' },
  { value: 'Both', label: 'Both' },
];

const AllServices = () => {
  const [data, setData] = useState([]);
  const [checkedServices, setCheckedServices] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openDialogue, setOpenDialogue] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadedData, setUploadedData] = useState(null);
  const [serviceFor, setServiceFor] = useState('');
  const [search, setSearch] = useState('');
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [clear, setClear] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const { fetchAllServices, activeBranchId, createManyService, setShowMessage, deleteManyService } =
    useMain();

  useEffect(() => {
    applyFilters(data, limit, page, search)
  }, [data, limit, page, search])

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      // Read the file using XLSX
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });

        // Assuming the first sheet contains the data
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];

        // Convert the sheet data to JSON
        const jsonData = XLSX.utils.sheet_to_json(firstSheet);

        // Set the converted data in state
        setUploadedData(jsonData);
      };
      reader.readAsArrayBuffer(selectedFile);
    }
  };

  const clearDataHandler = () => {
    setLimit(10);
    setPage(1);
    setSearch('');
    setServiceFor('');
    if (clear) {
      getData('clear');
      setClear(false);
    }
  };

  const onApplyHandler = () => {
    if (search) {
      getData();
      setClear(true);
    }
  };

  const getData = async (key) => {
    let queryParams = `?page=${page}&limit=${limit}${serviceFor ? `&serviceFor=${serviceFor}` : ''
      }${search ? `&search=${search}` : ''}`;
    if (key) queryParams = `?page=${page}&limit=${limit}&sortBy=createdAt`;

    try {
      setShowLoader(true);
      const res = await fetchAllServices(activeBranchId);
      if (res.statusCode === 200) {
        setData(res?.data || []);
      } else {
      }
    } catch (error) {
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    if (activeBranchId) {
      setClear(true);
      getData();
    }
    return () => {
      setData([]);
    };
  }, [activeBranchId]);

  useEffect(() => {
    applyFilters();
  }, [limit, page, search, data, serviceFor]);

  const applyFilters = () => {
    let filteredData = data?.filter((item) => {
      let isNameMatch = true;
      let isService = true;

      if (serviceFor) {
        isService = item?.serviceFor === serviceFor
      }

      if (search) {
        isNameMatch = item?.serviceName?.toLowerCase().includes(search.toLowerCase());
      }

      return isNameMatch && isService;
    });
    // console.log(serviceFor);
    // Applying pagination
    const startIndex = (page - 1) * limit;
    const endIndex = startIndex + limit;
    filteredData = filteredData.slice(startIndex, endIndex);
    setFilteredData(filteredData);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setPage(1);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (uploadedData) {
      setShowLoader(true);
      try {
        const res = await createManyService(activeBranchId, { bulkData: uploadedData });
        if (res?.statusCode === 200) {
          setShowMessage({
            message: res.message || 'Service was successfully uploaded',
            messageType: 'success',
          });
          getData();
        } else {
          setShowMessage({
            message: res.message || 'Service failed to upload',
            messageType: 'error',
          });
        }
      } catch (error) {
        setShowMessage({ message: error.message || 'Something went wrong', messageType: 'error' });
      } finally {
        setShowLoader(false);
        closeModal();
      }
    }
  };

  const downloadExcelTemplate = () => {
    const templateFileName = Book1service;

    // Create an anchor element to trigger the download
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = templateFileName;
    a.download = 'service_template.xlsx';

    // Trigger the click event to download the file
    document.body.appendChild(a);
    a.click();

    // Remove the anchor element
    document.body.removeChild(a);
  };

  const handleBulkDelete = async () => {
    setOpenDialogue(true);
  };

  const handleCloseDialogue = () => {
    setOpenDialogue(false);
  };

  const submitBulkDelete = async () => {
    try {
      setDeleteLoading(true);
      const res = await deleteManyService({ ids: checkedServices }, `?branchId=${activeBranchId}`);
      if (res.statusCode === 200) {
        setShowMessage({
          message: res.message || 'Successfully deleted service',
          messageType: 'success',
        });
        setCheckedServices([]);
        getData();
      } else {
        setShowMessage({
          message: res.message || 'Error occurre deleting service',
          messageType: 'error',
        });
      }
    } catch (error) {
      setShowMessage({ message: error.message || 'Something Went Worng', messageType: 'error' });
    } finally {
      setDeleteLoading(false);
      handleCloseDialogue();
    }
  };

    return (
      <PageContainer title="Manage Services" description="this contains all products">
        <DashboardCard2 title="Manage Services">
          <Grid container style={{ gap: '10px' }}>
            <Grid container justifyContent="space-between">
              <Grid item xs={2}>
                <InputLabel>Show per page</InputLabel>
                <Select
                  style={{
                    borderRadius: '7px',
                    border: '0.5px solid #6174DD',
                    padding: '4px 12px',
                    background: '#FFF',
                    height: '40px',
                    // width:'100px'
                  }}
                  value={limit}
                  onChange={handleLimitChange}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={1.3} marginLeft={25}>
                <div>
                  <InputLabel>Gender</InputLabel>
                  <Select
                    style={{
                      borderRadius: '7px',
                      border: '0.5px solid #6174DD',
                      padding: '4px 12px',
                      background: '#FFF',
                      height: '40px',
                      width: '100%',
                    }}
                    value={serviceFor}
                    onChange={(e) => setServiceFor(e.target.value)}
                  >
                    {filterServiceFor.map((item, index) => (
                      <MenuItem key={index} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </Grid>
              {/* <Grid item xs={2}> */}
              <Grid item xs={1.3} marginLeft={10}>
                <div>
                  <InputLabel>Search</InputLabel>
                  <div className="search-input">
                    <img src={SearchImg} alt="" />
                    <input
                      type="text"
                      placeholder=""
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={2} display="flex" gap="20px" alignItems="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  target="_blank"
                  sx={{
                    height: '40px',
                    // width: '100%',
                    width: '60%',
                    marginLeft: '5rem',
                    background: '#ff4d4d',
                    '&:hover': {
                      background: '#ff6666',
                    },
                  }}
                  onClick={clearDataHandler}
                >
                  Clear
                </Button>
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end">
              <Grid item justifyContent="flex-end">
                {checkedServices.length ? (
                  <Button
                    variant="contained"
                    color="primary"
                    target="_blank"
                    sx={{
                      background: '#ff4d4d',
                      '&:hover': {
                        background: '#ff6666',
                      },
                    }}
                    onClick={handleBulkDelete}
                  >
                    Delete
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    target="_blank"
                    sx={{
                      background: '#6174DD',
                    }}
                    onClick={openModal}
                  >
                    Bulk Services Upload
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
          {/* Modal Dialog */}
          <Dialog open={isModalOpen} onClose={closeModal}>
            <DialogTitle>Create Many Services</DialogTitle>
            <form onSubmit={handleFormSubmit}>
              <Box p={2}>
                <input
                  type="file"
                  accept=".xlsx, .xls" // Set accepted file types
                  onChange={handleFileChange}
                />
              </Box>
              {showLoader && <ShowLoader value={showLoader} />}
              <Box p={2}>
                <Button type="submit" variant="contained" color="primary">
                  Submit
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{ ml: 2 }}
                  onClick={downloadExcelTemplate}
                >
                  <ArrowCircleDownIcon sx={{ margin: '0px 2px 0px 0px' }} />
                  Sample Download
                </Button>
              </Box>
            </form>
          </Dialog>

          {/* Delete Model */}
          <Dialog open={openDialogue} onClose={handleCloseDialogue}>
            <DialogTitle>Delete bill</DialogTitle>
            <DialogContent>
              <DialogContentText>Are you sure you want to delete this Service?</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialogue}>Cancel</Button>
              <Button onClick={submitBulkDelete} autoFocus>
                Delete
              </Button>
            </DialogActions>
          </Dialog>
          {showLoader ? (
            <ShowLoader />
          ) : filteredData && filteredData.length > 0 ? (
            <>
              <Box>
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={12}>
                    <FieldData
                      data={filteredData}
                      setData={setData}
                      getServices={getData}
                      setCheckedServices={setCheckedServices}
                      checkedServices={checkedServices}
                    />
                  </Grid>
                </Grid>
              </Box>
            </>
          ) : (
            'No data found'
          )}
          <Box display={'flex'} justifyContent={'flex-end'}>
            <Pagination
              count={filteredData && filteredData?.length < limit ? page : page + 1}
              page={page}
              color="primary"
              onChange={(e, page) => setPage(page)}
            />
          </Box>
        </DashboardCard2>
      </PageContainer>
    );
  };

  export default AllServices;
