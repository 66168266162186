import { baseUrl, headers, jwtHeaders } from "../common/data";




export const fetchAllSubscription = async () => {
    const response = await fetch(`${baseUrl}/api/subscription/getallsubscription`, {
        method: "GET",
        headers: jwtHeaders(),
        redirect: "follow",
    });
    const resData = await response?.json();
    
    return resData;
};