import { CheckCircleOutline } from '@mui/icons-material'
import { Button, Modal } from '@mui/material'
import { IconDiscountCheck } from '@tabler/icons'
import React from 'react'

const SuccessModal = ({ open, handleClose, text }) => {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <div
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    padding: '20px',
                    width: 350,
                    background: 'white',
                    borderRadius: '10px',
                    boxShadow: 24,
                    p: 2,
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '20px'
                }}
            >
                <IconDiscountCheck size={100} color='#6174DD' />
                <div style={{ fontWeight: '800', fontSize: '30px' }}>Success</div>
                <div style={{ color: '#737373', fontSize: '16px', fontWeight: '500' }}>
                    {text}
                </div>
                <Button
                    variant="contained"
                    fullWidth
                    color="primary"
                    target="_blank"
                    sx={{
                        background: '#6174DD',
                        maxWidth: '150px'
                    }}
                    onClick={handleClose}
                >
                    Done
                </Button>
            </div>
        </Modal>
    )
}

export default SuccessModal
