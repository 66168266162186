import React, { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import MainState from './context/MainState';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <StrictMode>
    <MainState>
      <Suspense>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Suspense>
    </MainState>
  </StrictMode>,
);
