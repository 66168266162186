import logo2 from 'src/assets/images/logos/logo.jpeg';
// import logo2 from 'src/assets/images/logos/YourSalon_Logo-03.png';
import { styled, Box } from '@mui/system'; // Use '@mui/system' instead of '@mui/material'

const StyledLinkBox = styled(Box)(() => ({
  height: '80px',
  width: '100px',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'center',
  textDecoration: 'none',
  marginLeft:'2.5rem'
}));

const Logo = () => {
  return (
    <StyledLinkBox>
      <img src={logo2} alt="logo" height={60} width={60} style={{ borderRadius: '8px' }} />
    </StyledLinkBox>
  );
};

export default Logo;
