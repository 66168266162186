import React, { useEffect, useState } from 'react';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard from 'src/components/shared/DashboardCard';
import { Button, Grid, Snackbar } from '@mui/material';
import { Box } from '@mui/system';
import { ShowSuccess } from 'src/components/CustomComponents/ShowMessages';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import { useMain } from '../hooks/useMain';
import TextInputs from 'src/components/inputs/TextInputs';
import SelectInputs from 'src/components/inputs/SelectInputs';

const initialValue = {
    appointmentId: '',
    rating: '',
    description: ''
}

const AddFeedback = () => {
    const [formData, setFormData] = useState(initialValue);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [showLoader, setShowLoader] = useState(false);
    const [appointments, setAppointments] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value })
    }

    const { createFeedback, fetchAllAppointments, activeBranchId } = useMain();

    const getData = async () => {
        const res = await fetchAllAppointments(activeBranchId);
        setAppointments(res?.data);
    }

    const handleSubmit = async () => {
        const { appointmentId, rating, description } = formData;
        if (description.trim() === '') return setErrorMessage('Description is Required');
        if (rating === '') return setErrorMessage('Rating is Required');
        if (appointmentId === '') return setErrorMessage('Appointment is Required');
        if (!activeBranchId) return setErrorMessage('branch is required');
        setShowLoader(true);

        const res = await createFeedback(JSON.stringify({ ...formData, branchDetails: activeBranchId }));

        if (res?.statusCode === 200) {
            setSuccessMessage('Feedback Successfully Created');
            setTimeout(() => {
                setSuccessMessage('');
            }, 7000);
            setFormData(initialValue);
            setErrorMessage('');
            setShowLoader(false);
        } else {
            setErrorMessage(res?.message);
            setTimeout(() => {
                setErrorMessage('');
            }, 7000);
            setFormData(initialValue);
            setShowLoader(false);
        }
    };

    useEffect(() => {
        if (activeBranchId)
            getData();

        return () => { }
    }, [activeBranchId]);

    return (
        <PageContainer title="Add Feedback" description="this is add Feedback">
            <DashboardCard title="Add Feedback">
                <Snackbar
                    open={errorMessage === '' ? false : true}
                    autoHideDuration={6000}
                    onClose={() => {
                        setErrorMessage('');
                    }}
                    severity="error"
                    message={errorMessage}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                />

                <Box style={{ marginTop: '20px' }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                            <SelectInputs
                                title={'Select Appointment'}
                                options={appointments}
                                name={'appointmentId'}
                                value={formData?.appointmentId}
                                handleChange={handleChange}
                                optionTitle={'clientName'}
                                optionValue={'_id'}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextInputs
                                title={'Rating(out of 5)'}
                                name={'rating'}
                                value={formData?.rating}
                                handleChange={handleChange}
                                type={'number'}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextInputs
                                title={'Description'}
                                name={'description'}
                                value={formData?.description}
                                handleChange={handleChange}
                                type={'text'}
                            />
                        </Grid>
                    </Grid>
                </Box>

                {showLoader && <ShowLoader value={showLoader} />}
                {successMessage != '' && <ShowSuccess message={successMessage} />}

                <div style={{ width: '200px', margin: '3rem auto' }}>
                    <Button
                        variant="contained"
                        fullWidth
                        price="primary"
                        target="_blank"
                        sx={{
                            background: '#6174DD'
                        }}
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </div>
            </DashboardCard>
        </PageContainer>
    )
}

export default AddFeedback