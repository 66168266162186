import React, { useEffect, useState } from 'react'
import PageContainer from 'src/components/container/PageContainer'
import FieldData from './FieldData'
import { Box, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { useMain } from '../hooks/useMain'
import ShowLoader from 'src/components/CustomComponents/ShowLoader'
import DashboardCard2 from 'src/components/shared/DashboardCard2'
import { Search } from '@mui/icons-material'


const AllCategories = () => {
    const [data, setData] = useState([]);
    const [showLoader, setShowLoader] = useState(false);

    const { fetchAllCategories, activeBranchId } = useMain();

    const getData = async () => {
        setShowLoader(true);
        const res = await fetchAllCategories(activeBranchId);
        setData(res?.data);
        setShowLoader(false);
    }

    useEffect(() => {
        if (activeBranchId)
            getData();

        return () => { }
    }, [activeBranchId]);

    return (
        <PageContainer title="Category" description="this contains all products">
            <DashboardCard2 title="All Categories">
                <Grid container spacing={2} sx={{ mt: 1, mb: 3 }} alignItems="center">
                    <Grid item xs={6}>
                        <FormControl variant="standard" style={{ minWidth: 120 }}>
                            <InputLabel>Show per page</InputLabel>
                            <Select value={10} onChange={() => { }}>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={20}>20</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} container justifyContent="flex-end">
                        <TextField
                            label="Search"
                            variant="standard"
                            fullWidth
                            style={{
                                maxWidth: '300px'
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
                <Box>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={12}>
                            <FieldData data={data} />
                        </Grid>
                    </Grid>
                </Box>
                {showLoader && <ShowLoader value={showLoader} />}
            </DashboardCard2>
        </PageContainer>
    )
}

export default AllCategories