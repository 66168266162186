const MessageData = (clientData, branchData) => {
  let text;
  const membership = clientData?.membershipDetails;
  if (membership?.membershipType === 'serviceType') {
    text = `Hi%20${clientData?.clientName},%20Thank%20you%20for%20choosing%20${
      branchData?.branchName
    }.%0A%0AMembership%20details%20is:%20%0A
          validity:%20${membership?.endDate}%0A
          Remaining%20Service%20and%20count: %0A
          ${membership?.services
            .map((service) => `service: ${service.serviceName}, remaining count: ${service.count}`)
            .join('%0A')}
          %0A%0A`;
  } else {
    text = `Hi%20${clientData?.clientName},%20Thank%20you%20for%20choosing%20${
      branchData?.branchName || 'your branch'
    }.%0A%0AMembership%20details%20is:%20%0A
          Validity:%20${membership?.endDate}%0A
          and%20Remaining%20Count%20is:%20${membership?.discount?.availableCount}%0A
          Discount%20on%20Product%20:%20${membership?.discount?.discountOnProduct}%20${
      membership?.discount?.productDiscountType
    }%0A
          Discount%20on%20Service%20:%20${membership?.discount?.discountOnService}%20${
      membership?.discount?.serviceDiscountType
    }%0A
          Discount%20on%20Package%20:%20${membership?.discount?.discountOnPackages}%20${
      membership?.discount?.packageDiscountType
    }%0A
          %0A%0A`;
  }

  const countryCode = '91';
  const url = `https://api.whatsapp.com/send?phone=${countryCode}${clientData?.clientNumber}&text=${text}`;
  return url;
};

export { MessageData };
