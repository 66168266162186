// import React, { useEffect, useState } from 'react';
// import PageContainer from 'src/components/container/PageContainer';
// import { Box, Button, Grid, InputLabel, MenuItem, Pagination, Select } from '@mui/material';
// import { useNavigate } from 'react-router';


// import { useMain } from '../hooks/useMain';
// import ShowLoader from 'src/components/CustomComponents/ShowLoader';
// import SearchImg from '../../assets/search.svg';
// import DashboardCard2 from 'src/components/shared/DashboardCard2';
// import FieldData from './FieldData';

// const filterSourceOfEnquiry = [
//   { label: 'Twitter', value: 'Twitter' },
//   { label: 'Instagram', value: 'Instagram' },
//   { label: 'Walking', value: 'Walking' },
//   { label: 'Facebook', value: 'Facebook' },
//   { label: 'Website', value: 'Website' },
// ];

// const filterLeadStatus = [
//   { label: 'Pending', value: 'Pending' },
//   { label: 'Converted', value: 'Converted' },
//   { label: 'Closed', value: 'Closed' },
// ];

// const filterEnquiryType = [
//   { label: 'Hot', value: 'Hot' },
//   { label: 'Cold', value: 'Cold' },
//   { label: 'Warm', value: 'Warm' },
// ];

// const filterGenderWise = [
//   { value: 'Female', label: 'Female' },
//   { value: 'Male', label: 'Male' },
// ];

// const AllEnquiry = () => {
//   const { fetchAllEquiry, activeBranchId } = useMain();

//   const [data, setData] = useState([]);
//   const [checkedClientEnquiry, setCheckedClientEnquiry] = useState([]);
//   const [showLoader, setShowLoader] = useState(true);
//   const [search, setSearch] = useState('');
//   const [genderBy, setGenderBy] = useState('');
//   const [sourceOfEnquiry, setSourceOfEnquiry] = useState('');
//   const [leadStatus, setLeadStatus] = useState('');
//   const [enquiryType, setEnquiryType] = useState('');
//   const [clear, setClear] = useState(false);
//   const [limit, setLimit] = useState(10);
//   const [page, setPage] = useState(1);

//   const [filterData, setFilterData] = useState(null);
//   console.log(filterData);

// const navigate=useNavigate()


//   const clearDataHandler = () => {
//     setLimit(10);
//     setPage(1);
//     setSearch('');
//     setSourceOfEnquiry('');
//     setLeadStatus('');
//     setEnquiryType('');
//     setGenderBy('');
//     if (clear) {
//       getData('clear');
//       setClear(false);
//     }
//   };

//   const onApplyHandler = () => {
//     if (search) {
//       getData();
//       setClear(true);
//     }
//   };

//   const getData = async (key) => {
//     // let queryParams = `?page=${page}&limit=${limit}${
//     //   sourceOfEnquiry ? `&sourceOfEnquiry=${sourceOfEnquiry}` : ''
//     // }${enquiryType ? `&enquiryType=${enquiryType}` : ''}${
//     //   leadStatus ? `&leadStatus=${leadStatus}` : ''
//     // }${genderBy ? `&gender=${genderBy}` : ''}${search ? `&search=${search}` : ''}`;

//     // if (key) queryParams = `?page=${page}&limit=${limit}`;
//     try {
//       setShowLoader(true);
//       const res = await fetchAllEquiry(activeBranchId);
//       if (res.statusCode === 200) {
//         setData(res?.data || []);
//       } else {
//       }
//     } catch (error) {
//     } finally {
//       setShowLoader(false);
//     }
//   };

//   useEffect(() => {
//     if (activeBranchId) {
//       setClear(true);
//       getData();
//     }
//     return () => {
//       setData([]);
//     };
//   }, [activeBranchId]);

//   useEffect(() => {
//     applyFilters();
//   }, [limit, page, sourceOfEnquiry, enquiryType, leadStatus, genderBy, data, search]);

//   const applyFilters = () => {
//     let filteredData = data.filter((item) => {
//       let isGenderMatch = true;
//       let isSourceMatch = true;
//       let isTypeMatch = true;
//       let isStatusMatch = true;
//       let isNameMatch = true;

//       if (genderBy) {
//         isGenderMatch = item.gender === genderBy;
//       }

//       if (sourceOfEnquiry) {
//         isSourceMatch = item.sourceOfEnquiry === sourceOfEnquiry;
//       }

//       if (enquiryType) {
//         isTypeMatch = item.enquiryType === enquiryType;
//       }

//       if (leadStatus) {
//         isStatusMatch = item.leadStatus === leadStatus;
//       }

//       if (search) {
//         isNameMatch =
//           item.clientName.toLowerCase().includes(search.toLowerCase()) ||
//           item.clientNumber.includes(search);
//       }

//       return isGenderMatch && isSourceMatch && isTypeMatch && isStatusMatch && isNameMatch;
//     });

//     // Applying pagination
//     const startIndex = (page - 1) * limit;
//     const endIndex = startIndex + limit;
//     filteredData = filteredData.slice(startIndex, endIndex);
//     setFilterData(filteredData);
//   };

//   const handleLimitChange = (e) => {
//     setLimit(e.target.value);
//     setPage(1);
//   };

//   return (
//     <PageContainer title="All Enquiries" description="this contains all Enquiries">
//       <DashboardCard2 title="All Enquiries">
//         <Grid container style={{ gap: '10px' }}>
//           <Grid container justifyContent="space-between">
//             <Grid item xs={1.5}>
//               <InputLabel>Show per page</InputLabel>
//               <Select
//                 style={{
//                   borderRadius: '7px',
//                   border: '0.5px solid #6174DD',
//                   padding: '4px 12px',
//                   background: '#FFF',
//                   height: '40px',
//                   // width:'100px'
//                 }}
//                 value={limit}
//                 onChange={handleLimitChange}
//               >
//                 <MenuItem value={10}>10</MenuItem>
//                 <MenuItem value={20}>20</MenuItem>
//                 <MenuItem value={50}>50</MenuItem>
//               </Select>
//             </Grid>
//             <Grid item xs={1.5}>
//               <InputLabel>Enquiry Type</InputLabel>
//               <Select
//                 style={{
//                   borderRadius: '7px',
//                   border: '0.5px solid #6174DD',
//                   padding: '4px 12px',
//                   background: '#FFF',
//                   height: '40px',
//                   width: '100%',
//                 }}
//                 value={enquiryType}
//                 onChange={(e) => setEnquiryType(e.target.value)}
//               >
//                 {filterEnquiryType.map((item, index) => (
//                   <MenuItem key={index} value={item.value}>
//                     {item.label}
//                   </MenuItem>
//                 ))}
//               </Select>
//             </Grid>
//             <Grid item xs={1.5}>
//               <InputLabel>Lead Status</InputLabel>
//               <Select
//                 style={{
//                   borderRadius: '7px',
//                   border: '0.5px solid #6174DD',
//                   padding: '4px 12px',
//                   background: '#FFF',
//                   height: '40px',
//                   width: '100%',
//                 }}
//                 value={leadStatus}
//                 onChange={(e) => setLeadStatus(e.target.value)}
//               >
//                 {filterLeadStatus.map((item, index) => (
//                   <MenuItem key={index} value={item.value}>
//                     {item.label}
//                   </MenuItem>
//                 ))}
//               </Select>
//             </Grid>
//             <Grid item xs={1.5}>
//               <InputLabel>Source Of Enquiry</InputLabel>
//               <Select
//                 style={{
//                   borderRadius: '7px',
//                   border: '0.5px solid #6174DD',
//                   padding: '4px 12px',
//                   background: '#FFF',
//                   height: '40px',
//                   width: '100%',
//                 }}
//                 value={sourceOfEnquiry}
//                 onChange={(e) => setSourceOfEnquiry(e.target.value)}
//               >
//                 {filterSourceOfEnquiry.map((item, index) => (
//                   <MenuItem key={index} value={item.value}>
//                     {item.label}
//                   </MenuItem>
//                 ))}
//               </Select>
//             </Grid>
//             <Grid item xs={1.5}>
//               <div>
//                 <InputLabel>Gender</InputLabel>
//                 <Select
//                   style={{
//                     borderRadius: '7px',
//                     border: '0.5px solid #6174DD',
//                     padding: '4px 12px',
//                     background: '#FFF',
//                     height: '40px',
//                     width: '100%',
//                   }}
//                   value={genderBy}
//                   onChange={(e) => setGenderBy(e.target.value)}
//                 >
//                   {filterGenderWise.map((item, index) => (
//                     <MenuItem key={index} value={item.value}>
//                       {item.label}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </div>
//             </Grid>
//             <Grid item xs={1.5}>
//               <div>
//                 <InputLabel>Search</InputLabel>
//                 <div className="search-input">
//                   <img src={SearchImg} alt="" />
//                   <input
//                     type="text"
//                     placeholder=""
//                     value={search}
//                     onChange={(e) => setSearch(e.target.value)}
//                   />
//                 </div>
//               </div>
//             </Grid>
//             <Grid item xs={1.5} display="flex" gap="10px" alignItems="flex-end">
//               <Button
//                 variant="contained"
//                 color="primary"
//                 target="_blank"
//                 sx={{
//                   height: '40px',
//                   width: '100%',
//                   background: '#ff4d4d',
//                   '&:hover': {
//                     background: '#ff6666',
//                   },
//                 }}
//                 onClick={clearDataHandler}
//               >
//                 Clear
//               </Button>
//             </Grid>
//              <Grid item xs={2}>
//               <Button
//                 onClick={()=>{navigate('/clientReview')}}
//                 variant="contained"
//                 target="_blank"
//                 color="primary"
//                 sx={{
//                   background: '#6174DD',
//                 }}
//                 style={{marginTop:'2rem'}}
//               >
//                 Client Reviews
//               </Button>
//             </Grid>
//           </Grid>
//         </Grid>
//         {showLoader ? (
//           <ShowLoader />
//         ) : filterData && filterData.length > 0 ? (
//           <>
//             <Box>
//               <Grid container spacing={3}>
//                 <Grid item xs={12} lg={12}>
//                   <FieldData
//                     data={filterData}
//                     setData={setData}
//                     getData={getData}
//                     checkedClientEnquiry={checkedClientEnquiry}
//                     setCheckedClientEnquiry={setCheckedClientEnquiry}
//                   />
//                 </Grid>
//               </Grid>
//             </Box>
//           </>
//         ) : (
//           'No data found'
//         )}
//         <Box display={'flex'} justifyContent={'flex-end'}>
//           <Pagination
//             count={filterData && filterData?.length < limit ? page : page + 1}
//             page={page}
//             color="primary"
//             onChange={(e, page) => setPage(page)}
//           />
//         </Box>
//       </DashboardCard2>
//     </PageContainer>
//   );
// };

// export default AllEnquiry;

//new filter code
// import React, { useEffect, useState } from 'react';
// import PageContainer from 'src/components/container/PageContainer';
// import { Box, Button, Grid, InputLabel, MenuItem, Pagination, Select } from '@mui/material';
// import { useNavigate } from 'react-router';


// import { useMain } from '../hooks/useMain';
// import ShowLoader from 'src/components/CustomComponents/ShowLoader';
// import SearchImg from '../../assets/search.svg';
// import DashboardCard2 from 'src/components/shared/DashboardCard2';
// import FieldData from './FieldData';
// import BigFilter from 'src/components/inputs/BigFilter';
// // import EnqFil from './EnqFil';

// const filterSourceOfEnquiry = [
//   { label: 'Twitter', value: 'Twitter' },
//   { label: 'Instagram', value: 'Instagram' },
//   { label: 'Walking', value: 'Walking' },
//   { label: 'Facebook', value: 'Facebook' },
//   { label: 'Website', value: 'Website' },
// ];

// const filterLeadStatus = [
//   { label: 'Pending', value: 'Pending' },
//   { label: 'Converted', value: 'Converted' },
//   { label: 'Closed', value: 'Closed' },
// ];

// const filterEnquiryType = [
//   { label: 'Hot', value: 'Hot' },
//   { label: 'Cold', value: 'Cold' },
//   { label: 'Warm', value: 'Warm' },
// ];

// const filterGenderWise = [
//   { value: 'Female', label: 'Female' },
//   { value: 'Male', label: 'Male' },
// ];

// const AllEnquiry = () => {
//   const { fetchAllEquiry, activeBranchId } = useMain();

//   const [data, setData] = useState([]);
//   const [checkedClientEnquiry, setCheckedClientEnquiry] = useState([]);
//   const [showLoader, setShowLoader] = useState(true);
//   const [search, setSearch] = useState('');
//   const [genderBy, setGenderBy] = useState('');
//   const [sourceOfEnquiry, setSourceOfEnquiry] = useState('');
//   const [leadStatus, setLeadStatus] = useState('');
//   const [enquiryType, setEnquiryType] = useState('');
//   const [clear, setClear] = useState(false);
//   const [limit, setLimit] = useState(10);
//   const [page, setPage] = useState(1);

//   const [filterData, setFilterData] = useState(null);
//   console.log(filterData);

//   const navigate = useNavigate()


//   const clearDataHandler = () => {
//     setLimit(10);
//     setPage(1);
//     setSearch('');
//     setSourceOfEnquiry('');
//     setLeadStatus('');
//     setEnquiryType('');
//     setGenderBy('');
//     if (clear) {
//       getData('clear');
//       setClear(false);
//     }
//   };

//   const onApplyHandler = () => {
//     if (search) {
//       getData();
//       setClear(true);
//     }
//   };

//   const getData = async (key) => {
//     // let queryParams = `?page=${page}&limit=${limit}${
//     //   sourceOfEnquiry ? `&sourceOfEnquiry=${sourceOfEnquiry}` : ''
//     // }${enquiryType ? `&enquiryType=${enquiryType}` : ''}${
//     //   leadStatus ? `&leadStatus=${leadStatus}` : ''
//     // }${genderBy ? `&gender=${genderBy}` : ''}${search ? `&search=${search}` : ''}`;

//     // if (key) queryParams = `?page=${page}&limit=${limit}`;
//     try {
//       setShowLoader(true);
//       const res = await fetchAllEquiry(activeBranchId);
//       if (res.statusCode === 200) {
//         setData(res?.data || []);
//       } else {
//       }
//     } catch (error) {
//     } finally {
//       setShowLoader(false);
//     }
//   };

//   useEffect(() => {
//     if (activeBranchId) {
//       setClear(true);
//       getData();
//     }
//     return () => {
//       setData([]);
//     };
//   }, [activeBranchId]);

//   useEffect(() => {
//     applyFilters();
//   }, [limit, page, sourceOfEnquiry, enquiryType, leadStatus, genderBy, data, search]);

//   const applyFilters = () => {
//     let filteredData = data.filter((item) => {
//       let isGenderMatch = true;
//       let isSourceMatch = true;
//       let isTypeMatch = true;
//       let isStatusMatch = true;
//       let isNameMatch = true;

//       if (genderBy) {
//         isGenderMatch = item.gender === genderBy;
//       }

//       if (sourceOfEnquiry) {
//         isSourceMatch = item.sourceOfEnquiry === sourceOfEnquiry;
//       }

//       if (enquiryType) {
//         isTypeMatch = item.enquiryType === enquiryType;
//       }

//       if (leadStatus) {
//         isStatusMatch = item.leadStatus === leadStatus;
//       }

//       if (search) {
//         isNameMatch =
//           item.clientName.toLowerCase().includes(search.toLowerCase()) ||
//           item.clientNumber.includes(search);
//       }

//       return isGenderMatch && isSourceMatch && isTypeMatch && isStatusMatch && isNameMatch;
//     });

//     // Applying pagination
//     const startIndex = (page - 1) * limit;
//     const endIndex = startIndex + limit;
//     filteredData = filteredData.slice(startIndex, endIndex);
//     setFilterData(filteredData);
//   };

//   const handleLimitChange = (e) => {
//     setLimit(e.target.value);
//     setPage(1);
//   };

//   return (
//     <PageContainer title="All Enquiries" description="this contains all Enquiries">
//       <DashboardCard2 title="All Enquiries">
//         <Grid container style={{ gap: '10px' }}>
//           <Grid container justifyContent="space-between">
//             <Grid item xs={1.5}>
//               <InputLabel>Show per page</InputLabel>
//               <Select
//                 style={{
//                   borderRadius: '7px',
//                   border: '0.5px solid #6174DD',
//                   padding: '4px 12px',
//                   background: '#FFF',
//                   height: '40px',
//                   // width:'100px'
//                 }}
//                 value={limit}
//                 onChange={handleLimitChange}
//               >
//                 <MenuItem value={10}>10</MenuItem>
//                 <MenuItem value={20}>20</MenuItem>
//                 <MenuItem value={50}>50</MenuItem>
//               </Select>
//             </Grid>
//             {/* <Grid item xs={1.5}>
//               <InputLabel>Enquiry Type</InputLabel>
//               <Select
//                 style={{
//                   borderRadius: '7px',
//                   border: '0.5px solid #6174DD',
//                   padding: '4px 12px',
//                   background: '#FFF',
//                   height: '40px',
//                   width: '100%',
//                 }}
//                 value={enquiryType}
//                 onChange={(e) => setEnquiryType(e.target.value)}
//               >
//                 {filterEnquiryType.map((item, index) => (
//                   <MenuItem key={index} value={item.value}>
//                     {item.label}
//                   </MenuItem>
//                 ))}
//               </Select>
//             </Grid>
//             <Grid item xs={1.5}>
//               <InputLabel>Lead Status</InputLabel>
//               <Select
//                 style={{
//                   borderRadius: '7px',
//                   border: '0.5px solid #6174DD',
//                   padding: '4px 12px',
//                   background: '#FFF',
//                   height: '40px',
//                   width: '100%',
//                 }}
//                 value={leadStatus}
//                 onChange={(e) => setLeadStatus(e.target.value)}
//               >
//                 {filterLeadStatus.map((item, index) => (
//                   <MenuItem key={index} value={item.value}>
//                     {item.label}
//                   </MenuItem>
//                 ))}
//               </Select>
//             </Grid>
//             <Grid item xs={1.5}>
//               <InputLabel>Source Of Enquiry</InputLabel>
//               <Select
//                 style={{
//                   borderRadius: '7px',
//                   border: '0.5px solid #6174DD',
//                   padding: '4px 12px',
//                   background: '#FFF',
//                   height: '40px',
//                   width: '100%',
//                 }}
//                 value={sourceOfEnquiry}
//                 onChange={(e) => setSourceOfEnquiry(e.target.value)}
//               >
//                 {filterSourceOfEnquiry.map((item, index) => (
//                   <MenuItem key={index} value={item.value}>
//                     {item.label}
//                   </MenuItem>
//                 ))}
//               </Select>
//             </Grid>
//             <Grid item xs={1.5}>
//               <div>
//                 <InputLabel>Gender</InputLabel>
//                 <Select
//                   style={{
//                     borderRadius: '7px',
//                     border: '0.5px solid #6174DD',
//                     padding: '4px 12px',
//                     background: '#FFF',
//                     height: '40px',
//                     width: '100%',
//                   }}
//                   value={genderBy}
//                   onChange={(e) => setGenderBy(e.target.value)}
//                 >
//                   {filterGenderWise.map((item, index) => (
//                     <MenuItem key={index} value={item.value}>
//                       {item.label}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </div>
//             </Grid> */}
//             <Grid item xs={12} sm={6} md={3}>
//               {/* <InputLabel>Filters</InputLabel> */}
//               <BigFilter
//                 options={[
//                   { title: 'Gender', items: filterGenderWise },
//                   { title: 'Source of Enquiry', items: filterSourceOfEnquiry },
//                   { title: 'Lead Status', items: filterLeadStatus },
//                   { title: 'Enquiry Type', items: filterEnquiryType },
//                 ]}
//                 value={[ genderBy, sourceOfEnquiry, leadStatus, enquiryType ].filter(Boolean)}
//                 // onChange={( gender, source, status, type ) => {
//                 onChange={( selectedValues ) => {
//                   // setGenderBy(gender);
//                   // setSourceOfEnquiry(source);
//                   // setLeadStatus(status);
//                   // setEnquiryType(type);

//                   setGenderBy(selectedValues.find(val => filterGenderWise.some(opt => opt.value === val)));
//                   setSourceOfEnquiry(selectedValues.find(val =>  filterSourceOfEnquiry.some(opt => opt.value === val)));
//                   setLeadStatus(selectedValues.find(val =>  filterLeadStatus.some(opt => opt.value === val)));
//                   setEnquiryType(selectedValues.find(val =>  filterEnquiryType.some(opt => opt.value === val)));
//                 }}
//                 placeholder="Filters"
//               />
//             </Grid>
//             <Grid item xs={1.5}>
//               <div>
//                 <InputLabel>Search</InputLabel>
//                 <div className="search-input">
//                   <img src={SearchImg} alt="" />
//                   <input
//                     type="text"
//                     placeholder=""
//                     value={search}
//                     onChange={(e) => setSearch(e.target.value)}
//                   />
//                 </div>
//               </div>
//             </Grid>
//             <Grid item xs={1.5} display="flex" gap="10px" alignItems="flex-end">
//               <Button
//                 variant="contained"
//                 color="primary"
//                 target="_blank"
//                 sx={{
//                   height: '40px',
//                   width: '100%',
//                   background: '#ff4d4d',
//                   '&:hover': {
//                     background: '#ff6666',
//                   },
//                 }}
//                 onClick={clearDataHandler}
//               >
//                 Clear
//               </Button>
//             </Grid>
//             <Grid item xs={2}>
//               <Button
//                 onClick={() => { navigate('/clientReview') }}
//                 variant="contained"
//                 target="_blank"
//                 color="primary"
//                 sx={{
//                   background: '#6174DD',
//                 }}
//                 style={{ marginTop: '2rem' }}
//               >
//                 Client Reviews
//               </Button>
//             </Grid>
//           </Grid>
//         </Grid>
//         {showLoader ? (
//           <ShowLoader />
//         ) : filterData && filterData.length > 0 ? (
//           <>
//             <Box>
//               <Grid container spacing={3}>
//                 <Grid item xs={12} lg={12}>
//                   <FieldData
//                     data={filterData}
//                     setData={setData}
//                     getData={getData}
//                     checkedClientEnquiry={checkedClientEnquiry}
//                     setCheckedClientEnquiry={setCheckedClientEnquiry}
//                   />
//                 </Grid>
//               </Grid>
//             </Box>
//           </>
//         ) : (
//           'No data found'
//         )}
//         <Box display={'flex'} justifyContent={'flex-end'}>
//           <Pagination
//             count={filterData && filterData?.length < limit ? page : page + 1}
//             page={page}
//             color="primary"
//             onChange={(e, page) => setPage(page)}
//           />
//         </Box>
//       </DashboardCard2>
//     </PageContainer>
//   );
// };

// export default AllEnquiry;

//new multiple select filter added
// import React, { useEffect, useState } from 'react';
// import PageContainer from 'src/components/container/PageContainer';
// import { Box, Button, Grid, InputLabel, MenuItem, Pagination, Select } from '@mui/material';
// import { useNavigate } from 'react-router';


// import { useMain } from '../hooks/useMain';
// import ShowLoader from 'src/components/CustomComponents/ShowLoader';
// import SearchImg from '../../assets/search.svg';
// import DashboardCard2 from 'src/components/shared/DashboardCard2';
// import FieldData from './FieldData';
// import BigFilter from 'src/components/inputs/BigFilter';
// // import EnqFil from './EnqFil';

// const filterSourceOfEnquiry = [
//   { label: 'Twitter', value: 'Twitter' },
//   { label: 'Instagram', value: 'Instagram' },
//   { label: 'Walking', value: 'Walking' },
//   { label: 'Facebook', value: 'Facebook' },
//   { label: 'Website', value: 'Website' },
// ];

// const filterLeadStatus = [
//   { label: 'Pending', value: 'Pending' },
//   { label: 'Converted', value: 'Converted' },
//   { label: 'Closed', value: 'Closed' },
// ];

// const filterEnquiryType = [
//   { label: 'Hot', value: 'Hot' },
//   { label: 'Cold', value: 'Cold' },
//   { label: 'Warm', value: 'Warm' },
// ];

// const filterGenderWise = [
//   { value: 'Female', label: 'Female' },
//   { value: 'Male', label: 'Male' },
// ];

// const AllEnquiry = () => {
//   const { fetchAllEquiry, activeBranchId } = useMain();

//   const [data, setData] = useState([]);
//   const [checkedClientEnquiry, setCheckedClientEnquiry] = useState([]);
//   const [showLoader, setShowLoader] = useState(true);
//   const [search, setSearch] = useState('');
//   // const [genderBy, setGenderBy] = useState('');
//   // const [sourceOfEnquiry, setSourceOfEnquiry] = useState('');
//   // const [leadStatus, setLeadStatus] = useState('');
//   // const [enquiryType, setEnquiryType] = useState('');

//   const [genderBy, setGenderBy] = useState([]);
//   const [sourceOfEnquiry, setSourceOfEnquiry] = useState([]);
//   const [leadStatus, setLeadStatus] = useState([]);
//   const [enquiryType, setEnquiryType] = useState([]);

//   const [clear, setClear] = useState(false);
//   const [limit, setLimit] = useState(10);
//   const [page, setPage] = useState(1);

//   const [filterData, setFilterData] = useState(null);
//   console.log(filterData);

//   const navigate = useNavigate()


//   const clearDataHandler = () => {
//     setLimit(10);
//     setPage(1);
//     setSearch('');
//     setSourceOfEnquiry('');
//     setLeadStatus('');
//     setEnquiryType('');
//     setGenderBy('');
//     if (clear) {
//       getData('clear');
//       setClear(false);
//     }
//   };

//   const onApplyHandler = () => {
//     if (search) {
//       getData();
//       setClear(true);
//     }
//   };

//   const getData = async (key) => {
//     // let queryParams = `?page=${page}&limit=${limit}${
//     //   sourceOfEnquiry ? `&sourceOfEnquiry=${sourceOfEnquiry}` : ''
//     // }${enquiryType ? `&enquiryType=${enquiryType}` : ''}${
//     //   leadStatus ? `&leadStatus=${leadStatus}` : ''
//     // }${genderBy ? `&gender=${genderBy}` : ''}${search ? `&search=${search}` : ''}`;

//     // if (key) queryParams = `?page=${page}&limit=${limit}`;
//     try {
//       setShowLoader(true);
//       const res = await fetchAllEquiry(activeBranchId);
//       if (res.statusCode === 200) {
//         setData(res?.data || []);
//       } else {
//       }
//     } catch (error) {
//     } finally {
//       setShowLoader(false);
//     }
//   };

//   useEffect(() => {
//     if (activeBranchId) {
//       setClear(true);
//       getData();
//     }
//     return () => {
//       setData([]);
//     };
//   }, [activeBranchId]);

//   useEffect(() => {
//     applyFilters();
//   }, [limit, page, sourceOfEnquiry, enquiryType, leadStatus, genderBy, data, search]);

//   // const applyFilters = () => {
//   //   let filteredData = data.filter((item) => {
//   //     let isGenderMatch = true;
//   //     let isSourceMatch = true;
//   //     let isTypeMatch = true;
//   //     let isStatusMatch = true;
//   //     let isNameMatch = true;

//   //     if (genderBy) {
//   //       isGenderMatch = item.gender === genderBy;
//   //     }

//   //     if (sourceOfEnquiry) {
//   //       isSourceMatch = item.sourceOfEnquiry === sourceOfEnquiry;
//   //     }

//   //     if (enquiryType) {
//   //       isTypeMatch = item.enquiryType === enquiryType;
//   //     }

//   //     if (leadStatus) {
//   //       isStatusMatch = item.leadStatus === leadStatus;
//   //     }

//   //     if (search) {
//   //       isNameMatch =
//   //         item.clientName.toLowerCase().includes(search.toLowerCase()) ||
//   //         item.clientNumber.includes(search);
//   //     }

//   //     return isGenderMatch && isSourceMatch && isTypeMatch && isStatusMatch && isNameMatch;
//   //   });

//   //   // Applying pagination
//   //   const startIndex = (page - 1) * limit;
//   //   const endIndex = startIndex + limit;
//   //   filteredData = filteredData.slice(startIndex, endIndex);
//   //   setFilterData(filteredData);
//   // };

//   const applyFilters = () => {
//     let filteredData = data.filter((item) => {
//       let isGenderMatch = true;
//       let isSourceMatch = true;
//       let isTypeMatch = true;
//       let isStatusMatch = true;
//       let isNameMatch = true;

//       if (genderBy.length > 0) {
//         isGenderMatch = genderBy.includes(item.gender);
//       }

//       if (sourceOfEnquiry.length > 0) {
//         isSourceMatch = sourceOfEnquiry.includes(item.sourceOfEnquiry);
//       }

//       if (enquiryType.length > 0) {
//         isTypeMatch = enquiryType.includes(item.enquiryType);
//       }

//       if (leadStatus.length > 0) {
//         isStatusMatch = leadStatus.includes(item.leadStatus);
//       }

//       if (search) {
//         isNameMatch =
//           item.clientName.toLowerCase().includes(search.toLowerCase()) ||
//           item.clientNumber.includes(search);
//       }

//       return isGenderMatch && isSourceMatch && isTypeMatch && isStatusMatch && isNameMatch;
//     });

//     // Applying pagination
//     const startIndex = (page - 1) * limit;
//     const endIndex = startIndex + limit;
//     filteredData = filteredData.slice(startIndex, endIndex);
//     setFilterData(filteredData);
//   };

//   const handleLimitChange = (e) => {
//     setLimit(e.target.value);
//     setPage(1);
//   };

//   return (
//     <PageContainer title="All Enquiries" description="this contains all Enquiries">
//       <DashboardCard2 title="All Enquiries">
//         <Grid container style={{ gap: '10px' }}>
//           <Grid container justifyContent="space-between">
//             <Grid item xs={1.5}>
//               <InputLabel>Show per page</InputLabel>
//               <Select
//                 style={{
//                   borderRadius: '7px',
//                   border: '0.5px solid #6174DD',
//                   padding: '4px 12px',
//                   background: '#FFF',
//                   height: '40px',
//                   // width:'100px'
//                 }}
//                 value={limit}
//                 onChange={handleLimitChange}
//               >
//                 <MenuItem value={10}>10</MenuItem>
//                 <MenuItem value={20}>20</MenuItem>
//                 <MenuItem value={50}>50</MenuItem>
//               </Select>
//             </Grid>
//             <Grid item xs={12} sm={6} md={3}>
//               {/* <InputLabel>Filters</InputLabel> */}
//               {/* <BigFilter
//                 options={[
//                   { title: 'Gender', items: filterGenderWise },
//                   { title: 'Source of Enquiry', items: filterSourceOfEnquiry },
//                   { title: 'Lead Status', items: filterLeadStatus },
//                   { title: 'Enquiry Type', items: filterEnquiryType },
//                 ]}
//                 value={[genderBy, sourceOfEnquiry, leadStatus, enquiryType].filter(Boolean)}
//                 // onChange={( gender, source, status, type ) => {
//                 onChange={(selectedValues) => {
//                   // setGenderBy(gender);
//                   // setSourceOfEnquiry(source);
//                   // setLeadStatus(status);
//                   // setEnquiryType(type);

//                   setGenderBy(selectedValues.find(val => filterGenderWise.some(opt => opt.value === val)));
//                   setSourceOfEnquiry(selectedValues.find(val => filterSourceOfEnquiry.some(opt => opt.value === val)));
//                   setLeadStatus(selectedValues.find(val => filterLeadStatus.some(opt => opt.value === val)));
//                   setEnquiryType(selectedValues.find(val => filterEnquiryType.some(opt => opt.value === val)));
//                 }}
//                 placeholder="Filters"
//               /> */}
//               <BigFilter
//                 options={[
//                   { title: 'Gender', items: filterGenderWise },
//                   { title: 'Source of Enquiry', items: filterSourceOfEnquiry },
//                   { title: 'Lead Status', items: filterLeadStatus },
//                   { title: 'Enquiry Type', items: filterEnquiryType },
//                 ]}
//                 value={[...genderBy, ...sourceOfEnquiry, ...leadStatus, ...enquiryType]}
//                 onChange={(selectedValues) => {
//                   setGenderBy(selectedValues.filter(val => filterGenderWise.some(opt => opt.value === val)));
//                   setSourceOfEnquiry(selectedValues.filter(val => filterSourceOfEnquiry.some(opt => opt.value === val)));
//                   setLeadStatus(selectedValues.filter(val => filterLeadStatus.some(opt => opt.value === val)));
//                   setEnquiryType(selectedValues.filter(val => filterEnquiryType.some(opt => opt.value === val)));
//                 }}
//                 placeholder="Filters"
//               />
//             </Grid>
//             <Grid item xs={1.5}>
//               <div>
//                 <InputLabel>Search</InputLabel>
//                 <div className="search-input">
//                   <img src={SearchImg} alt="" />
//                   <input
//                     type="text"
//                     placeholder=""
//                     value={search}
//                     onChange={(e) => setSearch(e.target.value)}
//                   />
//                 </div>
//               </div>
//             </Grid>
//             <Grid item xs={1.5} display="flex" gap="10px" alignItems="flex-end">
//               <Button
//                 variant="contained"
//                 color="primary"
//                 target="_blank"
//                 sx={{
//                   height: '40px',
//                   width: '100%',
//                   background: '#ff4d4d',
//                   '&:hover': {
//                     background: '#ff6666',
//                   },
//                 }}
//                 onClick={clearDataHandler}
//               >
//                 Clear
//               </Button>
//             </Grid>
//             <Grid item xs={2}>
//               <Button
//                 onClick={() => { navigate('/clientReview') }}
//                 variant="contained"
//                 target="_blank"
//                 color="primary"
//                 sx={{
//                   background: '#6174DD',
//                 }}
//                 style={{ marginTop: '2rem' }}
//               >
//                 Client Reviews
//               </Button>
//             </Grid>
//           </Grid>
//         </Grid>
//         {showLoader ? (
//           <ShowLoader />
//         ) : filterData && filterData.length > 0 ? (
//           <>
//             <Box>
//               <Grid container spacing={3}>
//                 <Grid item xs={12} lg={12}>
//                   <FieldData
//                     data={filterData}
//                     setData={setData}
//                     getData={getData}
//                     checkedClientEnquiry={checkedClientEnquiry}
//                     setCheckedClientEnquiry={setCheckedClientEnquiry}
//                   />
//                 </Grid>
//               </Grid>
//             </Box>
//           </>
//         ) : (
//           'No data found'
//         )}
//         <Box display={'flex'} justifyContent={'flex-end'}>
//           <Pagination
//             count={filterData && filterData?.length < limit ? page : page + 1}
//             page={page}
//             color="primary"
//             onChange={(e, page) => setPage(page)}
//           />
//         </Box>
//       </DashboardCard2>
//     </PageContainer>
//   );
// };

// export default AllEnquiry;
import React, { useEffect, useState } from 'react';
import PageContainer from 'src/components/container/PageContainer';
import { Box, Button, Grid, InputLabel, MenuItem, Pagination, Select } from '@mui/material';
import { useNavigate } from 'react-router';
import { useMain } from '../hooks/useMain';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import SearchImg from '../../assets/search.svg';
import DashboardCard2 from 'src/components/shared/DashboardCard2';
import FieldData from './FieldData';
import BigFilter from 'src/components/inputs/BigFilter';

const filterSourceOfEnquiry = [
  { label: 'Twitter', value: 'Twitter' },
  { label: 'Instagram', value: 'Instagram' },
  { label: 'Walking', value: 'Walking' },
  { label: 'Facebook', value: 'Facebook' },
  { label: 'Website', value: 'Website' },
];

const filterLeadStatus = [
  { label: 'Pending', value: 'Pending' },
  { label: 'Converted', value: 'Converted' },
  { label: 'Closed', value: 'Closed' },
];

const filterEnquiryType = [
  { label: 'Hot', value: 'Hot' },
  { label: 'Cold', value: 'Cold' },
  { label: 'Warm', value: 'Warm' },
];

const filterGenderWise = [
  { value: 'Female', label: 'Female' },
  { value: 'Male', label: 'Male' },
];

const AllEnquiry = () => {
  const { fetchAllEquiry, activeBranchId } = useMain();
  const [data, setData] = useState([]);
  const [checkedClientEnquiry, setCheckedClientEnquiry] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [search, setSearch] = useState('');
  const [genderBy, setGenderBy] = useState([]);
  const [sourceOfEnquiry, setSourceOfEnquiry] = useState([]);
  const [leadStatus, setLeadStatus] = useState([]);
  const [enquiryType, setEnquiryType] = useState([]);
  const [clear, setClear] = useState(false);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [filterData, setFilterData] = useState([]);

  const navigate = useNavigate();

  const clearDataHandler = () => {
    setLimit(10);
    setPage(1);
    setSearch('');
    setSourceOfEnquiry([]);
    setLeadStatus([]);
    setEnquiryType([]);
    setGenderBy([]);
    if (clear) {
      getData('clear');
      setClear(false);
    }
  };

  const onApplyHandler = () => {
    if (search) {
      getData();
      setClear(true);
    }
  };

  const getData = async (key) => {
    try {
      setShowLoader(true);
      const res = await fetchAllEquiry(activeBranchId);
      if (res.statusCode === 200) {
        setData(res?.data || []);
      } else {
        // Handle errors here
      }
    } catch (error) {
      // Handle errors here
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    if (activeBranchId) {
      setClear(true);
      getData();
    }
    return () => {
      setData([]);
    };
  }, [activeBranchId]);

  useEffect(() => {
    applyFilters();
  }, [limit, page, sourceOfEnquiry, enquiryType, leadStatus, genderBy, data, search]);

  const applyFilters = () => {
    let filteredData = data.filter((item) => {
      let isGenderMatch = genderBy.length === 0 || genderBy.includes(item.gender);
      let isSourceMatch = sourceOfEnquiry.length === 0 || sourceOfEnquiry.includes(item.sourceOfEnquiry);
      let isTypeMatch = enquiryType.length === 0 || enquiryType.includes(item.enquiryType);
      let isStatusMatch = leadStatus.length === 0 || leadStatus.includes(item.leadStatus);
      let isNameMatch = !search || item.clientName.toLowerCase().includes(search.toLowerCase()) || item.clientNumber.includes(search);

      return isGenderMatch && isSourceMatch && isTypeMatch && isStatusMatch && isNameMatch;
    });

    // Applying pagination
    const startIndex = (page - 1) * limit;
    const endIndex = startIndex + limit;
    filteredData = filteredData.slice(startIndex, endIndex);
    setFilterData(filteredData);
  };

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setPage(1);
  };

  const totalPages = Math.ceil(data.length / limit);

  return (
    <PageContainer title="All Enquiries" description="This contains all Enquiries">
      <DashboardCard2 title="All Enquiries">
        <Grid container style={{ gap: '10px' }}>
          <Grid container justifyContent="space-between">
            <Grid item xs={2}>
              <InputLabel>Show per page</InputLabel>
              <Select
                style={{
                  borderRadius: '7px',
                  border: '0.5px solid #6174DD',
                  padding: '4px 12px',
                  background: '#FFF',
                  height: '40px',
                }}
                value={limit}
                onChange={handleLimitChange}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} sm={6} md={3} marginLeft={10}>
              <BigFilter
                options={[
                  { title: 'Gender', items: filterGenderWise },
                  { title: 'Source of Enquiry', items: filterSourceOfEnquiry },
                  { title: 'Lead Status', items: filterLeadStatus },
                  { title: 'Enquiry Type', items: filterEnquiryType },
                ]}
                value={[...genderBy, ...sourceOfEnquiry, ...leadStatus, ...enquiryType]}
                onChange={(selectedValues) => {
                  setGenderBy(selectedValues.filter(val => filterGenderWise.some(opt => opt.value === val)));
                  setSourceOfEnquiry(selectedValues.filter(val => filterSourceOfEnquiry.some(opt => opt.value === val)));
                  setLeadStatus(selectedValues.filter(val => filterLeadStatus.some(opt => opt.value === val)));
                  setEnquiryType(selectedValues.filter(val => filterEnquiryType.some(opt => opt.value === val)));
                }}
                placeholder="Filters"
              />
            </Grid>
            {/* <Grid item xs={1.3} marginLeft={5}> */}
            <Grid item xs={1.3} marginLeft={3}>
              {/* <div> */}
                <InputLabel>Search</InputLabel>
                <div className="search-input">
                  <img src={SearchImg} alt="" />
                  <input
                    type="text"
                    placeholder=""
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              {/* </div> */}
            </Grid>
            <Grid item xs={1.5} display="flex">
              <Button
                onClick={() => { navigate('/clientReview') }}
                variant="contained"
                color="primary"
                sx={{
                  background: '#6174DD',
                  marginTop: '1.7rem',
                  padding: '0.5rem ',
                  marginLeft:'1rem',
                  width:'30rem'
                }}
              >
                Client Reviews
                {/* Reviews */}
              </Button>
            </Grid>
            <Grid item xs={1.3}>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  marginTop: '1.7rem',
                  height: '40px',
                  width: '80%',
                  // width: '60%',
                  marginLeft:'1rem',
                  background: '#ff4d4d',
                  '&:hover': {
                    background: '#ff6666',
                  },
                }}
                onClick={clearDataHandler}
              >
                Clear
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {showLoader ? (
          <ShowLoader />
        ) : filterData.length > 0 ? (
          <Box>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <FieldData
                  data={filterData}
                  setData={setData}
                  getData={getData}
                  checkedClientEnquiry={checkedClientEnquiry}
                  setCheckedClientEnquiry={setCheckedClientEnquiry}
                />
              </Grid>
            </Grid>
          </Box>
        ) : (
          'No data found'
        )}
        <Box display={'flex'} justifyContent={'flex-end'}>
          <Pagination
            count={totalPages}
            page={page}
            color="primary"
            onChange={(e, page) => setPage(page)}
          />
        </Box>
      </DashboardCard2>
    </PageContainer >
  );
};

export default AllEnquiry;
