import React from 'react';
import { Card, CardContent, Typography, Stack, Box, useTheme } from '@mui/material';

const DashboardCard = ({
  title,
  subtitle,
  children,
  action,
  footer,
  cardheading,
  headtitle,
  headsubtitle,
  middlecontent,
}) => {

  const theme=useTheme()

  return (
    <Card
      sx={{
        padding: {
          xs: '10px', // for extra-small screens and up
          sm: '15px', // for small screens and up
          md: '20px', // for medium screens and up
          lg: '25px', // for large screens and up
        },
        // padding: 5,
        background: '#F2F2F7',
        borderRadius: 4
      }}
      // elevation={9}
      variant={undefined}
    >
      {cardheading ? (
        <CardContent>
          <Typography variant="h5">{headtitle}</Typography>
          <Typography variant="subtitle2" color="textSecondary">
            {headsubtitle}
          </Typography>
        </CardContent>
      ) : (
        <CardContent sx={{
          background: 'white',
          borderRadius: 5,
          boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.08), 0px 8px 8px rgba(0, 0, 0, 0.08)',
          padding: 0
        }}
        // elevation={9}
        >
          {title ? (
            <Stack
              direction="row"
              spacing={2}
              justifyContent="space-between"
              alignItems={'center'}
              mb={3}
              sx={{
                background: '#6174DD',
                px: 3,
                py: 2,
                borderRadius: 5,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                color: 'white'
              }}
            >
              <Box>
                {title ? <Typography variant="h5">{title}</Typography> : ''}

                {subtitle ? (
                  <Typography variant="subtitle2" color="textSecondary">
                    {subtitle}
                  </Typography>
                ) : (
                  ''
                )}
              </Box>
              {action}
            </Stack>
          ) : null}
          <Box
            sx={{
              px: {
                xs: '20px', // for extra-small screens and up
                sm: '25px', // for small screens and up
                md: '28px', // for medium screens and up
                lg: '30px', // for large screens and up
              },
              minHeight: '60vh'
            }}
          >
            {children}
          </Box>
        </CardContent>
      )}
      {footer}
    </Card>
  );
};

export default DashboardCard;
