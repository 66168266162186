import React, { useState } from 'react';
import {
    Typography, Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Chip,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Snackbar,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    InputAdornment,
    Avatar,
    IconButton
} from '@mui/material';
import { useMain } from '../hooks/useMain';
import { useNavigate } from 'react-router';
import TableCells from 'src/components/Tables/TableCells';
import { CheckCircleRounded, DangerousRounded, Search } from '@mui/icons-material';


export const TrimData = (data) => {
    if (data?.length < 25)
        return data;
    return data?.substring(0, 15) + '...' + data?.substring(data?.length - 10);
};


const Users = ({ data, setData }) => {
    const [alert, setAlert] = useState('');
    const [openDialogue, setOpenDialogue] = useState(false);
    const [id, setId] = useState(null);

    const { updateRequest } = useMain();

    const handleCloseDialogue = () => {
        setOpenDialogue(false);
        setId(null);
    }

    const handleAction = async (data, id) => {

        const res = await updateRequest(data === 'Cancel' ? 'Cancelled By Admin' : 'Active', id);

        if (res?.statusCode === 200) {
            // setData(data?.filter((data) => data?._id !== id));
            setId(null);
            setAlert('Success!')
        }
        else {
            setAlert(res?.message)
        }
    };

    return (
        <Box sx={{ overflow: 'auto', width: { xs: '450px', sm: 'auto' } }}>
            <Snackbar
                open={alert === '' ? false : true}
                autoHideDuration={6000}
                onClose={() => {
                    setAlert('');
                }}
                severity="error"
                message={alert}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            />

            <Table
                aria-label="simple table"
                sx={{
                    whiteSpace: "nowrap",
                    mt: 2
                }}
            >
                <TableHead>
                    <TableRow
                        sx={{
                            background: '#6174DD',
                        }}
                    >
                        <TableCells
                            borderRadius={'10px 0 0 10px'}
                            color={'white'}
                            type={'Heading'}
                            data={'Name'}
                        />
                        {
                            [
                                'Email',
                                'Address',
                                'kyc',
                                'Subscription Status',
                                'Payment Status',
                                'Start Date',
                                'End Date'
                            ].map((item, index) =>
                                <TableCells
                                    key={index}
                                    color={'white'}
                                    type={'Heading'}
                                    data={item}
                                />
                            )
                        }
                        <TableCells
                            borderRadius={'0px 10px 10px 0px'}
                            color={'white'}
                            type={'Heading'}
                            data={'Actions'}
                        />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.map((item) => (
                        <TableRow
                            key={item?._id}
                        >
                            <TableCells
                                // color={'white'}
                                // background={'red'}
                                type={'content'}
                                data={TrimData(item?.salonOwnerId?.name)}
                            />
                            <TableCells
                                borderRadius={'10px 0 0 10px'}
                                // color={'white'}
                                // background={'red'}
                                type={'content'}
                                data={TrimData(item?.salonOwnerId?.email)}
                            />
                            <TableCell>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: 'column',
                                        gap: '5px'
                                    }}
                                >
                                    {
                                        item?.salonOwnerId?.salonAddress?.map((k, i) =>
                                            <Box
                                                key={i}
                                                sx={{
                                                    background: '#D0D0D0',
                                                    padding: '10px',
                                                    borderRadius: '5px',
                                                    width: '150px',
                                                    whiteSpace: 'pre-wrap',
                                                    // overflowX: 'auto',
                                                    // '&::-webkit-scrollbar': {
                                                    //     width: '0px',
                                                    // }
                                                }}
                                            >
                                                <Typography variant="subtitle2" fontWeight={600}>
                                                    {TrimData(k?.address)}, {k?.city}, ({k?.pincode})
                                                </Typography>
                                            </Box>
                                        )
                                    }
                                </Box>
                            </TableCell>
                            <TableCell>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: 'column',
                                        gap: '5px'
                                    }}
                                >
                                    {
                                        item?.kyc?.map((k, i) =>
                                            <Avatar
                                                src={k?.documentImg}
                                                alt={k?.documentName}
                                                key={i}
                                                sx={{
                                                    width: '90px',
                                                    height: '60px',
                                                    borderRadius: '10px'
                                                }}
                                            />
                                        )
                                    }
                                </Box>
                            </TableCell>
                            <TableCells
                                type={'content'}
                                data={item?.status}
                            />
                            <TableCells
                                type={'content'}
                                data={item?.paymentStatus}
                            />
                            <TableCells
                                type={'content'}
                                data={new Date(item?.startDate)?.toDateString()}
                            />
                            <TableCells
                                type={'content'}
                                data={new Date(item?.endDate)?.toDateString()}
                            />
                            <TableCell
                                sx={{
                                    borderBottom: '0.5px solid #CFCFCF',
                                    my: '10px'
                                }}
                            >
                                <div>
                                    <IconButton
                                        aria-controls="dropdown-menu"
                                        aria-haspopup="true"
                                        onClick={() => { setOpenDialogue('Accept'); setId(item?._id) }}
                                        size="small"
                                    >
                                        <CheckCircleRounded sx={{ color: 'green' }} />
                                    </IconButton>
                                    <IconButton
                                        aria-controls="dropdown-menu"
                                        aria-haspopup="true"
                                        onClick={() => { setOpenDialogue('Cancel'); setId(item?._id) }}
                                        size="small"
                                    >
                                        <DangerousRounded sx={{ color: 'red' }} />
                                    </IconButton>
                                </div>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Dialog open={openDialogue} onClose={handleCloseDialogue}>
                <DialogTitle>Confirm Request</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to {openDialogue} the request?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialogue}>Cancel</Button>
                    <Button onClick={() => handleAction(openDialogue, id)} autoFocus>
                        Sure
                    </Button>
                </DialogActions>
            </Dialog>
        </Box >
    );
};

export default Users;