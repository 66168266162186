import { baseUrl, headers, jwtHeaders } from '../common/data';

export const fetchAllAppointments = async (branchId, queryParams) => {
  const response = await fetch(
    `${baseUrl}/api/appointment/getbranchwise/${branchId}${queryParams ? queryParams : ''}`,
    {
      method: 'GET',
      headers: jwtHeaders(),
      redirect: 'follow',
    },
  );
  const resData = await response?.json();

  return resData;
};

export const fetchAppointmentById = async (appointmentId, branchId) => {
  const response = await fetch(
    `${baseUrl}/api/appointment/getparticularappointment/${appointmentId}${
      branchId ? branchId : ''
    }`,
    {
      method: 'GET',
      headers: jwtHeaders(),
      redirect: 'follow',
    },
  );
  const resData = await response?.json();
  return resData;
};

export const createAppointment = async (data) => {
  const response = await fetch(`${baseUrl}/api/appointment/createappointment`, {
    method: 'POST',
    body: data,
    headers: jwtHeaders(),
  });
  const resData = await response?.json();

  return resData;
};

export const deleteAppointment = async (id, branchId) => {
  const response = await fetch(
    `${baseUrl}/api/appointment/deleteappointment/${id}${branchId ? branchId : ''}`,
    {
      method: 'DELETE',
      headers: jwtHeaders(),
    },
  );
  const resData = await response?.json();

  return resData;
};

export const updateAppointment = async (id, data, branchId) => {
  const response = await fetch(
    `${baseUrl}/api/appointment/updateappointment/${id}${branchId ? branchId : ''}`,
    {
      method: 'PUT',
      body: JSON.stringify(data),
      headers:jwtHeaders(),
    },
  );
  const resData = await response?.json();
  return resData;
};

export const assignAppointment = async (id, data, branchId) => {
  const response = await fetch(
    `${baseUrl}/api/staff/assignappointment/${id}${branchId ? branchId : ''}`,
    {
      method: 'POST',
      body: data,
      headers: jwtHeaders(),
    },
  );
  const resData = await response?.json();
  return resData;
};

export const fetchStaffAppointmentsDateWise = async (queryParams) => {
  const response = await fetch(
    `${baseUrl}/api/appointment/staffAppointmentsDatewise${queryParams ? queryParams : ''}`,
    {
      method: 'GET',
      headers:jwtHeaders(),
    },
  );
  const resData = await response?.json();

  return resData;
};

export const updateAppointmentStatus = async (id, data, branchId) => {
  const response = await fetch(
    `${baseUrl}/api/appointment/updateappointmentstatus/${id}${branchId ? branchId : ''}`,
    {
      method: 'PUT',
      body: JSON.stringify(data),
      headers:jwtHeaders(),
    },
  );
  const resData = await response?.json();
  return resData;
};

export const createWebAppointmentBookingLink = async (branchId) => {
  const response = await fetch(`${baseUrl}/api/appointment/appointmentpage/${branchId}`, {
    method: 'GET',
    headers:jwtHeaders(),
  });
  const resData = await response?.json();
  return resData;
};

export const fetchAppointmentAnalytics = async (branchId) => {
  const response = await fetch(`${baseUrl}/api/appointment/appointmentsoverview?branchId=${branchId}`, {
    method: 'GET',
    headers:jwtHeaders(),
  });
  const resData = await response?.json();
  return resData;
};