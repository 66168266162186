import { Box, Button, Grid, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ReqField } from 'src/components/CustomComponents/CustomLoader';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import PageContainer from 'src/components/container/PageContainer';
import TextInputs from 'src/components/inputs/TextInputs';
import DashboardCard from 'src/components/shared/DashboardCard';
import { useMain } from '../hooks/useMain';
import SelectInputs from 'src/components/inputs/SelectInputs';

const initialValue = {
  date: getCurrentDateInFormat(),
  typeOfExpense: '',
  others: '',
  staffId: '',
  amountPaid: '',
  modeOfPayment: 'Cash',
  recipientName: '',
  description: '',
};

const initialFormValidation = {
  dateValidation: false,
  typeOfExpenseValidation: false,
  othersValidation: false,
  staffIdValidation: false,
  amountPaidValidation: false,
};

export const initialTypeOfExpense = [
  { label: 'Salary' },
  { label: 'Staff Advance' },
  { label: 'Others' },
];
const paymentmode = ['Cash', 'Credit/Debit Card', 'Paytm', 'PhonePe', 'Gpay'];

const AddExpenses = () => {
  const [formValidation, setFormValidation] = useState(initialFormValidation);
  const [formData, setFormData] = useState(initialValue);
  const [staff, setStaff] = useState([]);

  const [typeOfExpenseData, setTypeOfExpenseData] = useState(initialTypeOfExpense);
  const [loading, setLoading] = useState(false);

  const dateValidation = !formData.date;
  const typeOfExpenseValidation = !formData.typeOfExpense;
  const othersValidation = !formData.others;
  const staffIdValidation = !formData.staffId;
  const amountPaidValidation = !formData.amountPaid || !(Number(formData.amountPaid) > 0);

  const { createExpense, activeBranchId, setShowMessage, fetchAllExpenseType, getAllStaff } =
    useMain();

  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    const { date, typeOfExpense, amountPaid, others, staffId } = formData;

    let validation = {
      dateValidation: !date,
      typeOfExpenseValidation: !typeOfExpense,
      othersValidation: false,
      staffIdValidation: false,
      amountPaidValidation: !amountPaid || !(Number(amountPaid) > 0),
    };

    // Adjust validation rules based on the selected typeOfExpense
    if (typeOfExpense === 'Others') {
      validation = {
        ...validation,
        othersValidation: !others.trim(),
      };
    } else if (typeOfExpense === 'Staff Advance') {
      validation = {
        ...validation,
        staffIdValidation: !staffId,
      };
    }

    setFormValidation((pre) => ({ ...pre, ...validation }));

    if (Object.values(validation).some((item) => item)) return;

    try {
      setLoading(true);
      const res = await createExpense({
        ...formData,
        branchDetails: activeBranchId,
      });
      if (res.statusCode === 200) {
        setShowMessage({
          message: res.message || 'Expense has been created successfully',
          messageType: 'success',
        });
        setFormData(initialValue);
        setFormValidation((pre) => ({ ...initialFormValidation }));
        getAllTypeOfExpense();
      } else {
        setShowMessage({ message: res.message || 'Something went wrong', messageType: 'error' });
      }
    } catch (error) {
      setShowMessage({ message: error.message || 'Something went wrong', messageType: 'error' });
    } finally {
      setLoading(false);
    }
  };

  // This logic disables the mouse wheel event
  useEffect(() => {
    const handleWheel = (e) => {
      if (document.activeElement.type === 'number') {
        document.activeElement.blur();
      }
    };

    window.addEventListener('wheel', handleWheel);
    return () => {
      window.removeEventListener('wheel', handleWheel);
    };
  }, []);

  const getAllTypeOfExpense = async () => {
    try {
      const res = await fetchAllExpenseType(activeBranchId);
      if (res.statusCode === 200) {
        setTypeOfExpenseData((pre) => [
          ...[{ label: 'Salary' }, { label: 'Staff Advance' }],
          ...res?.data.map((type) => ({ label: type })),
          { label: 'Others' },
        ]);
      }
    } catch (error) {}
  };

  const getAllStaffData = async () => {
    try {
      const res = await getAllStaff(activeBranchId);
      setStaff(res?.data || []);
    } catch (error) {}
  };
  useEffect(() => {
    if (activeBranchId) {
      getAllStaffData();
      getAllTypeOfExpense();
    }
    return () => {
      setTypeOfExpenseData(initialTypeOfExpense);
      setStaff({});
    };
  }, [activeBranchId]);

  return (
    <PageContainer title="Create Expense" description="this is add expenses">
      <DashboardCard title={'Create Expense'}>
        <Box style={{ marginTop: '10px' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <TextInputs
                title={'Date'}
                name={'date'}
                value={formData?.date}
                handleChange={handleChange}
                type={'date'}
                max={new Date().toISOString().split('T')[0]}
                fieldValidation={dateValidation && formValidation.dateValidation}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <SelectInputs
                name={'typeOfExpense'}
                value={formData?.typeOfExpense}
                title={'Type of Expense'}
                handleChange={handleChange}
                options={typeOfExpenseData}
                optionValue={'label'}
                optionTitle={'label'}
                fieldValidation={typeOfExpenseValidation && formValidation.typeOfExpenseValidation}
              />
            </Grid>
            {formData.typeOfExpense === 'Others' ? (
              <Grid item xs={12} md={4}>
                <TextInputs
                  title={'Others'}
                  name={'others'}
                  value={formData?.others}
                  handleChange={handleChange}
                  type={'text'}
                  fieldValidation={othersValidation && formValidation.othersValidation}
                />
              </Grid>
            ) : formData.typeOfExpense === 'Staff Advance' ? (
              <Grid item xs={12} md={4}>
                <SelectInputs
                  name={'staffId'}
                  value={formData?.staffId}
                  title={'Staff'}
                  handleChange={handleChange}
                  options={staff}
                  optionValue={'_id'}
                  optionTitle={'name'}
                  fieldValidation={staffIdValidation && formValidation.staffIdValidation}
                />
              </Grid>
            ) : (
              ''
            )}
            <Grid item xs={12} md={4}>
              <TextInputs
                title={'Amount Paid'}
                name={'amountPaid'}
                value={formData?.amountPaid}
                // placeholder={'0'}
                handleChange={handleChange}
                type={'number'}
                min={'0'}
                fieldValidation={amountPaidValidation && formValidation.amountPaidValidation}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <div style={{ marginBottom: '6px', fontWeight: 'bold' }}>
                Payment Mode <ReqField />
              </div>
              <Select
                name="modeOfPayment"
                value={formData?.modeOfPayment}
                onChange={handleChange}
                fullWidth
              >
                {paymentmode?.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextInputs
                title={'Receipt Name'}
                name={'recipientName'}
                value={formData?.recipientName}
                handleChange={handleChange}
                type={'text'}
                required={false}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextInputs
                required={false}
                title={'Description'}
                name={'description'}
                value={formData?.description}
                handleChange={handleChange}
                type={'text'}
              />
            </Grid>
          </Grid>
        </Box>
        {loading ? (
          <ShowLoader />
        ) : (
          <div style={{ width: '200px', margin: '3rem auto' }}>
            <Button
              variant="contained"
              fullWidth
              color="primary"
              target="_blank"
              sx={{
                background: '#6174DD',
              }}
              onClick={handleSubmit}
            >
              Create Expense
            </Button>
          </div>
        )}
      </DashboardCard>
    </PageContainer>
  );
};

export default AddExpenses;

// Function to get the current date in a specific format
function getCurrentDateInFormat() {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}
