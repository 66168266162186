import { baseUrl, headers, jwtHeaders } from "../common/data";

export const fetchAllCategories = async (branchId) => {
    const response = await fetch(`${baseUrl}/api/service/category/getallcategory`, {
        method: "GET",
        headers: jwtHeaders(),
        redirect: "follow",
    });
    const resData = await response?.json();
    
    return resData;
};

export const createCategory = async (data) => {

        const response = await fetch(`${baseUrl}/api/service/category/create`, {
            method: 'POST',
            body: data,
            headers: jwtHeaders()
        })
        const resData = await response?.json();
        
        return resData;

};