import React from 'react';
import { Box, Typography, Stack, Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

import CustomTextField from '../../../components/forms/theme-elements/CustomTextField';
import { useState } from 'react';
import { useMain } from 'src/views/hooks/useMain';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';

const AuthForgotPassword = ({ title, subtitle, subtext }) => {
  const [email, setEmail] = useState('');
  const [loading,setLoading]=useState(false)

  const navigate = useNavigate();
  const { forgotpassowrd,setShowMessage } = useMain();


  const handleSubmit = async () => {
    if (!email) return setShowMessage({message:'Please enter the email',messageType:'error'});
    try {
      setLoading(true)
      const res = await forgotpassowrd({ email });
      if (res.statusCode === 200) {
      setShowMessage({message:res.message || 'Check your email and reset your password',messageType:'success'});
      navigate("/auth/login")
      } else {
        return setShowMessage({message:res.message || 'Something went wrong',messageType: 'error'});
      }
    } catch (error) {
      return setShowMessage({message:error.message || 'Something went wrong',messageType: 'error'});
    }finally{
      setLoading(false)
    }
  };
  return (
    <>
      {title ? (
        <Typography fontWeight="700" variant="h2" mb={1}>
          {title}
        </Typography>
      ) : null}

      {subtext}

      <Stack>
        <Box>
          <Typography
            variant="subtitle1"
            fontWeight={600}
            component="label"
            htmlFor="username"
            mb="5px"
          >
            Email
          </Typography>
          <CustomTextField
            id="username"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            variant="outlined"
            fullWidth
          />
        </Box>

        <Stack justifyContent="flex-end" direction="row" alignItems="center" my={2}>
          <Typography
            component={Link}
            to="/auth/login"
            fontWeight="500"
            sx={{
              textDecoration: 'none',
              color: 'primary.main',
            }}
          >
            Login ?
          </Typography>
        </Stack>
      </Stack>
      <Box>
        {loading?<ShowLoader value={loading}/>:<Button
          color="primary"
          variant="contained"
          size="large"
          fullWidth
          type="submit"
          onClick={handleSubmit}
        >
          Send
        </Button>}
      </Box>
    </>
  );
};

export default AuthForgotPassword;
