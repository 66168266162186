import * as React from 'react';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

export default function ShowLoader() {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop:'10px'
      }}
    >
      <Stack sx={{ color: 'grey.500' }} spacing={2} direction="row">
        <CircularProgress color="success" />
      </Stack>
    </div>
  )
}
