import React, { useEffect, useState } from 'react';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard2 from 'src/components/shared/DashboardCard2';
import ToggleMaskingPage from './MaskingInformation/ToggleMaskingPage';
import { useMain } from '../hooks/useMain';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import { useNavigate } from 'react-router';
import { Box, padding, width } from '@mui/system';
import WebAppointmentBookingLink from './WebAppointmentBookingLink/WebAppointmentBookingLink';
import OwnerProfile from './OwnerProfile/OwnerProfile';
import GstBillsPage from './GstBillsData/GstBillsPage';
import { Button } from '@mui/material';

const Settings = () => {
  const [loading, setLoading] = useState(false);
  const [showGstBills, setShowGstBills] = useState(false);

  const navigate = useNavigate();

  const { fetchAOwner, setOwnerData, ownerData, setActiveBranchId, getBranch, setBranchData } =
    useMain();

  const refreshBranchData = async (id) => {
    try {
      const res = await getBranch(id);
      if (res.statusCode === 200) setBranchData((pre) => ({ ...pre, ...res.data }));
    } catch (error) { }
  };

  const refreshData = async (id) => {
    try {
      setLoading(true);
      const res = await fetchAOwner(id);
      if (res?.statusCode === 200) {
        const owner = res?.data;
        if (owner?.activeStatus && owner?.activeStatus.toLowerCase() !== 'active') {
          localStorage.removeItem('salon-admin');
          setActiveBranchId(null);
          setBranchData({});
          setOwnerData({});
          navigate('/auth/login');
          return;
        }
        refreshBranchData(owner?.branch[0]?._id);
        localStorage.setItem('salon-admin', JSON.stringify(owner._id));
        setOwnerData(owner);
        setActiveBranchId(
          () => owner?.branch?.filter((item) => item?.activeStatus === 'APPROVED')[0]?._id,
        );
      } else {
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageContainer title="Settings" description="This is the settings page">
      <DashboardCard2 title="Settings">
        {loading ? (
          <ShowLoader />
        ) : (
          <Box display="flex" flexDirection="column" gap={'10px'}>
            <OwnerProfile data={ownerData} refreshData={refreshData} />
            <ToggleMaskingPage data={ownerData} refreshData={refreshData} />
            <WebAppointmentBookingLink />
            <Button style={{ width: "10%", padding: "0.3rem 0.1rem" }}
              variant="contained" onClick={() => setShowGstBills(!showGstBills)}>
              {showGstBills ? 'Hide GST Bills' : 'Show GST Bills'}
            </Button>
            {showGstBills && <GstBillsPage />}
          </Box>
        )}
      </DashboardCard2>
    </PageContainer>
  );
};

export default Settings;
