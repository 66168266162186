import React from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { ReqField } from '../CustomComponents/CustomLoader';
import CustomTextField from '../forms/theme-elements/CustomTextField';

const TextInputs = ({
  title,
  name,
  value,
  handleChange,
  type,
  required = true,
  fieldValidation,
  disabled = false,
  placeholder,
  min,
  max,
}) => {
  const isFileInput = type === 'file';

  return (
    <Box>
      <Typography variant="subtitle1" fontWeight={550} component="label" htmlFor={name} mb="4px">
        {title} {required ? <ReqField /> : <br />}
      </Typography>
      {isFileInput ? (
        <CustomTextField
          id={name}
          variant="outlined"
          type={type}
          fullWidth
          onChange={handleChange}
          name={name}
          inputProps={{
            accept: 'image/*',
          }}
        />
      ) : (
        <TextField
          id={name}
          variant="outlined"
          type={type}
          placeholder={placeholder || ''}
          fullWidth
          InputLabelProps={min || max ? { shrink: true } : {}}
          InputProps={
            min || max
              ? {
                  inputProps: {
                    min: min || undefined,
                    max: max || undefined,
                  },
                }
              : {}
          }
          value={value}
          onChange={handleChange}
          name={name}
          error={!!fieldValidation}
          helperText={fieldValidation && `${title} is required`}
          disabled={disabled}
        />
      )}
    </Box>
  );
};
export default TextInputs;
