import { baseUrl, headers, jwtHeaders, logoutUtil,jwtFormDataHeaders } from '../common/data';

export const signup = async (data) => {
  const response = await fetch(`${baseUrl}/admin/signup`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(data),
    redirect: 'follow',
  });

  if (response?.status === 401) {
    logoutUtil();
  }
  const resData = await response?.json();

  return resData;
};

export const login = async (data) => {
  const response = await fetch(`${baseUrl}/api/owner/loginowner`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(data),
    redirect: 'follow',
  });
  if (response?.status === 401) {
    logoutUtil();
  }
  const resData = await response?.json();

  return resData;
};

export const forgotpassowrd = async (data) => {
  const response = await fetch(`${baseUrl}/api/owner/forgotpassword`, {
    method: 'POST',
    headers,
    body: JSON.stringify(data),
    redirect: 'follow',
  });
  const resData = await response?.json();

  return resData;
};

export const fetchAOwner = async (id) => {
  const response = await fetch(`${baseUrl}/api/owner/getaowner/${id}`, {
    method: 'GET',
    headers: jwtHeaders(),
  });
  const resData = await response?.json();
  return resData;
};

export const getAllUsers = async () => {
  const response = await fetch(`${baseUrl}/admin/user/all`, {
    headers: jwtHeaders(),
  });
  const resData = await response?.json();

  return resData;
};

export const updateOwnerSettings = async (id, data) => {
  const response = await fetch(`${baseUrl}/api/owner/updatenumbermasking/${id}`, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers:jwtHeaders()
  });
  const resData = await response.json();
  return resData;
};

export const updateOwnerProfile = async (ownerId, data) => {
  const response = await fetch(`${baseUrl}/api/owner/updateowner/${ownerId}`, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers:jwtHeaders()
  });
  const resData = await response.json();
  return resData;
};

export const updateOwnerProfileImage = async (ownerId, data) => {
  const response = await fetch(`${baseUrl}/api/owner/updatelogo/${ownerId}`, {
    method: "PATCH",
    body: data,
    headers:jwtFormDataHeaders()
  });
  const resData = await response.json();
  return resData;
};
