import React, { useEffect, useState } from 'react';
import './analytics.css';
import PageContainer from 'src/components/container/PageContainer';
import { Button } from '@mui/material';
import DashboardCard2 from 'src/components/shared/DashboardCard2';
import { useMain } from 'src/views/hooks/useMain';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
// import GstBillsPage from '../../Settings/GstbillsData/GstBillsPage';
import { baseUrl, jwtHeaders } from 'src/context/common/data';
// import PackageBillData from 'src/views/Settings/GstbillsData/PackageBillData';
// import MembershipBillData from 'src/views/Settings/GstbillsData/MembershipBillData';
import SalesReport from './SalesReport';
import PackageBillData from 'src/views/Settings/GstBillsData/PackageBillData';
import MembershipBillData from 'src/views/Settings/GstBillsData/MembershipBillData';
import GstBillsPage from 'src/views/Settings/GstBillsData/GstBillsPage';

function Analytics() {
  const { activeBranchId, fetchBillAnalytics, staffCollection, fetchMemberAnalytixs } = useMain();

  const [loading, setLoading] = useState(true);
  const [packagedata, setPackagedata] = useState(null);
  const [memberdata, setMemberdata] = useState(null);
  const [activeComponent, setActiveComponent] = useState('SalesReport'); // Default to 'SalesReport'

  const handleButtonClick = (component) => {
    setActiveComponent(component);
  };

  const getData2 = async (activeBranchId) => {
    try {
      setLoading(true);
      const result = await fetch(
        `${baseUrl}/api/billing/packagebilloverview?branchId=${activeBranchId}`,
        {
          method: 'GET',
          headers: jwtHeaders(),
          redirect: 'follow',
        },
      );
      const res = await result.json();
      if (res.statusCode === 200) {
        setPackagedata(res?.data?.packageBills);
        setMemberdata(res?.data?.membershipBills);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (activeBranchId) {
      getData2(activeBranchId);
    }
  }, [activeBranchId]);

  return (
    // <>
    //   <PageContainer title="Sales Analytics" description="This is Sales Analytics">
    //     <DashboardCard2 title="Sales Analytics">
    //       {loading ? (
    //         <ShowLoader />
    //       ) : (
    //         <div>
    //           <div className="inline-buttons" style={{ display: 'flex', gap: '10px' }}>
    //             <Button
    //               variant="contained"
    //               color="primary"
    //               onClick={() => handleButtonClick('SalesReport')}
    //               sx={{ background: '#6565D1', padding: '8px 10px' }}
    //             >
    //               View Sales Report
    //             </Button>
    //             <Button
    //               variant="contained"
    //               color="primary"
    //               onClick={() => handleButtonClick('GstBillsPage')}
    //               sx={{ background: '#6565D1', padding: '8px 10px' }}
    //             >
    //               GST Bills
    //             </Button>
    //             <Button
    //               variant="contained"
    //               color="primary"
    //               onClick={() => handleButtonClick('PackageBillData')}
    //               sx={{ background: '#6565D1', padding: '8px 10px' }}
    //             >
    //               Package Bills
    //             </Button>
    //             <Button
    //               variant="contained"
    //               color="primary"
    //               onClick={() => handleButtonClick('MembershipBillData')}
    //               sx={{ background: '#6565D1', padding: '8px 10px' }}
    //             >
    //               Membership Bills
    //             </Button>
    //           </div>

    //           <div className="content">
    //             {activeComponent === 'SalesReport' && <SalesReport />}
    //             {activeComponent === 'GstBillsPage' && <GstBillsPage/>}
    //             {activeComponent === 'PackageBillData' && <PackageBillData packageData={packagedata} />}
    //             {activeComponent === 'MembershipBillData' && <MembershipBillData membershipData={memberdata} />}
    //           </div>
    //         </div>
    //       )}
    //     </DashboardCard2>
    //   </PageContainer>
    // </>
    <>
      <PageContainer title="Sales Analytics" description="This is Sales Analytics">
        <DashboardCard2 title="Sales Analytics">
          {loading ? (
            <ShowLoader />
          ) : (
            <div>
              <div className="inline-buttons" style={{ display: 'flex', gap: '10px' }}>
                {[
                  { label: 'Sales Report', component: 'SalesReport' },
                  { label: 'GST Bills', component: 'GstBillsPage' },
                  // { label: 'Package Bills', component: 'PackageBillData' },
                  // { label: 'Membership Bills', component: 'MembershipBillData' },
                ].map(({ label, component }) => (
                  <Button
                    key={component}
                    className={`button-64 ${activeComponent === component ? 'button-64-active' : ''}`}
                    onClick={() => handleButtonClick(component)}
                  >
                    <span className="text">{label}</span>
                  </Button>
                ))}
              </div>

              <div className="content">
                {activeComponent === 'SalesReport' && <SalesReport />}
                {activeComponent === 'GstBillsPage' && <GstBillsPage />}
                {/* {activeComponent === 'PackageBillData' && <PackageBillData packageData={packagedata} />}
                {activeComponent === 'MembershipBillData' && <MembershipBillData membershipData={memberdata} />} */}
              </div>
            </div>
          )}
        </DashboardCard2>
      </PageContainer>
    </>
  );
}

export default Analytics;