import { Grid, Typography } from '@mui/material';

import React, { useState } from 'react';

const ServiceMembershipDetail = ({ data }) => {

  return (
    <Grid container xs={12}>
      <Grid container xs={12} spacing={4}>
        <Grid item xs={12} display={'flex'} alignItems={'center'} spacing={3}>
          <Grid item xs={6}>
            <Typography variant="body1" style={{ fontSize: '16px' }}>
              Start Date <strong>{data.startDate}</strong>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" style={{ fontSize: '16px' }}>
              End Date: <strong>{data.endDate}</strong>
            </Typography>
          </Grid>
        </Grid>
        {/* <Grid item xs={12} display={'flex'} alignItems={'center'} spacing={3}>
          <Grid item xs={6}>
            <Typography variant="body1" style={{ fontSize: '16px' }}>
              Membership duration: <strong>{data.duration} month</strong>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" style={{ fontSize: '16px' }}>
              Reward Points: <strong>{data.rewardPointsOnPurchase}</strong>
            </Typography>
          </Grid>
        </Grid> */}
      </Grid>

      <Grid item xs={12}>
        <table className="table-container mt-3">
          <thead className="table-thead">
            <tr>
              {['Service Name', 'Category', 'Count'].map((h, index) => (
                <th key={index} style={{ width: '100%' }}>
                  {h}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="table-tbody">
            {data?.services?.map((item, index) => (
              <tr key={index}>
                <td style={{ width: '100%' }}>{item?.serviceName}</td>
                <td style={{ width: '100%' }}>{item?.category}</td>
                <td style={{ width: '100%' }}>{item?.count}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Grid>
    </Grid>
  );
};

export default ServiceMembershipDetail;
