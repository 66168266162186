import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, InputLabel, Grid as MuiGrid } from '@mui/material';
import TableCells from 'src/components/Tables/TableCells';
import { useMain } from '../hooks/useMain';
import { useNavigate } from 'react-router';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard2 from 'src/components/shared/DashboardCard2';
import { Button } from 'reactstrap';

export const TrimData = (data) => {
  if (data?.length < 25) return data;
  return data?.substring(0, 15) + '...' + data?.substring(data?.length - 10);
};

export const maskData = (value, isMasked) => {
  if (isMasked) {
    const maskLength = Math.ceil(value.length / 2);
    return '*'.repeat(maskLength) + value.substring(maskLength);
  } else {
    return value;
  }
};

const AllDraftBills = () => {
  const [draftBilldata, setDraftBillData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [loadingBillId, setLoadingBillId] = useState(null);

  const { activeBranchId, fetchDraftBill, fetchSingleBill, branchData } = useMain();
  const navigate = useNavigate();

  // Fetch all draft bills
  const getDraftBillsData = async () => {
    try {
      setShowLoader(true);
      const res = await fetchDraftBill(activeBranchId);

      if (res?.statusCode === 200 || res?.status === 'success') {
        setDraftBillData(res?.data || []);
      } else {
        setDraftBillData([]);
      }
    } catch (error) {
      setDraftBillData([]);
    } finally {
      setShowLoader(false);
    }
  };


  useEffect(() => {
    if (activeBranchId) {
      getDraftBillsData();
    }
  }, [activeBranchId]);

  // Fetch single draft bill when draftBilldata is populated
  // useEffect(() => {
  //   if (draftBilldata.length > 0) {
  //     const firstBillId = draftBilldata[0]?._id;
  //     if (firstBillId) {
  //       getSingleDraftBill(firstBillId);
  //     }
  //   }
  // }, [draftBilldata]); // Run only when draftBilldata changes

  console.log(draftBilldata, 'draftBilldata');


  // Function to handle row click
  const handleRowClick = async (billData) => {
    if (billData && billData._id) {
      try {
        setLoadingBillId(billData._id);
        const queryParams = `?branchId=${activeBranchId}&billId=${billData._id}&draftBillId=${billData?.draftBillId}`;
        const res = await fetchSingleBill(queryParams);

        if (res?.statusCode === 200 || res?.status === 'success') {
          const singleBill = res?.data || [];

          // Navigate to AddBilling with the single bill data
          navigate('/addbilling', { state: { draftBill: singleBill } });
        } else {
          console.error('Failed to fetch single bill data');
        }
      } catch (error) {
        console.error('Error fetching single draft bill:', error);
      } finally {
        setLoadingBillId(null); // Reset loader after action
      }
    } else {
      console.error('Invalid bill data:', billData);
    }
  };


  return (
    <PageContainer title="All Draft Bills" description="this contains all products">
      <DashboardCard2 title="All Draft Bills">
        <MuiGrid container spacing={2} alignItems="center">
          <MuiGrid container justifyContent="space-around"></MuiGrid>
        </MuiGrid>

        {showLoader ? (
          <ShowLoader />
        ) : draftBilldata.length > 0 ? (
          <Box>
            <MuiGrid container spacing={3}>
              <MuiGrid item xs={12}>
                <Box sx={{ overflow: 'auto', width: { xs: '450px', sm: 'auto' } }}>
                  <table className="table-container mt-3">
                    <thead className="table-thead">
                      <tr>
                        {['Date of Bill', 'Client Name', 'Contact', 'Gender', 'Actions'].map((h, index) => (
                          <th key={index} style={{ width: '100%' }}>
                            {h}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="table-tbody">
                      {draftBilldata.map((item) => {
                        const originalDate = item?.dateOfBilling;

                        // Convert "YYYY-MM-DD" to "DD-MM-YYYY"
                        let formattedBillDate = '';
                        if (originalDate) {
                          const [year, month, day] = originalDate.split('-');
                          formattedBillDate = `${day}-${month}-${year}`;
                        }

                        return (
                          <tr>
                            <td style={{ width: '100%' }}>{formattedBillDate}</td>
                            <td style={{ width: '100%' }}>{item?.clientName}</td>
                            <td style={{ width: '100%' }}>
                              {maskData(item?.clientNumber, branchData?.isMasked)}
                            </td>
                            <td style={{ width: '100%' }}>{item?.gender}</td>
                            <td style={{ width: '100%' }}>
                              <Button
                                style={{
                                  backgroundColor: '#5d87ff',
                                  color: 'white',
                                  fontSize: '14px',
                                  fontWeight: 'bold',
                                  width: '120px',
                                  height: '40px',
                                  '&:hover': {
                                    backgroundColor: '#6174DD',
                                  },
                                }}
                                onClick={() => handleRowClick(item)}
                              >
                                {loadingBillId === item._id ? (
                                  <CircularProgress size={24} style={{ color: '#03C4A9' }} />
                                ) : (
                                  'Add To Bill'
                                )}
                              </Button>


                            </td>

                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Box>
              </MuiGrid>
            </MuiGrid>
          </Box>
        ) : (
          'No data found'
        )}
      </DashboardCard2>
    </PageContainer>
  );
};

export default AllDraftBills;

