import React, { useEffect, useState } from 'react'
import PageContainer from 'src/components/container/PageContainer'
import DashboardCard from 'src/components/shared/DashboardCard'
import Orders from './Orders'
import { Box, Grid } from '@mui/material'
import { useMain } from '../hooks/useMain'
import ShowLoader from 'src/components/CustomComponents/ShowLoader'

const AllOrders = () => {
    const [orders, setOrders] = useState([]);
    const [showLoader, setShowLoader]=useState(false);

    const { getAllOrders } = useMain();

    const getOrders=async()=>{
        setShowLoader(true);
        const res=await getAllOrders();
        setOrders(res?.data?.orders);
        setShowLoader(false);
    }

    useEffect(()=>{
        getOrders();

        return ()=>{}
    },[]);

    return (
        <PageContainer title="All Orders" description="this contains all Orders">
            <DashboardCard title="All Orders">
                <Box>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={12}>
                            <Orders orders={orders} />
                        </Grid>
                    </Grid>
                </Box>
                {showLoader && <ShowLoader value={showLoader} />}
            </DashboardCard>
        </PageContainer>
    )
}

export default AllOrders
