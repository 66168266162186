import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import TextInputs from './TextInputs';

const DateRangeInputs = ({
  isOpen,
  onClose,
  onDateRangeSelected,
  selectedStartDate,
  selectedEndDate,
}) => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [error, setError] = useState('');

  const validateDates = () => {
    if (!startDate || !endDate) {
      setError('Both start date and end date are required.');
      return;
    }
    if (startDate > endDate) {
      setError('Start date cannot be after end date.');
      return;
    }
    setError('');
    onDateRangeSelected(startDate, endDate);
  };

  const handleOnClose = () => {
    setStartDate(selectedStartDate);
    setEndDate(selectedEndDate);
    setError('');
    onClose();
  };

  return (
    <Dialog
    open={isOpen}
    onClose={handleOnClose}
    maxWidth="md"
    fullWidth={false}
    PaperProps={{ style: { width: 'auto', maxWidth: '100%' } }}
  >
    <DialogContent style={{ padding: '16px 24px' }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextInputs
            title={'Start Date'}
            name={'startDate'}
            value={startDate}
            handleChange={(e) => setStartDate(e.target.value)}
            type={'date'}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInputs
            title={'End Date'}
            name={'endDate'}
            value={endDate}
            handleChange={(e) => setEndDate(e.target.value)}
            type={'date'}
          />
        </Grid>
      </Grid>
      {error && <div style={{ color: 'red', marginTop: '20px' }}>{error}</div>}
    </DialogContent>
    <DialogActions style={{ justifyContent: 'space-between', padding: '16px 24px' }}>
      <Button variant="contained" color="primary" onClick={handleOnClose}>
        Cancel
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={validateDates}
        disabled={!startDate || !endDate}
      >
        Apply
      </Button>
    </DialogActions>
  </Dialog>
  );
};

export default DateRangeInputs;
