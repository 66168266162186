import {
  ADD_PRODUCT_ROW,
  DELETE_PRODUCT_ROW,
  HANDLE_CLIENT_SUGGESTED_CLICK,
  HANDLE_CLIENT_INPUT_CHANGE,
  HANDLE_PRODUCT_INPUT_CHANGE,
  HANDLE_PRODUCT_SUGGESTED_CLICK,
  HANDLE_SERVICE_INPUT_CHANGE,
  HANDLE_SERVICE_SUGGESTED_CLICK,
  ADD_SERVICE_ROW,
  DELETE_SERVICE_ROW,
  HANDLE_DISCOUNT_INPUT_CHANGE,
  HANDLE_TAXE_INPUT_CHANGE,
  HANDLE_AMOUNTPAID_INPUT_CHANGE,
  ADD_AMOUNTPAID_ROW,
  DELETE_AMOUNTPAID_ROW,
  REST_BILL_STATE,
  HANDLE_COUPON_INPUT_CHANGE,
  HANDLE_CLIENT_NUMBER_INPUT_CHANGE,
  HANDLE_MEMBERSHIP_INPUT_CHANGE,
  HANDLE_PACKAGE_INPUT_CHANGE,
  HANDLE_ADD_BILL_BY_APPOINTMENTID,
  HANDLE_REWARD_POINT_CHANGE,
  SET_DRAFT_BILL,
} from './billConstent';

// Initial state for the bill
export const INITIAL_STATE = {
  dateOfBilling: getCurrentDateInFormat(),
  timeOfBilling: getCurrentTime(),
  clientNumber: '',
  clientName: '',
  gender: '',
  products: [],
  services: [],
  subTotal: 0,
  useMembership: false,
  usePackage: false,
  discount: {
    discountType: '%',
    discount: '',
  },
  taxes: {
    taxType: 'Inclusive',
    SGST: 0,
    PGST: 0,
  },
  totalAmount: 0,
  useRewardPoint: false,
  amountPayable: 0,
  coupon: {
    couponId: null,
    couponCode: '',
    discountAmount: 0,
  },
  amountPaid: [
    {
      paymentType: 'Cash',
      amount: '',
    },
  ],

  paidDues: 0,
  notes: '',
};

// Reducer function for handling bill actions
export const billReducer = (state, action) => {
  switch (action.type) {
    case HANDLE_ADD_BILL_BY_APPOINTMENTID:
      return billByAppointmentId(state, action.payload);

    case HANDLE_CLIENT_INPUT_CHANGE:
      return clientInputChange(state, action.payload);

    case HANDLE_CLIENT_SUGGESTED_CLICK:
      return clientSuggestClick(state, action.payload);

    case HANDLE_CLIENT_NUMBER_INPUT_CHANGE:
      return clientNumberInputChange(state, action.payload);

    case HANDLE_PRODUCT_INPUT_CHANGE:
      return productInputChange(state, action.payload);

    case HANDLE_PRODUCT_SUGGESTED_CLICK:
      return productSggestedClick(state, action.payload);

    case ADD_PRODUCT_ROW:
      return addProductRow(state);

    case DELETE_PRODUCT_ROW:
      return deleteProductRow(state, action.payload);

    case HANDLE_SERVICE_INPUT_CHANGE:
      return serviceInputChange(state, action.payload);

    case HANDLE_SERVICE_SUGGESTED_CLICK:
      return serviceSuggestedClick(state, action.payload);

    case ADD_SERVICE_ROW:
      return addServiceRow(state);

    case DELETE_SERVICE_ROW:
      return deleteServiceRow(state, action.payload);

    case HANDLE_DISCOUNT_INPUT_CHANGE:
      return discountInputChange(state, action.payload);

    case HANDLE_TAXE_INPUT_CHANGE:
      return taxeInputChange(state, action.payload);

    case HANDLE_COUPON_INPUT_CHANGE:
      return couponInputChange(state, action.payload);

    case HANDLE_MEMBERSHIP_INPUT_CHANGE:
      return membershipInputChange(state, action.payload);

    case HANDLE_PACKAGE_INPUT_CHANGE:
      return packageInputChange(state, action.payload);

    case HANDLE_AMOUNTPAID_INPUT_CHANGE:
      return amountPaidInputChange(state, action.payload);

    case HANDLE_REWARD_POINT_CHANGE:
      return handleRewardPointToggle(state, action.payload);

    case ADD_AMOUNTPAID_ROW:
      return addAmountPaidRow(state);

    case DELETE_AMOUNTPAID_ROW:
      return deleteAmountPaidRow(state, action.payload);

      case SET_DRAFT_BILL:
        return handleDraftBill(state, action.payload);

    case REST_BILL_STATE:
      return {
        ...INITIAL_STATE,
        dateOfBilling: getCurrentDateInFormat(),
        timeOfBilling: getCurrentTime(),
        amountPaid: [
          {
            paymentType: 'Cash',
            amount: '',
          },
        ],
      };

    default:
      return state;
  }
};

function billByAppointmentId(state, payload) {
  const { clientName, clientNumber, gender, serviceSelected, _id } = payload;
  const updatedServices = serviceSelected.map((item) => ({
    serviceName: item.service.serviceName,
    service: item.service._id, // Update this based on your data structure
    category: item.service.category, // Update this based on your data structure
    quantity: 1,
    discount: 0,
    discountType: '%',
    serviceProvider: item.serviceProvider?._id || '', // Update this based on your data structure
    serviceProviderName: item.serviceProvider?.name || '', // Update this based on your data structure
    startDate: '',
    endDate: '',
    price: item.service.price, // Update this based on your data structure
    amount: item.service.price,
  }));

  const updatedSubTotal = calculateSubTotal([...state.products, ...updatedServices]);
  const { taxType, SGST } = state.taxes;
  const { SGSTAmount } = calculateTaxes(updatedSubTotal, taxType, SGST);
  let updatedTotalAmount = updatedSubTotal + SGSTAmount;
  if (state.discount.discountType === 'INR') {
    updatedTotalAmount = updatedTotalAmount - state.discount.discount;
  } else if (state.discount.discountType === '%') {
    updatedTotalAmount = updatedTotalAmount - (updatedTotalAmount * state.discount.discount) / 100;
  }
  let updatedAmountPayable = updatedTotalAmount - state.coupon.discountAmount;
  return {
    ...state,
    appointmentId: _id,
    clientName,
    clientNumber,
    gender,
    services: updatedServices,
    subTotal: Math.round(updatedSubTotal),
    totalAmount: Math.round(updatedTotalAmount),
    amountPayable: Math.round(updatedAmountPayable),
    paidDues: Math.round(
      updatedAmountPayable -
      state.amountPaid.reduce((acc, payment) => acc + Number(payment.amount), 0),
    ),
    useMembership: false,
    coupon: { couponId: null, couponCode: '', discountAmount: 0 },
  };
}

// Function to populate draft bill data into the state
function handleDraftBill(state, payload) {
  const { draftBill } = payload;
  console.log(draftBill, "draftBillREDUCER");

  const {
    clientName,
    clientNumber,
    gender,
    services,
    products,
    subTotal,
    totalAmount,
    amountPayable,
    coupon,
    discount,
    taxes,
    dateOfBilling,
    timeOfBilling,
    paidDues,
    amountPaid,
    notes,
    useMembership,
    useRewardPoint,
  } = draftBill;
  console.log(clientName,"clientName")
  console.log(clientNumber,"clientNumber")
  console.log(services,"services")
  const updatedServices = services?.map((service) => ({
    serviceName: service.serviceName,
    service: service.service, // Assuming `service` is the ID here
    category: service.category, // Assuming category exists in service
    quantity: service.quantity || 1, // Default quantity
    discount: service.discount || 0, // Default discount
    discountType: service.discountType || '%', // Default discountType
    serviceProvider: service.serviceProvider || '', // Assuming serviceProvider ID
    serviceProviderName: service.serviceProviderName || '', // Assuming serviceProviderName
    startDate: service.startDate || '',
    endDate: service.endDate || '',
    price: service.price,
    amount: service.amount,
  }));

  return {
    ...state,
    clientName: clientName,
    clientNumber: clientNumber || state.clientNumber,
    gender: gender || state.gender,
    dateOfBilling: dateOfBilling || state.dateOfBilling,
    timeOfBilling: timeOfBilling || state.timeOfBilling,
    products: products || state.products,
    services: updatedServices,
    subTotal: subTotal || state.subTotal,
    totalAmount: totalAmount || state.totalAmount,
    amountPayable: amountPayable || state.amountPayable,
    coupon: {
      couponId: coupon?.couponId || state.coupon.couponId,
      couponCode: coupon?.couponCode || state.coupon.couponCode,
      discountAmount: coupon?.discountAmount || state.coupon.discountAmount,
    },
    discount: {
      discountType: discount?.discountType || state.discount.discountType,
      discount: discount?.discount || state.discount.discount,
    },
    taxes: {
      taxType: taxes?.taxType || state.taxes.taxType,
      SGST: taxes?.SGST || state.taxes.SGST,
      PGST: taxes?.PGST || state.taxes.PGST,
    },
    paidDues: paidDues || state.paidDues,
    amountPaid: amountPaid || state.amountPaid,
    notes: notes || state.notes,
    useMembership: typeof useMembership !== 'undefined' ? useMembership : state.useMembership,
    useRewardPoint: typeof useRewardPoint !== 'undefined' ? useRewardPoint : state.useRewardPoint,
  };
}


function clientSuggestClick(state, payload) {
  const { clientName, clientNumber, gender } = payload;
  return {
    ...state,
    clientName,
    clientNumber,
    gender,
  };
}

function clientNumberInputChange(state, payload) {
  const { paidDues } = payload;
  return {
    ...state,
  };
}

function clientInputChange(state, payload) {
  const { name, value } = payload;
  // if (name === 'clientNumber')
  //   return {
  //     ...INITIAL_STATE,
  //     dateOfBilling:state.dateOfBilling,
  //     timeOfBilling:state.timeOfBilling,
  //     amountPaid: [
  //       {
  //         paymentType: 'Cash',
  //         amount: 0,
  //       },
  //     ],
  //     [name]: value,
  //   };
  return { ...state, [name]: value };
}

function getDefaultProduct() {
  return {
    productName: '',
    product: '', // Product Id
    quantity: 0,
    providerStaff: null, // Provider staff Id
    discount: 0,
    mrp: 0,
    discountType: '%',
    price: 0, // Base price
    amount: 0, // Amount after calculation of quantity and discount
  };
}

function addProductRow(state) {
  const newProduct = getDefaultProduct();
  const addNewProductRow = [...state.products, newProduct];
  return {
    ...state,
    products: addNewProductRow,
  };
}

function productInputChange(state, payload) {
  const { name, value, index, item } = payload;
  let updatedProducts = [...state.products];
  let updatedServices = [...state.services];

  // Update the product with the new value
  updatedProducts[index][name] = value;

  // Recalculate amount when price or quantity changes
  if (name === 'price') {
    const updatedProductAmount = updatedProducts[index].quantity * value;
    updatedProducts[index].amount = updatedProductAmount;
  }

  if (name === 'quantity') {
    const updatedProductAmount = value * updatedProducts[index].price;
    updatedProducts[index].amount = updatedProductAmount;
  }

  // Preserve membership services and recalculate non-membership services
  const { membershipServices, updatedNonMembershipServices } = handleMembershipServices(
    state,
    state.services,
    item
  );

  // Update subtotal, taxes, and amounts only if price or quantity changes
  if (['price', 'quantity'].includes(name)) {
    const updatedSubTotal = calculateSubTotal([...updatedNonMembershipServices, ...updatedProducts]);

    const { taxType, SGST } = state.taxes;
    const { SGSTAmount } = calculateTaxes(updatedSubTotal, taxType, SGST);

    const updatedTotalAmount = updatedSubTotal + SGSTAmount;

    return {
      ...state,
      products: updatedProducts,
      services: [...membershipServices, ...updatedNonMembershipServices],  // Preserve membership services
      subTotal: Math.round(updatedSubTotal),               // Update subtotal
      totalAmount: Math.round(updatedTotalAmount),         // Update total amount
      amountPayable: Math.round(updatedTotalAmount),       // Update amount payable
      paidDues: Math.round(
        updatedTotalAmount - state.amountPaid.reduce((acc, payment) => acc + Number(payment.amount), 0)
      ),
      useMembership: state.useMembership,                  // Preserve membership flag
      coupon: { couponId: null, couponCode: '', discountAmount: 0 },
    };
  }

  // If other fields change (not price or quantity), return state unchanged
  return {
    ...state,
    products: updatedProducts,
    services: updatedServices,
  };
}


//old code
// function productInputChange(state, payload) {
//   const { name, value, index } = payload;
//   let updatedProducts = [...state.products];
//   updatedProducts[index][name] = value;

//   const updatedDiscount = { ...state.discount };
//   const updatedServices = applyDiscountToItems(state.services, updatedProducts, updatedDiscount);
//   updatedProducts = applyDiscountToItems(updatedProducts, state.services, updatedDiscount);
//   const updatedSubTotal = calculateSubTotal([...updatedServices, ...updatedProducts]);
//   const { taxType, SGST } = state.taxes;
//   const { SGSTAmount } = calculateTaxes(updatedSubTotal, taxType, SGST);
//   let updatedTotalAmount = updatedSubTotal + SGSTAmount;
//   return {
//     ...state,
//     products: updatedProducts,
//     services: updatedServices,
//     discount: updatedDiscount,
//     subTotal: Math.round(updatedSubTotal),
//     totalAmount: Math.round(updatedTotalAmount),
//     amountPayable: Math.round(updatedTotalAmount),
//     paidDues: Math.round(
//       updatedTotalAmount -
//       state.amountPaid.reduce((acc, payment) => acc + Number(payment.amount), 0),
//     ),
//     useMembership: false,
//     coupon: { couponId: null, couponCode: '', discountAmount: 0 },
//   };
// }

function productSggestedClick(state, payload) {
  const { product, index, item } = payload;

  if (index === null || index === undefined) return state;

  let updatedProducts = [...state.products];
  updatedProducts[index] = {
    product: product._id,
    productName: product.productName,
    quantity: 1,
    providerStaff: null,
    discount: 0,
    mrp: product.mrp,
    discountType: '%',
    price: product.sellPrice,
    amount: product.sellPrice,
  };

  // Preserve membership services
  const { membershipServices, updatedNonMembershipServices } = handleMembershipServices(
    state,
    state.services,
    item
  );

  const updatedDiscount = { ...state.discount };

  // Apply discounts to non-membership services
  const updatedServices = applyDiscountToItems(updatedNonMembershipServices, updatedProducts, updatedDiscount);

  // Apply discounts to products
  updatedProducts = applyDiscountToItems(updatedProducts, updatedServices, updatedDiscount);

  // Combine membership services with the updated services
  const finalServices = [...membershipServices, ...updatedServices];

  // Calculate subtotal and total amounts
  const updatedSubTotal = calculateSubTotal([...finalServices, ...updatedProducts]);
  const { taxType, SGST } = state.taxes;
  const { SGSTAmount } = calculateTaxes(updatedSubTotal, taxType, SGST);
  let updatedTotalAmount = updatedSubTotal + SGSTAmount;

  return {
    ...state,
    products: updatedProducts,
    services: finalServices,
    discount: updatedDiscount,
    subTotal: Math.round(updatedSubTotal),
    totalAmount: Math.round(updatedTotalAmount),
    amountPayable: Math.round(updatedTotalAmount),
    paidDues: Math.round(
      updatedTotalAmount - state.amountPaid.reduce((acc, payment) => acc + Number(payment.amount), 0)
    ),
    useMembership: state.useMembership,
    coupon: { couponId: null, couponCode: '', discountAmount: 0 },
  };
}

//old code
// function productSggestedClick(state, payload) {
//   const { product, index } = payload;
//   if (index === null) return state;
//   let updatedProducts = state.products.slice(); // Create a copy of products
//   updatedProducts[index] = {
//     product: product._id, // Update this based on your data structure
//     productName: product.productName, // Update this based on your data structure
//     quantity: 1,
//     providerStaff: null,
//     discount: 0,
//     mrp: product.mrp, // Update this based on your data structure
//     discountType: '%',
//     price: product.sellPrice, // Update this based on your data structure
//     amount: product.sellPrice,
//   };

//   const updatedDiscount = { ...state.discount };
//   const updatedServices = applyDiscountToItems(state.services, updatedProducts, updatedDiscount);
//   updatedProducts = applyDiscountToItems(updatedProducts, state.services, updatedDiscount);
//   const updatedSubTotal = calculateSubTotal([...updatedServices, ...updatedProducts]);

//   const { taxType, SGST } = state.taxes;
//   const { SGSTAmount } = calculateTaxes(updatedSubTotal, taxType, SGST);
//   let updatedTotalAmount = updatedSubTotal + SGSTAmount;

//   return {
//     ...state,
//     products: updatedProducts,
//     services: updatedServices,
//     discount: updatedDiscount,
//     subTotal: Math.round(updatedSubTotal),
//     totalAmount: Math.round(updatedTotalAmount),
//     amountPayable: Math.round(updatedTotalAmount),
//     paidDues: Math.round(
//       updatedTotalAmount -
//       state.amountPaid.reduce((acc, payment) => acc + Number(payment.amount), 0),
//     ),
//     useMembership: false,
//     coupon: { couponId: null, couponCode: '', discountAmount: 0 },
//   };
// }

function deleteProductRow(state, payload) {
  const { index } = payload;
  let updatedProducts = [...state.products.slice(0, index), ...state.products.slice(index + 1)];

  const updatedDiscount = { ...state.discount };

  // Handle membership services by filtering out
  const { membershipServices, updatedNonMembershipServices } = handleMembershipServices(
    state,
    state.services,
    null
  );

  // Apply discounts
  const updatedProductsWithDiscounts = applyDiscountToItems(updatedProducts, updatedNonMembershipServices, updatedDiscount);
  const finalServices = [...membershipServices, ...updatedNonMembershipServices];

  const updatedSubTotal = calculateSubTotal([...finalServices, ...updatedProductsWithDiscounts]);

  const { taxType, SGST } = state.taxes;
  const { SGSTAmount } = calculateTaxes(updatedSubTotal, taxType, SGST);
  let updatedTotalAmount = updatedSubTotal + SGSTAmount;

  return {
    ...state,
    products: updatedProductsWithDiscounts,
    services: finalServices,
    discount: updatedDiscount,
    subTotal: Math.round(updatedSubTotal),
    totalAmount: Math.round(updatedTotalAmount),
    amountPayable: Math.round(updatedTotalAmount),
    paidDues: Math.round(
      updatedTotalAmount -
      state.amountPaid.reduce((acc, payment) => acc + Number(payment.amount), 0),
    ),
    useMembership: state.useMembership,
    coupon: { couponId: null, couponCode: '', discountAmount: 0 },
  };
}


//old code
// function deleteProductRow(state, payload) {
//   const { index } = payload;
//   let updatedProducts = [...state.products.slice(0, index), ...state.products.slice(index + 1)];

//   const updatedDiscount = { ...state.discount };
//   const updatedServices = applyDiscountToItems(state.services, updatedProducts, updatedDiscount);
//   updatedProducts = applyDiscountToItems(updatedProducts, state.services, updatedDiscount);
//   const updatedSubTotal = calculateSubTotal([...updatedServices, ...updatedProducts]);

//   const { taxType, SGST } = state.taxes;
//   const { SGSTAmount } = calculateTaxes(updatedSubTotal, taxType, SGST);
//   let updatedTotalAmount = updatedSubTotal + SGSTAmount;

//   return {
//     ...state,
//     products: updatedProducts,
//     services: updatedServices,
//     discount: updatedDiscount,
//     subTotal: Math.round(updatedSubTotal),
//     totalAmount: Math.round(updatedTotalAmount),
//     amountPayable: Math.round(updatedTotalAmount),
//     paidDues: Math.round(
//       updatedTotalAmount -
//       state.amountPaid.reduce((acc, payment) => acc + Number(payment.amount), 0),
//     ),
//     useMembership: false,
//     coupon: { couponId: null, couponCode: '', discountAmount: 0 },
//   };
// }


function getDefaultService() {
  return {
    serviceName: '',
    service: '', // serviceId
    category: '',
    quantity: 0,
    discount: 0,
    discountType: '%',
    serviceProvider: '', // service provider Id
    serviceProviderName: '',
    startDate: '',
    endDate: '',
    price: 0,
    amount: 0,
  };
}

function addServiceRow(state) {
  const newService = getDefaultService();
  const addNewServiceRow = [...state.services, newService];
  return {
    ...state,
    services: addNewServiceRow,
    usePackage: false,
  };
}

function serviceInputChange(state, payload) {
  const { name, value, index, item } = payload;
  let updatedServices = [...state.services];
  let updatedProducts = [...state.products]; // Preserve existing products

  // Update the value in the services array
  updatedServices[index][name] = value;

  // Recalculate amount for the service when quantity or price changes
  if (name === 'quantity' || name === 'price') {
    const isMembershipService = state.useMembership && 
      item?.services.some((membershipService) => membershipService.id === updatedServices[index].service);

    // Check if the membership service's count is zero
    const membershipServiceCount = item?.services.find((membershipService) => membershipService.id === updatedServices[index].service)?.count || 0;

    if (isMembershipService && membershipServiceCount > 0) {
      updatedServices[index].amount = 0; // Membership service amount should be 0 if the count is more than 0
    } else if (isMembershipService && membershipServiceCount === 0) {
      // If membership service count is 0, calculate the price at 100%
      const updatedAmount = updatedServices[index].quantity * updatedServices[index].price;
      updatedServices[index].amount = updatedAmount; // Full price calculation
    } else {
      const updatedAmount = updatedServices[index].quantity * updatedServices[index].price;
      updatedServices[index].amount = updatedAmount; // Regular non-membership price calculation
    }
  }

  // Recalculate subtotal, taxes, totalAmount, etc.
  if (['serviceProvider', 'serviceName', 'quantity', 'price'].includes(name)) {
    const updatedSubTotal = calculateSubTotal([...updatedServices, ...updatedProducts]); // Include both services and products in subtotal
    const { taxType, SGST } = state.taxes;
    const { SGSTAmount } = calculateTaxes(updatedSubTotal, taxType, SGST); // Recalculate taxes
    const updatedTotalAmount = updatedSubTotal + SGSTAmount;

    return {
      ...state,
      services: updatedServices,
      products: updatedProducts,
      subTotal: Math.round(updatedSubTotal),
      totalAmount: Math.round(updatedTotalAmount),
      amountPayable: Math.round(updatedTotalAmount),
      paidDues: Math.round(
        updatedTotalAmount -
        state.amountPaid.reduce((acc, payment) => acc + Number(payment.amount), 0)
      ),
      useMembership: state.useMembership,
    };
  }

  // Apply discounts only if necessary
  const updatedDiscount = { ...state.discount };
  updatedServices = applyDiscountToItems(updatedServices, updatedProducts, updatedDiscount);

  // Recalculate the new subtotal with discounts applied to both services and products
  const updatedSubTotal = calculateSubTotal([...updatedServices, ...updatedProducts]);

  // Recalculate taxes
  const { taxType, SGST } = state.taxes;
  const { SGSTAmount } = calculateTaxes(updatedSubTotal, taxType, SGST);
  const updatedTotalAmount = updatedSubTotal + SGSTAmount;

  return {
    ...state,
    services: updatedServices,
    products: updatedProducts,
    discount: updatedDiscount,
    subTotal: Math.round(updatedSubTotal),
    totalAmount: Math.round(updatedTotalAmount),
    amountPayable: Math.round(updatedTotalAmount),
    paidDues: Math.round(
      updatedTotalAmount -
      state.amountPaid.reduce((acc, payment) => acc + Number(payment.amount), 0)
    ),
    useMembership: state.useMembership,
    usePackage: state.usePackage,
    useRewardPoint: false,
    coupon: { couponId: null, couponCode: '', discountAmount: 0 },
  };
}


//old code
// function serviceInputChange(state, payload) {
//   const { name, value, index } = payload; // Destructure the payload
//   // console.log(value);
//   let updatedServices = [...state.services];
//   updatedServices[index][name] = value;
//   // console.log(updatedServices);

//   const updatedDiscount = { ...state.discount };
//   updatedServices = applyDiscountToItems(updatedServices, state.products, updatedDiscount);
//   const updatedProducts = applyDiscountToItems(state.products, updatedServices, updatedDiscount);
//   const updatedSubTotal = calculateSubTotal([...updatedServices, ...updatedProducts]);

//   const { taxType, SGST } = state.taxes;
//   const { SGSTAmount } = calculateTaxes(updatedSubTotal, taxType, SGST);
//   let updatedTotalAmount = updatedSubTotal + SGSTAmount;
//   // console.log(updatedServices);
//   // console.log(updatedTotalAmount);

//   return {
//     ...state,
//     products: updatedProducts,
//     services: updatedServices,
//     discount: updatedDiscount,
//     subTotal: Math.round(updatedSubTotal),
//     totalAmount: Math.round(updatedTotalAmount),
//     amountPayable: Math.round(updatedTotalAmount),
//     paidDues: Math.round(
//       updatedTotalAmount -
//       state.amountPaid.reduce((acc, payment) => acc + Number(payment.amount), 0),
//     ),
//     useMembership: false,
//     usePackage: false,
//     useRewardPoint:false,
//     coupon: { couponId: null, couponCode: '', discountAmount: 0 },
//   };
// }

function serviceSuggestedClick(state, payload) {
  const { service, index, item } = payload;

  if (index === null || index === undefined) return { ...state };

  let updatedServices = [...state.services];

  // Handle membership services
  const { membershipServices, updatedNonMembershipServices } = handleMembershipServices(
    state,
    updatedServices,
    item
  );
  console.log(membershipServices,'membershipServices')
  console.log(updatedNonMembershipServices,'updatedNonMembershipServices')
  // Handle non-membership services
  updatedServices = handleNonMembershipServices(updatedNonMembershipServices, service, index);

  // Filter out incomplete or placeholder services
  updatedServices = updatedServices.filter(
    (s) => s.service && s.serviceName && s.price > 0 && s.quantity > 0
  );

  // Re-combine membership services with updated services
  updatedServices = [...membershipServices, ...updatedServices];

  const updatedDiscount = { ...state.discount };

  updatedServices = applyDiscountToItems(updatedServices, membershipServices, updatedDiscount);

  // Apply discount to products
  const updatedProducts = applyDiscountToItems(state.products, membershipServices, updatedDiscount);

  // Calculate subtotal and total amounts
  const updatedSubTotal = calculateSubTotal([...updatedServices, ...updatedProducts]);

  const { taxType, SGST } = state.taxes;
  const { SGSTAmount } = calculateTaxes(updatedSubTotal, taxType, SGST);
  const updatedTotalAmount = updatedSubTotal + SGSTAmount;

  return {
    ...state,
    products: updatedProducts,
    services: updatedServices,
    discount: updatedDiscount,
    subTotal: Math.round(updatedSubTotal),
    totalAmount: Math.round(updatedTotalAmount),
    amountPayable: Math.round(updatedTotalAmount),
    paidDues: Math.round(
      updatedTotalAmount -
      state.amountPaid.reduce((acc, payment) => acc + Number(payment.amount), 0),
    ),
    useMembership: state.useMembership,
    usePackage: state.usePackage,
    useRewardPoint: false,
    coupon: { couponId: null, couponCode: '', discountAmount: 0 },
  };
}

//old code
// function serviceSuggestedClick(state, payload) {
//   const { service, index } = payload;
//   if (index === null) return state;
//   let updatedServices = [...state.services];
//   console.log(service);
//   updatedServices[index] = {
//     serviceName: service.serviceName,
//     service: service._id, // Update this based on your data structure
//     category: service.category, // Update this based on your data structure
//     quantity: 1,
//     tipAmount:0,
//     discount: 0,
//     discountType: '%',
//     serviceProvider: '', // Update this based on your data structure
//     startDate: '',
//     endDate: '',
//     price: service.price, // Update this based on your data structure
//     amount: service.price,
//   };

//   const updatedDiscount = { ...state.discount };
//   updatedServices = applyDiscountToItems(updatedServices, state.products, updatedDiscount);
//   const updatedProducts = applyDiscountToItems(state.products, updatedServices, updatedDiscount);
//   const updatedSubTotal = calculateSubTotal([...updatedServices, ...updatedProducts]);

//   const { taxType, SGST } = state.taxes;
//   const { SGSTAmount } = calculateTaxes(updatedSubTotal, taxType, SGST);
//   let updatedTotalAmount = updatedSubTotal + SGSTAmount;

//   return {
//     ...state,
//     products: updatedProducts,
//     services: updatedServices,
//     discount: updatedDiscount,
//     subTotal: Math.round(updatedSubTotal),
//     totalAmount: Math.round(updatedTotalAmount),
//     amountPayable: Math.round(updatedTotalAmount),
//     paidDues: Math.round(
//       updatedTotalAmount -
//       state.amountPaid.reduce((acc, payment) => acc + Number(payment.amount), 0),
//     ),
//     useMembership: false,
//     usePackage:  false,
//     useRewardPoint:false,
//     coupon: { couponId: null, couponCode: '', discountAmount: 0 },
//   };
// }

function handleNonMembershipServices(updatedServices, service, index) {
  console.log(updatedServices,"updatedServicesNONMEMBERSHIP");
  console.log(service,"serviceNONMEMBERSHIP")
  if (!updatedServices) {
    updatedServices = []; // Ensure updatedServices is always an array
  }

  if (index >= updatedServices.length) {
    updatedServices.push({
      serviceName: service.serviceName,
      service: service._id,
      category: service.category,
      quantity: 1,
      discount: 0,
      discountType: '%',
      serviceProvider: '',
      startDate: '',
      endDate: '',
      price: service.price,
      amount: service.price,
    });
  } else {
    updatedServices[index] = {
      ...updatedServices[index],
      serviceName: service.serviceName,
      service: service._id,
      category: service.category,
      quantity: 1,
      discount: 0,
      discountType: '%',
      serviceProvider: updatedServices[index]?.serviceProvider || '',
      startDate: updatedServices[index]?.startDate || '',
      endDate: updatedServices[index]?.endDate || '',
      price: service.price,
      amount: service.price,
    };
  }
console.log(updatedServices,"RETURNNONMEMBERSHIP")
  return updatedServices;
}

function handleMembershipServices(state, updatedServices, item) {
  let membershipServices = [];

  // Ensure item and item.services exist before performing operations
  if (state.useMembership && item?.services?.length > 0) {
    membershipServices = updatedServices.filter((s) =>
      item.services.some((membershipService) => membershipService.id === s.service)
    );

    updatedServices = updatedServices.filter(
      (s) => !item.services.some((membershipService) => membershipService.id === s.service)
    );
  }

  return { 
    membershipServices, 
    updatedNonMembershipServices: updatedServices 
  };
}


function deleteServiceRow(state, payload) {
  const { index } = payload;

  let updatedServices = [...state.services.slice(0, index), ...state.services.slice(index + 1)];

  const updatedDiscount = { ...state.discount };

  // Handle membership services
  const { membershipServices, updatedNonMembershipServices } = handleMembershipServices(
    state,
    updatedServices,
    null
  );

  // Apply discounts
  const updatedProducts = applyDiscountToItems(state.products, updatedNonMembershipServices, updatedDiscount);
  const finalServices = [...membershipServices, ...updatedNonMembershipServices];
  
  const updatedSubTotal = calculateSubTotal([...finalServices, ...updatedProducts]);
  const { taxType, SGST } = state.taxes;
  const { SGSTAmount } = calculateTaxes(updatedSubTotal, taxType, SGST);
  let updatedTotalAmount = updatedSubTotal + SGSTAmount;

  return {
    ...state,
    products: updatedProducts,
    services: finalServices,
    discount: updatedDiscount,
    subTotal: Math.round(updatedSubTotal),
    totalAmount: Math.round(updatedTotalAmount),
    amountPayable: Math.round(updatedTotalAmount),
    paidDues: Math.round(
      updatedTotalAmount -
      state.amountPaid.reduce((acc, payment) => acc + Number(payment.amount), 0),
    ),
    useMembership: state.useMembership,
    usePackage: false,
    coupon: { couponId: null, couponCode: '', discountAmount: 0 },
  };
}

//old code
// function deleteServiceRow(state, payload) {
//   const { index } = payload;

//   let updatedServices = [...state.services.slice(0, index), ...state.services.slice(index + 1)];

//   const updatedDiscount = { ...state.discount };
//   updatedServices = applyDiscountToItems(updatedServices, state.products, updatedDiscount);
//   const updatedProducts = applyDiscountToItems(state.products, updatedServices, updatedDiscount);
//   const updatedSubTotal = calculateSubTotal([...updatedServices, ...updatedProducts]);

//   const { taxType, SGST } = state.taxes;
//   const { SGSTAmount } = calculateTaxes(updatedSubTotal, taxType, SGST);
//   let updatedTotalAmount = updatedSubTotal + SGSTAmount;

//   return {
//     ...state,
//     products: updatedProducts,
//     services: updatedServices,
//     discount: updatedDiscount,
//     subTotal: Math.round(updatedSubTotal),
//     totalAmount: Math.round(updatedTotalAmount),
//     amountPayable: Math.round(updatedTotalAmount),
//     paidDues: Math.round(
//       updatedTotalAmount -
//       state.amountPaid.reduce((acc, payment) => acc + Number(payment.amount), 0),
//     ),
//     useMembership: false,
//     usePackage:  false,
//     coupon: { couponId: null, couponCode: '', discountAmount: 0 },
//   };
// }

function discountInputChange(state, payload) {
  const { name, value } = payload;
  const updatedDiscount = { ...state.discount, [name]: value };
  const updatedServices = applyDiscountToItems(state.services, state.products, updatedDiscount);
  const updatedProducts = applyDiscountToItems(state.products, state.services, updatedDiscount);
  const updatedSubTotal = calculateSubTotal([...updatedServices, ...updatedProducts]);

  const { taxType, SGST } = state.taxes;
  const { SGSTAmount } = calculateTaxes(updatedSubTotal, taxType, SGST);
  let updatedTotalAmount = updatedSubTotal + SGSTAmount;

  return {
    ...state,
    products: updatedProducts,
    services: updatedServices,
    discount: updatedDiscount,
    subTotal: Math.round(updatedSubTotal),
    totalAmount: Math.round(updatedTotalAmount),
    amountPayable: Math.round(updatedTotalAmount),
    paidDues: Math.round(
      updatedTotalAmount -
      state.amountPaid.reduce((acc, payment) => acc + Number(payment.amount), 0),
    ),
    useMembership: false,
    usePackage: false,
    useRewardPoint: false,
    coupon: { couponId: null, couponCode: '', discountAmount: 0 },
  };
}

function taxeInputChange(state, payload) {
  const { taxType, SGST, PGST } = payload;
  // Calculate taxes based on taxType and percentage SGST/PGST
  let updatedSubTotal = Number(state.subTotal);

  if (taxType === 'Exclusive') {
    const SGSTAmount = (SGST / 100) * state.subTotal;
    updatedSubTotal += SGSTAmount;
  } else if (taxType === 'Inclusive') {
    updatedSubTotal = state.subTotal;
  }

  return {
    ...state,
    taxes: {
      taxType,
      SGST,
      PGST,
    },
    totalAmount: Math.round(updatedSubTotal),
    amountPayable: Math.round(updatedSubTotal),
    paidDues: Math.round(
      updatedSubTotal - state.amountPaid.reduce((acc, payment) => acc + Number(payment.amount), 0),
    ),
  };
}

//old but NaN exist
// function handleRewardPointToggle(state, payload) {
//   const { selectedValue, rewardPoint, perRewardPointsPrice } = payload;
//  console.log(perRewardPointsPrice,"perRewardPointsPrice")
//   const rewardDiscount = rewardPoint * perRewardPointsPrice;

//   let updatedAmountPayable;
//   let updatedPaidDues;

//   if (selectedValue) {
//     updatedAmountPayable = state.totalAmount - rewardDiscount;
//     updatedPaidDues = state.totalAmount - rewardDiscount;
//   } else {
//     updatedAmountPayable = state.totalAmount;
//     updatedPaidDues = state.totalAmount;
//   }

//   return {
//     ...state,
//     useRewardPoint: selectedValue,
//     amountPayable: Math.max(Math.round(updatedAmountPayable), 0),
//     paidDues: Math.max(Math.round(updatedPaidDues), 0),
//   };
// }

function handleRewardPointToggle(state, payload) {
  const { selectedValue, rewardPoint = 0, perRewardPointsPrice = 0 } = payload; // Ensure rewardPoint and perRewardPointsPrice have default values
  console.log(perRewardPointsPrice, "perRewardPointsPrice");

  // Ensure rewardDiscount is a valid number, even if rewardPoint or perRewardPointsPrice are invalid
  const rewardDiscount = rewardPoint * perRewardPointsPrice;

  let updatedAmountPayable;
  let updatedPaidDues;

  if (selectedValue) {
    updatedAmountPayable = state.totalAmount - rewardDiscount;
    updatedPaidDues = state.totalAmount - rewardDiscount;
  } else {
    updatedAmountPayable = state.totalAmount;
    updatedPaidDues = state.totalAmount;
  }

  const finalAmountPayable = Math.max(Math.round(updatedAmountPayable), 0);
  const finalPaidDues = Math.max(Math.round(updatedPaidDues), 0);

  return {
    ...state,
    useRewardPoint: selectedValue,
    amountPayable: finalAmountPayable,
    paidDues: finalPaidDues,
  };
}


//old code
// function amountPaidInputChange(state, payload) {
//   const { name, value, index, advanceGiven, setSnackbar } = payload;
//   const updatedAmountPaid = [...state.amountPaid];
//   let walletAmount = Number(advanceGiven); // Treat advance given as wallet amount.

//   // Update the value for the specific index and name (amount or paymentType)
//   updatedAmountPaid[index][name] = value;

//   // Recalculate total paid amount based on the updated amounts
//   const totalPaidAmount = updatedAmountPaid.reduce(
//     (acc, payment) => acc + Number(payment.amount || 0),
//     0
//   );

//   // Check if the total paid amount exceeds the payable amount
//   if (totalPaidAmount > state.amountPayable) {
//     setSnackbar({
//       open: true,
//       message: 'You cannot give advance here.',
//       severity: 'error',
//     });
//     return state; // Return the original state without changes.
//   }

//   // Recalculate wallet amount if payment type is 'Wallet'
//   updatedAmountPaid.forEach((payment, i) => {
//     if (payment.paymentType === 'Wallet') {
//       let enteredAmount = Number(payment.amount);

//       if (enteredAmount > walletAmount) {
//         setSnackbar({
//           open: true,
//           message: 'Your wallet amount is low. Please recharge your wallet.',
//           severity: 'warning',
//         });
//         updatedAmountPaid[i].amount = walletAmount; // Set the amount to the wallet amount.
//         walletAmount = 0; // Wallet is now zero.
//       } else {
//         walletAmount -= enteredAmount; // Deduct the entered amount from the wallet.
//       }
//     }
//   });

//   // Calculate the updated dues
//   const updatedPaidDues = totalPaidAmount >= state.amountPayable ? 0 : state.amountPayable - totalPaidAmount;

//   // Update the snackbar for the remaining wallet amount
//   setSnackbar({
//     open: true,
//     message: `Remaining wallet amount: ₹${walletAmount}`,
//     severity: 'info',
//   });

//   // Return the updated state
//   return {
//     ...state,
//     amountPaid: updatedAmountPaid,
//     paidDues: updatedPaidDues,
//   };
// }

function amountPaidInputChange(state, payload) {
  const { name, value, index, advanceGiven, setSnackbar } = payload;
  const updatedAmountPaid = [...state.amountPaid];
  let walletAmount = Number(advanceGiven); // Treat advance given as wallet amount.

  // Update the value for the specific index and name (amount or paymentType)
  updatedAmountPaid[index][name] = value;

  // Initialize a variable to track the adjusted total paid amount
  let adjustedTotalPaidAmount = 0;

  // Recalculate wallet amount if payment type is 'Wallet'
  updatedAmountPaid.forEach((payment, i) => {
    let enteredAmount = Number(payment.amount || 0);
    if (payment.paymentType === 'Wallet') {
      if (enteredAmount > walletAmount) {
        setSnackbar({
          open: true,
          message: 'Your wallet amount is low. Please recharge your wallet.',
          severity: 'warning',
        });
        enteredAmount = walletAmount; // Adjust amount to wallet limit
        updatedAmountPaid[i].amount = walletAmount; // Update the amount in the payment after adjustment
        walletAmount = 0; // Wallet is now zero.
      } else {
        walletAmount -= enteredAmount; // Deduct from wallet
      }

      // Update the snackbar for the remaining wallet amount
      setSnackbar({
        open: true,
        message: `Your wallet amount is : ₹${walletAmount}`,
        severity: 'info',
      });
    }

    // Accumulate the adjusted total paid amount
    adjustedTotalPaidAmount += enteredAmount;
  });

  // Check if the total paid amount exceeds the payable amount
  if (adjustedTotalPaidAmount > state.amountPayable) {
    setSnackbar({
      open: true,
      message: 'You cannot give advance here.',
      severity: 'error',
    });
    return state; // Return the original state without changes.
  }

  // Calculate the updated dues based on adjusted totalPaidAmount
  const updatedPaidDues = Math.max(state.amountPayable - adjustedTotalPaidAmount, 0);

  // Return the updated state
  return {
    ...state,
    amountPaid: updatedAmountPaid,
    paidDues: updatedPaidDues,
  };
}


function addAmountPaidRow(state) {
  const newAmountPaid = {
    paymentType: 'Cash',
    amount: '',
  };
  const addNewAmountPaid = [...state.amountPaid, newAmountPaid];
  return {
    ...state,
    amountPaid: addNewAmountPaid,
  };
}

function deleteAmountPaidRow(state, payload) {
  const { index } = payload;
  const updatedAmountPaid = state?.amountPaid?.filter((_, currentIndex) => currentIndex !== index);
  const paidDues =
    state.amountPayable -
    updatedAmountPaid.reduce((acc, payment) => acc + Number(payment.amount), 0);
  return {
    ...state,
    amountPaid: updatedAmountPaid,
    paidDues: paidDues,
  };
}

function couponInputChange(state, payload) {
  const { item } = payload;
  let updatedCoupon = { couponId: null, couponCode: '', discountAmount: 0 };
  let updatedServices = [...state.services];
  let updatedProducts = [...state.products];
  let updatedSubTotal;
  let updatedTotalAmount;

  if (item) {
    const { discount, discountType, _id, couponCode } = item;
    const isInPercentage = discountType === '%';
    const subTotalItems = [...updatedServices, ...updatedProducts];

    let discountAmount = isInPercentage
      ? Math.min(
        (calculatePriceSubTotal(subTotalItems) * discount) / 100,
        item.maximumDiscountAmount,
      )
      : discount;

    updatedServices = applyDiscountToItems(state.services, state.products, {
      discount: discountAmount,
      discountType: 'INR',
    });
    updatedProducts = applyDiscountToItems(state.products, state.services, {
      discount: discountAmount,
      discountType: 'INR',
    });

    updatedSubTotal = calculateSubTotal([...updatedServices, ...updatedProducts]);
    updatedCoupon = { couponId: _id, couponCode, discountAmount };
    const { taxType, SGST } = state.taxes;
    const { SGSTAmount } = calculateTaxes(updatedSubTotal, taxType, SGST);
    updatedTotalAmount = updatedSubTotal + SGSTAmount;

  } else {
    updatedServices = applyDiscountToItems(state.services, state.products, {
      discount: 0,
      discountType: 'INR',
    });
    updatedProducts = applyDiscountToItems(state.products, state.services, {
      discount: 0,
      discountType: 'INR',
    });
    updatedSubTotal = calculateSubTotal([...updatedServices, ...updatedProducts]);
    const { taxType, SGST } = state.taxes;
    const { SGSTAmount } = calculateTaxes(updatedSubTotal, taxType, SGST);
    updatedTotalAmount = updatedSubTotal + SGSTAmount;
  }

  const amountPaidTotal = state.amountPaid.reduce(
    (acc, payment) => acc + Number(payment.amount),
    0,
  );
  const totalAmount = Math.round(updatedSubTotal);

  return {
    ...state,
    coupon: updatedCoupon,
    products: updatedProducts,
    services: updatedServices,
    subTotal: totalAmount,
    totalAmount: Math.round(updatedTotalAmount),
    amountPayable: Math.round(updatedTotalAmount),
    paidDues: Math.round(updatedTotalAmount - amountPaidTotal),
    useMembership: false,
    usePackage:  false,
    useRewardPoint: false,
  };
}

//old code
// function membershipInputChange(state, payload) {
//   const { useMembership, item } = payload;
//   let updatedServices = [...state.services];
//   let updatedProducts = [...state.products];
//   let updatedSubTotal = 0;
//   let updatedTotalAmount;

//   if (useMembership) {
//     if (item.membershipType === 'serviceType') {
//       // Apply membership logic for serviceType
//       updatedServices = applyMembershipToServices(updatedServices, item);
//     } else {
//       // Apply membership logic for discountType
//       const { discountOnProduct, productDiscountType, discountOnService, serviceDiscountType } =
//         item.discount;
//       updatedServices = applyDiscountToItems(updatedServices, state.products, {
//         discount: discountOnService,
//         discountType: serviceDiscountType,
//       });
//       updatedProducts = applyDiscountToItems(updatedProducts, state.services, {
//         discount: discountOnProduct,
//         discountType: productDiscountType,
//       });
//     }
//     updatedSubTotal = calculateSubTotal([...updatedServices, ...updatedProducts]);
//     const { taxType, SGST } = state.taxes;
//     const { SGSTAmount } = calculateTaxes(updatedSubTotal, taxType, SGST);
//     updatedTotalAmount = updatedSubTotal + SGSTAmount;
//   } else {
//     updatedServices = applyDiscountToItems(state.services, state.products, {
//       discount: 0,
//       discountType: 'INR',
//     });
//     updatedProducts = applyDiscountToItems(state.products, state.services, {
//       discount: 0,
//       discountType: 'INR',
//     });
//     updatedSubTotal = calculateSubTotal([...updatedServices, ...updatedProducts]);
//     const { taxType, SGST } = state.taxes;
//     const { SGSTAmount } = calculateTaxes(updatedSubTotal, taxType, SGST);
//     updatedTotalAmount = updatedSubTotal + SGSTAmount;
//   }
//   const amountPaid = state.amountPaid.reduce((acc, payment) => acc + Number(payment.amount), 0);

//   return {
//     ...state,
//     services: updatedServices,
//     products: updatedProducts,
//     subTotal: Math.round(updatedSubTotal),
//     totalAmount: Math.round(updatedTotalAmount),
//     amountPayable: Math.round(updatedTotalAmount),
//     paidDues: Math.round(updatedTotalAmount - amountPaid),
//     useMembership,
//     usePackage: false,
//     useRewardPoint:false,
//     coupon: { couponId: null, couponCode: '', discountAmount: 0 },
//   };
// }

function membershipInputChange(state, payload) {
  const { useMembership, item, selectedServices } = payload;
  let updatedServices = [...state.services, ...selectedServices];
  let updatedProducts = [...state.products];
  let updatedSubTotal = 0;
  let updatedTotalAmount;
  console.log(updatedServices,"updatedServicesMemBership-1")
  console.log(selectedServices,"selectedServicesMembership-1")
  if (useMembership) {
    if (item.membershipType === 'serviceType') {
      // Apply membership logic for serviceType
      updatedServices = applyMembershipToServices(updatedServices, item);
    } else {
      // Apply membership logic for discountType
      const { discountOnProduct, productDiscountType, discountOnService, serviceDiscountType } =
        item.discount;
      updatedServices = applyDiscountToItems(updatedServices, state.products, {
        discount: discountOnService,
        discountType: serviceDiscountType,
      });
      updatedProducts = applyDiscountToItems(updatedProducts, state.services, {
        discount: discountOnProduct,
        discountType: productDiscountType,
      });
    }
    updatedSubTotal = calculateSubTotal([...updatedServices, ...updatedProducts]);
    const { taxType, SGST } = state.taxes;
    const { SGSTAmount } = calculateTaxes(updatedSubTotal, taxType, SGST);
    updatedTotalAmount = updatedSubTotal + SGSTAmount;
  } else {
    updatedServices = applyDiscountToItems(state.services, state.products, {
      discount: 0,
      discountType: 'INR',
    });
    updatedProducts = applyDiscountToItems(state.products, state.services, {
      discount: 0,
      discountType: 'INR',
    });
    updatedSubTotal = calculateSubTotal([...updatedServices, ...updatedProducts]);
    const { taxType, SGST } = state.taxes;
    const { SGSTAmount } = calculateTaxes(updatedSubTotal, taxType, SGST);
    updatedTotalAmount = updatedSubTotal + SGSTAmount;
  }
  const amountPaid = state.amountPaid.reduce((acc, payment) => acc + Number(payment.amount), 0);

  return {
    ...state,
    services: updatedServices,
    products: updatedProducts,
    subTotal: Math.round(updatedSubTotal),
    totalAmount: Math.round(updatedTotalAmount),
    amountPayable: Math.round(updatedTotalAmount),
    paidDues: Math.round(updatedTotalAmount - amountPaid),
    useMembership,
    usePackage: false,
    useRewardPoint:false,
    coupon: { couponId: null, couponCode: '', discountAmount: 0 },
  };
}

function packageInputChange(state, payload) {
  //console.log(payload, "payload")
  const { usePackage, item } = payload;
  console.log(usePackage, "usePackage", item, "item")
  console.log(state, "state")
  let updatedServices = [...state.services];
  console.log(updatedServices, "updatedServices")
  let updatedSubTotal = 0;
  let updatedTotalAmount;

  if (usePackage) {
    updatedServices = applyPackageToServices(updatedServices, item);
    updatedSubTotal = calculateSubTotal([...updatedServices]);
    const { taxType, SGST } = state.taxes;
    const { SGSTAmount } = calculateTaxes(updatedSubTotal, taxType, SGST);
    updatedTotalAmount = updatedSubTotal + SGSTAmount;
  } else {
    // Show error message if package is not applied
    console.error('Package could not be applied.');

    const resetServices = state.services.map(e => {
      const resetValue = e
      resetValue.amount = resetValue.price
      return resetValue
    })
    updatedServices = resetServices;
    console.log(updatedServices)

    updatedSubTotal = calculateSubTotal([...updatedServices]);
    const { taxType, SGST } = state.taxes;
    const { SGSTAmount } = calculateTaxes(updatedSubTotal, taxType, SGST);
    updatedTotalAmount = updatedSubTotal + SGSTAmount;
  }

  const amountPaid = state.amountPaid.reduce((acc, payment) => acc + Number(payment.amount), 0);

  return {
    ...state,
    services: updatedServices,
    subTotal: Math.round(updatedSubTotal),
    totalAmount: Math.round(updatedTotalAmount),
    amountPayable: Math.round(updatedTotalAmount),
    paidDues: Math.round(updatedTotalAmount - amountPaid),
    usePackage,
    useRewardPoint:false,
    packageUniqueId: usePackage ? item.uniqueId : null,
    coupon: { couponId: null, couponCode: '', discountAmount: 0 },
  };
}

function applyPackageToServices(services, item) {
  // Create a deep copy of item to avoid modifying the original object
  let clientPackage = JSON.parse(JSON.stringify(item));
  console.log(clientPackage, "clientPackageeeeee")
  const updatedServices = services.map((billService) => {
    const packageService = clientPackage.services.find((p) => p._id === billService.service);
    if (packageService) {
      billService.amount = 0; // Set service amount to zero if it matches the package service ID
      billService.discount = 100; // Assuming package services are fully discounted
      billService.discountType = '%';
    } else {
      billService.amount = billService.price || billService.amount;
      billService.discount = billService.discount || 0;
      billService.discountType = billService.discountType || '';
    }
    return billService;
  });

  // Return the updated services
  return updatedServices;
}

//old code
// function applyMembershipToServices(services, item) {
//   // Create a deep copy of item to avoid modifying the original object
//   let clientMembership = JSON.parse(JSON.stringify(item));
//   let remainingServices = []; // Array to store remaining services

//   const updatedServices = services.map((billService) => {
//     const membershipService = clientMembership.services.find((m) => m.id === billService.service);
//     if (membershipService && membershipService.count > 0) {
//       const quantityToUse = Math.min(billService.quantity, membershipService.count);
//       const remainingQuantity = billService.quantity - quantityToUse;
//       billService.quantity = quantityToUse;
//       billService.amount = 0; // Assuming membership services are free
//       billService.discount = 100; // Assuming membership services are fully discounted
//       billService.discountType = '%';
//       membershipService.count -= quantityToUse;
//       if (remainingQuantity > 0) {
//         // If requested quantity exceeds membership count, create remaining service
//         const remainingService = {
//           ...billService,
//           quantity: remainingQuantity,
//           amount: remainingQuantity * billService.price,
//           discount: 0,
//           discountType: '%',
//         };
//         // Add the remaining service to the remainingServices array
//         remainingServices.push(remainingService);
//       }
//     }
//     return billService;
//   });
//   // Concatenate remainingServices with updatedServices array
//   return updatedServices.concat(remainingServices).filter((service) => service.quantity > 0);
// }

function applyMembershipToServices(services, selectedServices) {
  console.log(services,"servicesAPPLYFUNCTION")
  console.log(selectedServices,"SelectedserAPPLYFUNCTIONN")
  const selectedServiceIds = selectedServices.services?.map((service) => service.id);
  console.log(selectedServiceIds,"selectedServiceIds")
  const remainingServices = [];

  const updatedServices = services.map((billService) => {
    const isSelected = selectedServiceIds.includes(billService.id);
    console.log(isSelected,"isSelected")
    if (isSelected) {
      const selectedService = selectedServices.services?.find((service) => service.id === billService.id);
      console.log(selectedService,"selectedServiceAPPLYITEM")
      const quantityToUse = Math.min(billService.quantity, selectedService.count);
      console.log(billService.quantity,"billService.quantity", selectedService.count,"selectedService.count")
      console.log(quantityToUse,"quantityToUse")
      const remainingQuantity = billService.quantity - quantityToUse;

      const updatedService = {
        ...billService,
      };

      if (remainingQuantity > 0) {
        remainingServices.push({
          ...billService,
          quantity: remainingQuantity,
          amount: remainingQuantity * billService.price,
          discount: 0,
          discountType: '%',
        });
      }
       console.log(updatedService,"updatedService")
      return updatedService;
    } else {
      console.log(billService,"billService")
      return billService;
    }
  });

  return updatedServices.concat(remainingServices).filter((service) => service.quantity > 0);
}

// Function to get the current date in a specific format
function getCurrentDateInFormat() {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

// Function to get the current time in a specific format
function getCurrentTime() {
  const now = new Date();
  const hours = now.getHours();
  const minutes = now.getMinutes();
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  return `${formattedHours}:${formattedMinutes}`;
}


function calculateSubTotal(items) {
  return items.reduce((total, item) => total + Number(item.amount), 0);
}

function calculatePriceSubTotal(items) {
  return items.reduce((total, item) => total + Number(item.price * item.quantity), 0);
}

function calculateAmountWithDiscount(item, discount) {
  const discountAmount = ((item.price * item.quantity * discount) / 100).toFixed(2);
  return (item.price * item.quantity - discountAmount).toFixed(2);
}

function applyDiscountToItems(items, remainingItems, discount) {
  const totalItems = [...items, ...remainingItems];
  const updatedDiscount =
    discount?.discountType === 'INR'
      ? ((discount?.discount / calculatePriceSubTotal(totalItems)) * 100).toFixed(2)
      : discount?.discount;

  return items.map((item) => {
    // Skip applying a discount to membership services
    const isMembershipService = remainingItems.some(
      (membershipService) => membershipService.service === item.service
    );
    if (isMembershipService) {
      return item;
    }

    return {
      ...item,
      discount: updatedDiscount,
      amount: calculateAmountWithDiscount(item, updatedDiscount),
    };
  });
}


// Function to calculate taxes based on subtotal, tax type, and rates
function calculateTaxes(subTotal, taxType, SGST, PGST) {
  let SGSTAmount = 0;
  if (taxType === 'Exclusive') {
    SGSTAmount = (SGST / 100) * subTotal;
  }
  return { SGSTAmount };
}



//old code
// function calculateSubTotal(items) {
//   return items.reduce((total, item) => total + Number(item.amount), 0);
// }

// function calculatePriceSubTotal(items) {
//   return items.reduce((total, item) => total + Number(item.price * item.quantity), 0);
// }

// function calculateAmountWithDiscount(item, discount) {
//   const discountAmount = ((item.price * item.quantity * discount) / 100).toFixed(2);
//   return (item.price * item.quantity - discountAmount).toFixed(2);
// }

// function applyDiscountToItems(items, remainingItems, discount) {
//   const updatedDiscount =
//     discount?.discountType === 'INR'
//       ? ((discount?.discount / calculatePriceSubTotal([...items, ...remainingItems])) * 100).toFixed(
//         2,
//       )
//       : discount?.discount;
//   return items.map((item) => ({
//     ...item,
//     // discountType: discount.discountType,
//     discount: updatedDiscount,
//     amount: calculateAmountWithDiscount(item, updatedDiscount),
//   }));
// }

// // Function to calculate taxes based on subtotal, tax type, and rates
// function calculateTaxes(subTotal, taxType, SGST, PGST) {
//   let SGSTAmount = 0;
//   if (taxType === 'Exclusive') {
//     SGSTAmount = (SGST / 100) * subTotal;
//   }
//   return { SGSTAmount };
// }