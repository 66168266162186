import React, { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Grid, Button } from '@mui/material';
import { Card, CardContent } from '@mui/material';
import CheckboxInputs from 'src/components/inputs/CheckboxInputs';
import EditIcon from '@mui/icons-material/Edit';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard2 from 'src/components/shared/DashboardCard2';

const ClientLead = ({ checkedClients }) => {
  const [isAllChecked, setIsAllChecked] = useState(false);
  const handleAllCheckboxChange = () => {};
  const handleSignleCheckboxChange = () => {};
  const editButtonStyle = {
    backgroundColor: '#1976D2',
    color: '#fff',
    marginRight: '8px',
    borderRadius: '5px',
    border: 'none',

    '&:hover': {
      backgroundColor: '#1565C0',
    },
  };

  const deleteButtonStyle = {
    backgroundColor: '#E53935',
    color: '#fff',
    borderRadius: '5px',
    border: 'none',
    '&:hover': {
      backgroundColor: '#C62828',
    },
  };
  return (
    <PageContainer title="Client History" description="This contains all products">
      <DashboardCard2 title="Client Lead Management">
        <Box sx={{ overflow: 'auto', width: { xs: '450px', sm: 'auto' } }}>
          <table className="table-container mt-3">
            <thead className="table-thead">
              <tr>
                <th style={{ width: '30%' }}>
                  <CheckboxInputs
                    type={'Heading'}
                    isAllChecked={isAllChecked}
                    handleAllCheckboxChange={handleAllCheckboxChange}
                  />
                </th>
                {[
                  'Name',
                  'Contact Number',
                  'Gender',
                  'Email',
                  'Lead Type',
                  'Actions',
                ].map((h, index) => {
                  return (
                    <th key={index} style={{ width: '100%' }}>
                      {h}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className="table-tbody">
              <tr>
                <td style={{ width: '30%' }}>
                  <CheckboxInputs
                    type={'content'}
                    handleSignleCheckboxChange={handleSignleCheckboxChange}
                    checkedItems={checkedClients}
                  />
                </td>
                <td style={{ width: '100%' }}>{'clientName'}</td>
                <td style={{ width: '100%' }}>{'clientNumber'}</td>
                <td style={{ width: '100%' }}>{'gender'}</td>
                <td style={{ width: '100%' }}>{'clientEmail'}</td>
                <td style={{ width: '100%' }}></td>
                <td style={{ width: '100%'}}>
                  <div style={{display:'flex'}}>
                  <button variant="contained" style={editButtonStyle}>
                    {<EditIcon />}
                    Edit
                  </button>
                  <button variant="contained" style={deleteButtonStyle}>
                    {<DeleteIcon />}
                    Delete
                  </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </Box>
      </DashboardCard2>
    </PageContainer>
  );
};

export default ClientLead;
